import {Dispatch, SetStateAction, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

interface AddListRequest {
    machineId: number;
    companyId: number;
    buildingId: number;
    additionalKey: string;
    additionalValue: string;
}

// 추가정보 등록
const useAddListRegist = (
    config: AddListRequest,
    setIsAddListView: Dispatch<SetStateAction<boolean>>,
): [() => Promise<void>, boolean] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const request = useMemo(() => {
        return httpRequest<AddListRequest, null>({
            method: "POST",
            url: "/machine/ac",
            data: config,
        });
    }, [config]);

    const addListSubmit = async () => {
        if (
            config.additionalKey.trim().length > 0 &&
            config.additionalKey !== "" &&
            config.additionalValue.trim().length > 0 &&
            config.additionalValue !== ""
        ) {
            if (window.confirm("추가정보를 등록하시겠습니까?")) {
                try {
                    setIsLoading(true);
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsAddListView(false);
                    setIsLoading(false);
                    alert("등록되었습니다");
                }
            } else {
                setIsLoading(true);
            }
        } else {
            alert("필수항목을 입력해주세요.");
            setIsLoading(true);
        }
    };

    return [addListSubmit, isLoading];
};

// 추가정보 수정
const useAddListEdit = (
    config: {machineAddId: number; additionalKey: string; additionalValue: string},
    setAddListDataEdit: any,
): [() => Promise<void>, boolean] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const request = useMemo(() => {
        return httpRequest<any, null>({
            method: "PUT",
            url: "/machine/au",
            data: config,
        });
    }, [config]);

    const historySubmit = async () => {
        if (
            config.machineAddId !== null &&
            config.additionalKey.trim().length > 0 &&
            config.additionalKey !== "" &&
            config.additionalValue.trim().length > 0 &&
            config.additionalValue !== ""
        ) {
            if (window.confirm("추가정보를 수정하시겠습니까?")) {
                try {
                    setIsLoading(true);
                    setAddListDataEdit({machineAddId: 0, additionalValue: ""});
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsLoading(false);
                    alert("수정되었습니다");
                }
            } else {
                setAddListDataEdit({machineAddId: 0, additionalValue: ""});
                setIsLoading(true);
            }
        } else {
            alert("필수항목을 입력해주세요.");
        }
    };

    return [historySubmit, isLoading];
};

// 추가정보 삭제
const useAddListDelete = (
    addListId: number,
    setHistoryId: Dispatch<SetStateAction<number | any>>,
): [() => Promise<void>, boolean] => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const request = useMemo(() => {
        return httpRequest<{machineAddId: number}, null>({
            method: "DELETE",
            url: "/machine/ad",
            data: {
                machineAddId: addListId,
            },
        });
    }, [addListId]);

    const historyDeleteSubmit = async () => {
        if (addListId !== null && addListId !== undefined) {
            if (window.confirm("추가정보를 삭제하시겠습니까?")) {
                try {
                    setIsLoading(true);
                    await request.submit();
                } catch (error) {
                    alert("에러가 발생하였습니다 다시 시도해주세요");
                } finally {
                    setIsLoading(false);
                    setHistoryId(null);
                    alert("삭제되었습니다");
                }
            } else {
                setIsLoading(true);
                setHistoryId(null);
            }
        }
    };

    return [historyDeleteSubmit, isLoading];
};

export {useAddListDelete, useAddListEdit, useAddListRegist};
