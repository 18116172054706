import {useEffect, useState} from "react";
import {App, Input, Select} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CenterModal} from "~/components/modal/center-modal";
import {useHrHandler} from "~/data/hr/use-hr-list";
import {HrSettingModalProps} from "~/pages/hr/modal/hr-setting.modal.interface";

import styles from "./hr-setting.modal.module.scss";

const cx = classNames.bind(styles);

const HrSettingModal = ({isShow, refresh, close, workList, ranks, hrInfo}: HrSettingModalProps) => {
    const [inputs, setInputs] = useState<{name: string; positionCode: null | string; workTypeId: number | null}>({
        name: "",
        positionCode: null,
        workTypeId: null,
    });
    const {register, update, remove} = useHrHandler();

    const handleConfirm = () => {
        if (inputs.name && inputs.workTypeId) {
            Swal.fire({
                text: "저장하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "저장하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    if (hrInfo) {
                        await update({
                            hrId: hrInfo.hrId,
                            name: inputs.name,
                            positionCode: inputs.positionCode!,
                            workTypeId: inputs.workTypeId!,
                        });
                    } else {
                        await register({
                            name: inputs.name!,
                            positionCode: inputs.positionCode!,
                            workTypeId: inputs.workTypeId!,
                        });
                    }
                    setTimeout(refresh, 500);
                    close();
                }
            });
        } else {
            Swal.fire({
                text: "필수 사항을 입력해주세요.",
                confirmButtonText: "확인",
            });
        }
    };

    const handleRemove = () => {
        Swal.fire({
            text: "삭제하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then(async (info) => {
            if (info.isConfirmed) {
                if (hrInfo) await remove([hrInfo.hrId]);
                setTimeout(refresh, 500);
                close();
            }
        });
    };

    useEffect(() => {
        if (hrInfo)
            setInputs({
                name: hrInfo.name,
                positionCode: hrInfo.positionCode,
                workTypeId: hrInfo.workTypeId,
            });
        console.log(hrInfo);
    }, [hrInfo]);

    return (
        <CenterModal
            isShow={isShow}
            close={close}
            closeIcon={false}
            title={`인사설정 ${hrInfo ? "수정" : "등록"}`}
            width={500}
            extraButtons={[
                <Button label={"저장하기"} key={"confirm"} onClick={handleConfirm} />,
                hrInfo && <Button label={"삭제하기"} key={"remove"} onClick={handleRemove} />,
            ]}
        >
            <App>
                <div className={cx("input-container")}>
                    <span className={cx("input-name")}>이름</span>
                    <div className={cx("input")}>
                        <Input
                            placeholder={"이름을 입력하세요"}
                            value={inputs.name}
                            status={inputs.name ? "" : "error"}
                            onChange={(e) => setInputs((prev) => ({...prev, name: e.target.value}))}
                        />
                    </div>
                </div>
                <div className={cx("input-container")}>
                    <span className={cx("input-name")}>직책</span>
                    <div className={cx("input")}>
                        <Select
                            placeholder={"직책을 선택하세요"}
                            className={cx("select")}
                            value={inputs.positionCode}
                            allowClear={true}
                            onChange={(e) => setInputs((prev) => ({...prev, positionCode: e}))}
                            options={[
                                ...ranks.map((data) => ({
                                    label: data.description,
                                    value: data.code,
                                })),
                            ]}
                        />
                    </div>
                </div>
                <div className={cx("input-container")}>
                    <span className={cx("input-name")}>소속</span>
                    <div className={cx("input")}>
                        <Select
                            placeholder={"소속을 선택하세요"}
                            className={cx("select")}
                            status={inputs.workTypeId ? "" : "error"}
                            value={inputs.workTypeId}
                            onChange={(e) => setInputs((prev) => ({...prev, workTypeId: e}))}
                            options={[
                                ...workList.map((data) => ({
                                    label: data.name,
                                    value: data.workTypeId,
                                })),
                            ]}
                        />
                    </div>
                </div>
            </App>
        </CenterModal>
    );
};

export {HrSettingModal};
