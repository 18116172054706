import {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";

const useCheckDev = () => {
    const [isDev, setIsDev] = useState(false);

    const {dev} = useParams();

    useEffect(() => {
        setIsDev(dev === "d343d9407eaa82d6ea78fa1de95ef748");
    }, [dev]);

    return isDev;
};

export {useCheckDev};
