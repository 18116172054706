import React, {useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import classNames from "classnames/bind";

import {Header} from "~/components/header";
import {Sidebar} from "~/components/sidebar";
import {useUser} from "~/data/user";
import {usePlatform} from "~/utills/use-platform";

import styles from "./header-with-sidebar.module.scss";
const cx = classNames.bind(styles);

const HeaderWithSidebar = () => {
    const {platform} = usePlatform();
    const [isOpenedSidebar, setIsOpenedSidebar] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();
    const {user, isLoading} = useUser();

    useEffect(() => {
        if (!isLoading && !user) navigate("/login");
    }, [user, isLoading]);

    const toggleSidebar = () => {
        setIsOpenedSidebar(!isOpenedSidebar);
    };

    useEffect(() => {
        if (platform === "mobile") {
            setIsOpenedSidebar(true);
        }
    }, [location.pathname, location.search, platform]);

    return (
        <div className={cx("container")}>
            <div className={cx("header-container")}>
                <Header toggleSidebar={toggleSidebar} isOpenedSidebar={isOpenedSidebar} />
            </div>
            <div className={cx("sidebar-container", {"opened-sidebar": isOpenedSidebar})}>
                <Sidebar />
            </div>
            <div className={cx("contents-container", {"opened-sidebar": isOpenedSidebar})}>
                <div className={cx("contents-inner", {mobile: platform === "mobile"})}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export {HeaderWithSidebar};
