import React, {ChangeEvent, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {faDownload, faPaperclip, faTrashCan, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Button as ButtonAntd,
    DatePicker,
    Input,
    List,
    Radio,
    Select,
    Spin,
    Table,
    Upload,
    UploadFile,
    UploadProps,
} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item/merge-grid-item";
import {MergeTargetList} from "~/components/merge/merge-target-list/merge-target-list";
import {PageTitle} from "~/components/page-title/page-title";
import {useMergeAnswerAddFileDownload} from "~/data/merge/answer/use-answer";
import {
    useMergeAllComplete,
    useMergeDelete,
    useMergeDetail,
    useMergeDownload,
    useMergeDownloadBuilding,
    useMergeFormDownload,
    useMergeUpdateDetail,
} from "~/data/merge/detail/use-detail";
import {MergeAddFileDTOList} from "~/data/merge/merge.interface";
import {MergeRegisterCheckDRMResponse} from "~/data/merge/register/register.interface";
import {useMergeRegisterCheckDRM, useMergeRegisterTargetList} from "~/data/merge/register/use-register";
import {useSearchFilter} from "~/utills/useSearchFilter";

import {
    CompleteDTOType,
    CompleteList,
    MergeBoardDetailModalProps,
    MergeDetailInput,
} from "./merge-board-detail.modal.interface";

import styles from "./merge-board-detail.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const MergeBoardDetailModal = ({noticeMergeId, close, submit: submitTable}: MergeBoardDetailModalProps) => {
    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [files, setFiles] = useState<{main: File | null; sub: UploadFile[]}>({
        main: null,
        sub: [],
    });
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs(new Date()), dayjs(new Date())]); // 취합기간
    const [important, setImportant] = useState<0 | 1 | 2 | 3>(0); // 중요도
    const [addFileList, setAddFileList] = useState<MergeAddFileDTOList[]>([]);
    const [searchSpecific, setSearchSpecific] = useState<string>("");
    const [input, setInput] = useState<MergeDetailInput>({
        managerName: "",
        title: "",
        content: "",
    }); // 제목, 내용, 담당자
    const [updateCompleteDTOList, setUpdateCompleteDTOList] = useState<CompleteDTOType[]>([]); // 완료지점 기한준수 저장된 값
    const [selectedIncompleteRow, setSelectedIncompleteRow] = useState<React.Key[]>([]); // 미완료 체크박스 key : buildingId
    const [deleteFiles, setDeleteFiles] = useState<number[]>([]);
    const [completeList, setCompleteList] = useState<CompleteList[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);

    // 상세 정보
    const [submit, response, isDataLoading] = useMergeDetail({
        noticeMergeId,
    });

    // 대상 리스트
    const [submitTargetList, targetList, isTargetListLoading] = useMergeRegisterTargetList();

    // 일괄마감
    const [submitAllComplete, isAllCompleteLoading] = useMergeAllComplete({
        noticeMergeId,
        buildings: selectedIncompleteRow.join("|"),
    });

    // 완료지점 첨부파일 다운로드
    const [downloadBuilding] = useMergeDownloadBuilding();
    // 첨부양식 다운로드
    const [downloadForm] = useMergeFormDownload(noticeMergeId);
    // 추가첨부 다운로드
    const [downloadAddFiles] = useMergeAnswerAddFileDownload();
    // 삭제
    const [submitDel, isDelLoading] = useMergeDelete(noticeMergeId);
    //취합 파일 다운로드
    const [mergeDownload, mergeDownloading] = useMergeDownload({
        noticeMergeId,
        mergeType: response?.responseData.mergeType ?? null,
    });
    //DRM 검사
    const [checkDRM, formatFileInfo] = useMergeRegisterCheckDRM({uploadFile: files.main});

    // 수정
    const [submitUpd, isUpdLoading] = useMergeUpdateDetail({
        info: {
            noticeMergeId: noticeMergeId,
            title: input.title,
            content: input.content,
            managerNm: input.managerName,
            startDate: Number(dateRange![0]),
            endDate: Number(dateRange![1]),
            targetType: response?.responseData.targetType ?? "ALL",
            important: important,
            formFileOriginName: null,
            formFileStorePath: null,
            completeDTOList: updateCompleteDTOList,
            removalFileIds: deleteFiles,
            fileSheetNames: formatFileInfo?.fileSheetNames ?? response?.responseData.fileSheetNames ?? null,
            fileFreezePaneCounts:
                formatFileInfo?.fileFreezePaneCounts ?? response?.responseData.fileFreezePaneCounts ?? null,
        },
        files,
    });

    // 완료/미완료 지점 검색
    const {
        searchText: completeSearchText,
        setSearchText: setCompleteSearchText,
        filteredData: filteredCompleteData,
    } = useSearchFilter(
        response
            ? response?.responseData.mergeType !== "DATA"
                ? response.responseData.completeDTOList
                : completeList
            : [],
        "buildingName",
    );
    const {
        searchText: incompleteSearchText,
        setSearchText: setIncompleteSearchText,
        filteredData: filteredIncompleteData,
    } = useSearchFilter(response?.responseData.nonCompleteDTOList || [], "buildingName");

    // 초기 셋팅
    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
        submit();
        submitTargetList();
    }, []);
    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => close(), 500);
    }, [isLoading]);

    // 초기 받아온 데이터 주입
    useEffect(() => {
        setDateRange([
            dayjs(new Date(response?.responseData.startDate ?? new Date())),
            dayjs(new Date(response?.responseData.endDate ?? new Date())),
        ]);
        setImportant(response?.responseData.important ?? 0);
        setInput({
            managerName: response?.responseData.managerNm ?? "",
            title: response?.responseData.title ?? "",
            content: response?.responseData.content ?? "",
        });
        setUpdateCompleteDTOList(response?.responseData.completeDTOList ?? []);

        setAddFileList(response?.responseData.addFileDTOList ?? []);

        // 데이터 취합 완료지점
        if (response) {
            const data = response.responseData.completeDTOList
                .sort((a, b) => a.noticeMergeQuestionId - b.noticeMergeQuestionId)
                .sort((a, b) => a.buildingId - b.buildingId);
            const seenBuildingNames = new Set<string>();
            let indexCounter = 0;
            let questionCnt = 0;

            const buildingNameCounts = data.reduce(
                (acc, curr) => {
                    acc[curr.buildingName] = (acc[curr.buildingName] || 0) + 1;
                    return acc;
                },
                {} as Record<string, number>,
            );

            // 각 데이터 객체에 buildingNameCount 추가
            const temp = data.map((item) => {
                if (!seenBuildingNames.has(item.buildingName)) {
                    seenBuildingNames.add(item.buildingName);
                    indexCounter++;
                    questionCnt = buildingNameCounts[item.buildingName];
                    return {
                        ...item,
                        rowSpan: buildingNameCounts[item.buildingName],
                        index: indexCounter,
                    };
                }
                return item;
            });
            if (questionCnt !== 0) {
                setPageSize(questionCnt);
            }
            const list = temp.map((data, index) => ({...data, key: index}));

            setCompleteList(list);
        }
    }, [response]);

    useEffect(() => {
        const temp = files.sub.map((data) => ({
            id: data.uid,
            fileOriginName: data.name,
            fileStorePath: "",
        }));
        setAddFileList((prev) => [...prev, ...temp]);
    }, [files.sub]);

    useEffect(() => {
        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    const handleClose = () => {
        setIsLoading(false);
    };

    // title, content, manage name
    const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    // 일괄마감
    const handleAllComplete = () => {
        Swal.fire({
            text: "선택된 지점들을 일괄마감하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then((info) => {
            if (info.isConfirmed) {
                submitAllComplete();
                setTimeout(submit, 1000);
            }
        });
    };

    // 수정
    const handleUpdateDetail = () => {
        Swal.fire({
            text: "수정하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then((info) => {
            if (info.isConfirmed) {
                submitUpd(files);
                setTimeout(submitTable, 1000);
                setIsLoading(false);
            }
        });
    };

    // 삭제
    const handleDeleteDetail = () => {
        Swal.fire({
            text: "정말 삭제하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then((info) => {
            if (info.isConfirmed) {
                submitDel();
                setIsLoading(false);
                setTimeout(submitTable, 1000);
            }
        });
    };

    // 수정용 완료지점 기한준수 바뀐 값 저장
    const handleIsDelay = (buildingId: number, isDelay: "Y" | "N") => {
        const newData: CompleteDTOType[] =
            updateCompleteDTOList.map((item) =>
                item.buildingId === buildingId
                    ? {
                          ...item,
                          isDelay: isDelay,
                      }
                    : item,
            ) ?? [];
        setUpdateCompleteDTOList(newData);
    };

    // 취합파일양식 DRM 검사
    const handleBeforeUpload = async (file: File) => {
        if (response?.responseData.mergeType === "FILE") {
            setFiles((prev) => ({...prev, main: file}));

            return false;
        } else {
            const result: MergeRegisterCheckDRMResponse | null = await checkDRM(file);

            if (result?.responseResult === "SUCCESS") {
                setFiles((prev) => ({...prev, main: file}));
            } else {
                Swal.fire({
                    title: "파일 오류",
                    text: result?.responseMessage,
                    confirmButtonText: "확인",
                });
                return Upload.LIST_IGNORE;
            }
            return false;
        }
    };

    /* ------------------------------- 다중첨부 ------------------------------- */
    const props: UploadProps = {
        name: "file",
        multiple: true,
        fileList: files.sub,
        onChange(info) {
            const newFileList = [...info.fileList];
            setFiles((prev) => ({...prev, sub: newFileList}));
        },
        beforeUpload: () => {
            if (files.sub.length >= 10) {
                Swal.fire({
                    text: "파일은 최대 10개까지 업로드 가능합니다.",
                    confirmButtonText: "확인",
                });
            }
            return false;
        },
        showUploadList: false,
    };

    const handleRemove = (file: MergeAddFileDTOList) => {
        setAddFileList((prev) => [...prev.filter((item) => item.id !== file.id)]);
        setDeleteFiles((prev) => [...prev, Number(file.id)]);
    };

    useEffect(() => {
        const temp: MergeAddFileDTOList[] = files.sub.map((data) => ({
            id: data.uid,
            fileOriginName: data.name,
            fileStorePath: "",
        }));
        setAddFileList((prev) => {
            const mergedList = [...prev, ...temp];
            return mergedList.filter((file, index, self) => index === self.findIndex((f) => f.id === file.id));
        });
    }, [files.sub]);

    return createPortal(
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading})}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className={cx("contents-container")}>
                    <PageTitle>취합 게시판 상세</PageTitle>
                    {isDataLoading || isAllCompleteLoading || isTargetListLoading ? (
                        <div className={cx("loading")}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <div className={cx("form-container")}>
                            <div className={cx("detail-content")}>
                                <MergeGridItem name={"연도"}>{response?.responseData.year}</MergeGridItem>
                                <MergeGridItem name={"월"}>{response?.responseData.month}</MergeGridItem>
                                <MergeGridItem name={"취합기간"}>
                                    <DatePicker.RangePicker
                                        className={gridCx("range-picker")}
                                        value={dateRange}
                                        allowClear={false}
                                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        //@ts-ignore
                                        onChange={(value: [Dayjs, Dayjs]) => setDateRange(value)}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"담당자"}>
                                    <Input
                                        className={gridCx("input")}
                                        name="managerName"
                                        onChange={handleChangeInput}
                                        value={input.managerName}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"제목"} span>
                                    <Input
                                        className={gridCx("input")}
                                        name="title"
                                        onChange={handleChangeInput}
                                        value={input.title}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"구분"}>
                                    {response?.responseData.mergeType === "DATA"
                                        ? "데이터"
                                        : response?.responseData.mergeType === "FILE"
                                          ? "파일"
                                          : "엑셀"}{" "}
                                    취합
                                </MergeGridItem>
                                <MergeGridItem name={"등록일"}>
                                    {dayjs(new Date(response?.responseData.createDate ?? new Date())).format(
                                        "YYYY.MM.DD",
                                    )}
                                </MergeGridItem>
                                <MergeGridItem name={"중요도"} span>
                                    <Radio.Group
                                        className={gridCx("radio-group")}
                                        value={important}
                                        onChange={(e) => setImportant(e.target.value)}
                                    >
                                        <Radio className={gridCx("radio")} value={0}>
                                            -
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={1}>
                                            ★
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={2}>
                                            ★★
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={3}>
                                            ★★★
                                        </Radio>
                                    </Radio.Group>
                                </MergeGridItem>
                                <MergeGridItem name={"대상"} span extraClassName="justify">
                                    <Radio.Group
                                        className={gridCx("radio-group-target")}
                                        value={response?.responseData.targetType}
                                        disabled
                                        // onChange={(e) =>
                                        //     setInput({
                                        //         ...input,
                                        //         targetType: e.target.value,
                                        //     })
                                        // }
                                    >
                                        <Radio className={gridCx("radio")} value={"ALL"}>
                                            전체
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"SECTOR"}>
                                            특정부문
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"TRAINER"}>
                                            특정 트레이너
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"SPECIFIC"}>
                                            특정지점
                                        </Radio>
                                    </Radio.Group>
                                    <div className={gridCx("target-input-container")}>
                                        <span className={gridCx("count")}>
                                            {response?.responseData.targetId
                                                ? response.responseData.targetId.split(",").length
                                                : 1}
                                            개 선택됨
                                        </span>
                                        {response?.responseData.targetType === "SPECIFIC" && (
                                            <Input
                                                className={gridCx("input")}
                                                placeholder="지점명 검색"
                                                value={searchSpecific}
                                                onChange={(e) => setSearchSpecific(e.target.value)}
                                            />
                                        )}
                                    </div>
                                    <MergeTargetList
                                        selectedTargetList={
                                            response?.responseData.targetId
                                                ? response.responseData.targetId.split(",").map(Number) ?? [0]
                                                : [0]
                                        }
                                        targetType={response?.responseData.targetType ?? "ALL"}
                                        targetList={targetList}
                                        searchText={searchSpecific}
                                        disabled
                                        onChange={(e) => {
                                            console.log("");
                                        }}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"내용"} span>
                                    <Input.TextArea
                                        className={gridCx("text-area", "input")}
                                        name="content"
                                        onChange={handleChangeInput}
                                        value={input.content}
                                    />
                                </MergeGridItem>
                                {(response?.responseData.mergeType === "EXCEL" ||
                                    response?.responseData.mergeType === "FILE") && (
                                    <MergeGridItem name={"첨부양식"} extraClassName={"upload-container-detail"}>
                                        <div
                                            className={gridCx("default-file")}
                                            onClick={() => {
                                                response?.responseData.formFileOriginName && downloadForm();
                                            }}
                                        >
                                            {response?.responseData.formFileOriginName
                                                ? response.responseData.formFileOriginName
                                                : "-"}
                                        </div>
                                        <Upload
                                            className={gridCx("upload")}
                                            maxCount={1}
                                            beforeUpload={handleBeforeUpload}
                                            onRemove={() => {
                                                setFiles((prev) => ({...prev, main: null}));
                                            }}
                                        >
                                            <ButtonAntd
                                                className={gridCx("upload-button")}
                                                icon={<FontAwesomeIcon icon={faUpload} />}
                                            >
                                                취합 파일 업로드
                                            </ButtonAntd>
                                        </Upload>
                                        {!files.main && (
                                            <p className={cx("reference")}>
                                                {response?.responseData.mergeType === "EXCEL"
                                                    ? "틀고정한 엑셀파일만 업로드 가능합니다."
                                                    : "다양한 형식의 파일을 업로드 가능합니다."}
                                            </p>
                                        )}
                                    </MergeGridItem>
                                )}
                                <MergeGridItem
                                    name={
                                        <div className={gridCx("question-name-container")}>
                                            <span>추가첨부</span>
                                            <Button
                                                label="일괄저장"
                                                color={"primary"}
                                                onClick={() => {
                                                    downloadAddFiles({noticeMergeId});
                                                }}
                                            />
                                        </div>
                                    }
                                    extraClassName={"upload-container"}
                                    span={response?.responseData.mergeType === "DATA"}
                                >
                                    <div className={gridCx("file-container")}>
                                        <Upload.Dragger
                                            className={gridCx("upload-dragger")}
                                            {...props}
                                            fileList={files.sub}
                                        >
                                            {addFileList.length ? (
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={addFileList}
                                                    className={gridCx("file-list")}
                                                    renderItem={(file, index) => (
                                                        <List.Item
                                                            className={gridCx("file-list-item")}
                                                            key={index}
                                                            actions={[
                                                                <ButtonAntd
                                                                    type="link"
                                                                    key={index}
                                                                    icon={<FontAwesomeIcon icon={faDownload} />}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        downloadAddFiles({id: file.id});
                                                                    }}
                                                                />,
                                                                <ButtonAntd
                                                                    type="link"
                                                                    danger
                                                                    key={index}
                                                                    icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleRemove(file);
                                                                    }}
                                                                />,
                                                            ]}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                                title={file.fileOriginName}
                                                                className={gridCx("file-list-item-meta")}
                                                                style={{textAlign: "left"}}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <>
                                                    <p>드래그하여 파일을 업로드 하거나 파일을 선택하세요</p>
                                                    <p>각 파일의 용량 제한은 500MB 입니다</p>
                                                    <p>용량이 초과될 경우 분할 압축을 하여 업로드 해주세요</p>
                                                </>
                                            )}
                                        </Upload.Dragger>
                                    </div>
                                </MergeGridItem>
                            </div>
                            <div className={cx("subtitle")}>
                                완료지점
                                <Input
                                    className={cx("search-input")}
                                    placeholder="완료지점 검색"
                                    value={completeSearchText}
                                    onChange={(e) => setCompleteSearchText(e.target.value)}
                                />
                                <ButtonAntd
                                    onClick={() => mergeDownload()}
                                    disabled={mergeDownloading}
                                    loading={mergeDownloading}
                                    className={cx("download-button")}
                                >
                                    취합파일 다운로드
                                </ButtonAntd>
                            </div>
                            <div className={cx("table-container")}>
                                <Table
                                    className={cx("table")}
                                    dataSource={filteredCompleteData.map((data, index) => ({...data, seq: index + 1}))}
                                    pagination={{
                                        pageSize: response?.responseData.mergeType === "DATA" ? pageSize * 10 : 10,
                                    }}
                                    bordered
                                    rowKey={response?.responseData.mergeType === "EXCEL" ? "answer" : "key"}
                                    columns={
                                        response?.responseData.mergeType === "EXCEL" ||
                                        response?.responseData.mergeType === "FILE"
                                            ? [
                                                  {
                                                      title: "No",
                                                      key: "index",
                                                      dataIndex: "seq",
                                                      align: "center",
                                                      width: "5%",
                                                  },
                                                  {
                                                      title: "지점",
                                                      key: "buildingName",
                                                      dataIndex: "buildingName",
                                                      sorter: (a, b) => a.buildingName.localeCompare(b.buildingName),
                                                      align: "center",
                                                  },
                                                  {
                                                      title: "등록일",
                                                      key: "createDate",
                                                      dataIndex: "createDate",
                                                      align: "center",
                                                      render: (value) =>
                                                          dayjs(new Date(value)).format("YYYY.MM.DD HH:mm"),
                                                      sorter: (a, b) =>
                                                          dayjs(a.createDate).unix() - dayjs(b.createDate).unix(),
                                                  },
                                                  {
                                                      title: "첨부파일",
                                                      key: "question",
                                                      dataIndex: "question",
                                                      width: "30%",
                                                      render: (value, record) => {
                                                          const refinedName = value ? value.split("/") : "";
                                                          const fileName = value
                                                              ? refinedName[refinedName.length - 1]
                                                              : "해당없음";
                                                          return value ? (
                                                              <span
                                                                  onClick={() => {
                                                                      const date = dayjs(new Date(record.createDate));
                                                                      downloadBuilding(
                                                                          record.answer,
                                                                          date.format("YYYY"),
                                                                          date.format("MM"),
                                                                      );
                                                                      console.log(record);
                                                                  }}
                                                                  className={cx("complete-table-file-name")}
                                                              >
                                                                  {fileName}
                                                              </span>
                                                          ) : (
                                                              fileName
                                                          );
                                                      },
                                                      align: "center",
                                                  },
                                                  {
                                                      title: "기한준수",
                                                      key: "isDelay",
                                                      dataIndex: "isDelay",
                                                      render: (value, record) => (
                                                          <Select
                                                              defaultValue={value}
                                                              key={record.buildingId}
                                                              onChange={(selected) =>
                                                                  handleIsDelay(record.buildingId, selected)
                                                              }
                                                              getPopupContainer={(trigger) => trigger.parentNode} // dropdown rendering
                                                              options={[
                                                                  {
                                                                      value: "Y",
                                                                      label: "지연",
                                                                  },
                                                                  {
                                                                      value: "N",
                                                                      label: "정상",
                                                                  },
                                                              ]}
                                                          />
                                                      ),
                                                      sorter: (a, b) => {
                                                          if (a.isDelay === b.isDelay) return 0;
                                                          if (a.isDelay === "Y") return -1;
                                                          return 1;
                                                      },
                                                      align: "center",
                                                  },
                                                  {
                                                      title: "지연사유",
                                                      key: "delayContent",
                                                      dataIndex: "delayContent",
                                                      align: "center",
                                                  },
                                              ]
                                            : /* ---------- 데이터취합 완료지점 ----------- */
                                              [
                                                  {
                                                      title: "No",
                                                      key: "index",
                                                      dataIndex: "index",
                                                      align: "center",
                                                      width: "5%",
                                                      onCell: (record) => ({
                                                          rowSpan: record.rowSpan || 0,
                                                          style: {verticalAlign: "middle"},
                                                      }),
                                                  },
                                                  {
                                                      title: "지점",
                                                      key: "buildingName",
                                                      dataIndex: "buildingName",
                                                      width: "10%",
                                                      sorter: (a, b) => a.buildingName.localeCompare(b.buildingName),
                                                      align: "center",
                                                      onCell: (record) => ({
                                                          rowSpan: record.rowSpan || 0,
                                                          style: {verticalAlign: "middle"},
                                                      }),
                                                  },
                                                  {
                                                      title: "등록일",
                                                      key: "createDate",
                                                      dataIndex: "createDate",
                                                      align: "center",
                                                      width: "10%",
                                                      render: (value) =>
                                                          dayjs(new Date(value)).format("YYYY.MM.DD HH:mm"),
                                                      sorter: (a, b) =>
                                                          dayjs(a.createDate).unix() - dayjs(b.createDate).unix(),
                                                      onCell: (record) => ({
                                                          rowSpan: record.rowSpan || 0,
                                                          style: {verticalAlign: "middle"},
                                                      }),
                                                  },
                                                  {
                                                      title: "항목",
                                                      key: "question",
                                                      dataIndex: "question",
                                                      width: "20%",
                                                  },
                                                  {
                                                      title: "취합내용",
                                                      key: "answer",
                                                      dataIndex: "answer",
                                                      width: "20%",
                                                      render: (value, record) =>
                                                          record.isNone === "Y" ? "해당없음" : value,
                                                  },
                                                  {
                                                      title: "기한준수",
                                                      key: "isDelay",
                                                      width: "5%",
                                                      dataIndex: "isDelay",
                                                      render: (value, record) => (
                                                          <Select
                                                              defaultValue={value}
                                                              key={record.buildingId}
                                                              onChange={(selected) =>
                                                                  handleIsDelay(record.buildingId, selected)
                                                              }
                                                              getPopupContainer={(trigger) => trigger.parentNode} // dropdown rendering
                                                              options={[
                                                                  {
                                                                      value: "Y",
                                                                      label: "지연",
                                                                  },
                                                                  {
                                                                      value: "N",
                                                                      label: "정상",
                                                                  },
                                                              ]}
                                                          />
                                                      ),
                                                      sorter: (a, b) => {
                                                          if (a.isDelay === b.isDelay) return 0;
                                                          if (a.isDelay === "Y") return -1;
                                                          return 1;
                                                      },
                                                      align: "center",
                                                      onCell: (record) => ({
                                                          rowSpan: record.rowSpan || 0,
                                                          style: {verticalAlign: "middle"},
                                                      }),
                                                  },
                                                  {
                                                      title: "지연사유",
                                                      width: "10%",
                                                      key: "delayContent",
                                                      dataIndex: "delayContent",
                                                      align: "center",
                                                      onCell: (record) => ({
                                                          rowSpan: record.rowSpan || 0,
                                                          style: {verticalAlign: "middle"},
                                                      }),
                                                  },
                                              ]
                                    }
                                />
                            </div>
                            <div className={cx("subtitle")}>
                                미완료지점
                                <Input
                                    className={cx("search-input")}
                                    placeholder="미완료지점 검색"
                                    value={incompleteSearchText}
                                    onChange={(e) => setIncompleteSearchText(e.target.value)}
                                />
                                <Button label="일괄마감" onClick={handleAllComplete} />
                            </div>
                            <div className={cx("table-container")}>
                                <Table
                                    className={cx("table")}
                                    bordered
                                    rowSelection={{
                                        selectedRowKeys: selectedIncompleteRow,
                                        onChange: (selectedRowKeys: React.Key[]) => {
                                            setSelectedIncompleteRow(selectedRowKeys);
                                        },
                                    }}
                                    pagination={{pageSize: 10}}
                                    dataSource={filteredIncompleteData.map((data, index) => ({
                                        ...data,
                                        seq: index + 1,
                                    }))}
                                    rowKey="buildingId"
                                    columns={[
                                        {
                                            title: "No",
                                            key: "index",
                                            dataIndex: "seq",
                                            align: "center",
                                            width: "5%",
                                        },
                                        {
                                            title: "지점",
                                            key: "buildingName",
                                            dataIndex: "buildingName",
                                            sorter: (a, b) => a.buildingName.localeCompare(b.buildingName),
                                            align: "center",
                                            width: "30%",
                                        },
                                        {
                                            title: "등록일",
                                            key: "createDate",
                                            dataIndex: "createDate",
                                            align: "center",
                                            render: (value) =>
                                                value ? dayjs(new Date(value)).format("YYYY.MM.DD HH:mm") : "-",
                                            sorter: (a, b) => dayjs(a.createDate).unix() - dayjs(b.createDate).unix(),
                                        },
                                        {
                                            title: "연락처",
                                            key: "phoneNumber",
                                            dataIndex: "phoneNumber",
                                            align: "center",
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx("button-container")}>
                    <Button onClick={handleDeleteDetail} label="삭제" />
                    <Button onClick={handleUpdateDetail} label="저장" />
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                </div>
            </div>
        </div>,
        document.body,
    );
};

export {MergeBoardDetailModal};
