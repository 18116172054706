import {useMemo, useState} from "react";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";
import {useShopCascaderRefined} from "~/hooks/use-shop-cascader/use-shop-cascader";

import {
    CostFacilityExcelDownload,
    FacilityMaintenanceList,
    FacilityMaintenanceListRequest,
    FacilityMaintenanceListResponse,
} from "./facility-maintenance.interface";

const useFacilityMaintenanceList = (
    config: FacilityMaintenanceList,
): [() => Promise<void>, FacilityMaintenanceListResponse | null, boolean, any] => {
    const [response, setResponse] = useState<FacilityMaintenanceListResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        const refinedData = () => {
            if (config.selected.length) {
                switch (config.selected[0]) {
                    case "ALL":
                        return null;
                    // break;
                    case "SECTOR":
                        return {
                            sectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    case "TRAINER":
                        return {
                            trainerSectorId: config.selected[1],
                            ...(config.selected[2] !== "0" && {buildingId: config.selected[2]}),
                        };
                    // break;
                    default:
                        return null;
                    // break;
                }
            }
        };

        return httpRequest<FacilityMaintenanceListRequest, FacilityMaintenanceListResponse>({
            method: "GET",
            url: "/lotte/cost/facility/month/plans",
            params: {
                year: config.year && config.year.format("YYYY"),
                month: config.month ? config.month.format("MM") : null,
                yearProcessId: config.yearProcessId === "ALL" ? null : config.yearProcessId,
                typeId: config.typeId === "ALL" ? null : config.typeId,
                state: config.state === "ALL" ? null : config.state,
                startCost: config.startCost,
                endCost: config.endCost,
                yearConstructName: config.yearConstructName === "" ? null : config.yearConstructName,
                pageNo: config.page,
                recordPerPage: config.perPage,
                ...refinedData(),
            },
        });
    }, [config, config.selected]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

// 엑셀 다운
const useCostFacilityExcelDownload = (config: CostFacilityExcelDownload) => {
    const [error, setError] = useState<any>(null);
    const refinedData = useShopCascaderRefined(config.selected);

    const request = useMemo(() => {
        return httpRequest<FacilityMaintenanceListRequest, Blob>({
            method: "GET",
            url: "/lotte/cost/facility/month/plans/exceldownload",
            params: {
                year: config.year && config.year.format("YYYY"),
                month: config.month ? config.month.format("MM") : null,
                yearProcessId: config.yearProcessId === "ALL" ? null : config.yearProcessId,
                typeId: config.typeId === "ALL" ? null : config.typeId,
                state: config.state === "ALL" ? null : config.state,
                startCost: config.startCost,
                endCost: config.endCost,
                yearConstructName: config.yearConstructName === "" ? null : config.yearConstructName,
                ...refinedData,
                ...(config.budgetDivision ? {budgetDivision: config.budgetDivision} : {}),
            },
            responseType: "blob",
        });
    }, [config]);

    const submit = async () => {
        try {
            const response = await request.submit();
            const blob = new Blob([response.data], {type: response.headers["content-type"]});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // 파일명 추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";

            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = matches[1].trim().replace(/['"]/g, "");
                }
            }

            link.setAttribute("download", decodeURIComponent(fileName.replace(/\+/g, " ")));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (e) {
            console.log(e);
            setError(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        }
    };
    return [submit, error];
};

export {useCostFacilityExcelDownload, useFacilityMaintenanceList};
