import {Modal} from "antd";

import Button from "~/components/button/button";

import {CenterModalProps} from "./center-modal.interface";

const CenterModal = ({close, isShow, extraButtons, children, refer, ...props}: CenterModalProps) => {
    return (
        <Modal
            open={isShow}
            onCancel={close}
            zIndex={11000}
            footer={[...extraButtons, <Button label="닫기" key={"close"} onClick={close} />]}
            {...props}
            panelRef={refer}
        >
            {children}
        </Modal>
    );
};

export {CenterModal};
