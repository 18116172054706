import React from "react";
import {useSortable} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {faUpDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import styles from "./update-form-item.module.scss";

const cx = classNames.bind(styles);

const UpdateFormItem = (props: any) => {
    const {item, children} = props;

    const {attributes, listeners, setNodeRef, transform, transition, isDragging} = useSortable({
        id: item.checkFormItemId,
    });
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        border: "1px solid #ccc",
        marginBottom: "4px",
        backgroundColor: "white",
        zIndex: isDragging ? "100000" : "unset",
    };

    return (
        <tr ref={setNodeRef} style={style} {...attributes}>
            <td>
                <button {...listeners} className={cx("drag-button")}>
                    <FontAwesomeIcon icon={faUpDown} />
                </button>
            </td>
            {children}
        </tr>
    );
};

export {UpdateFormItem};
