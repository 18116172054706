import {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {CustomTaskList, CustomTaskListResponse, Task} from "./use-tasks.interface";

function useTasks(type: "checklist" | "extra" | "legal" | "normal" | undefined) {
    const [tasks, setTasks] = useState<Array<Task>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        let url = "";
        if (type === "legal" || type === "checklist") url = "/buildings/checks/report/tasks";
        else url = "/buildings/checks/tasks";
        return httpRequest<
            {
                limit: number;
                offset: number;
                category: "LEGAL" | "CHECK" | "ADD" | "NORMAL";
            },
            Array<Task>
        >({
            url: url,
            params: {
                limit: 1000,
                offset: 0,
                category:
                    type === "legal" ? "LEGAL" : type === "checklist" ? "CHECK" : type === "extra" ? "ADD" : "NORMAL",
            },
        });
    }, [type]);

    useEffect(() => shoot(), [request]);

    const shoot = () => {
        setLoading(true);
        request
            .submit()
            .then((response) => setTasks(response.data))
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        loading,
        tasks: tasks,
        refresh: shoot,
        totalCount: tasks.length,
    };
}

function stopTask(checkTaskId: number) {
    return httpRequest<undefined, undefined>({
        method: "DELETE",
        url: "/buildings/checks/tasks/task?checkTaskId=" + checkTaskId,
    }).submit();
}
function deleteHistory(checkHistoryId: number) {
    return httpRequest<undefined, undefined>({
        method: "DELETE",
        url: `/reportCheck/report/tasks/task/${checkHistoryId}`,
    }).submit();
}

function useCustomTasksLists(buildingId: number) {
    const [tasksLists, setTasksLists] = useState<Array<CustomTaskList>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<
            {
                buildingId: number;
                year: number;
                month: number;
            },
            CustomTaskListResponse
        >({
            url: "/buildings/checks/custom",
            params: {
                buildingId: buildingId,
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1,
            },
        });
    }, []);

    useEffect(() => shoot(), [request]);

    const shoot = () => {
        setLoading(true);
        request
            .submit()
            .then((response) => setTasksLists(response.data.checkList))
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        tasksListsLoading: loading,
        tasksLists: tasksLists,
        tasksListsRefresh: shoot,
        tasksListsTotalCount: tasksLists.length,
    };
}

export {deleteHistory, stopTask, useCustomTasksLists, useTasks};
