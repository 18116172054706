import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import {useLocation, useNavigate} from "react-router-dom";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, DatePicker, Input, Radio, Select, Upload} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {PageTitle} from "~/components/page-title";
import {useCheckElevator, useElevatorList} from "~/data/check/elevator/use-check-elevator";
import {useUser} from "~/data/user";
import {singleFileUpload} from "~/fetch/single-file-upload";
import {fileToBase64} from "~/utills/file-to-base64";

import {ElevatorCheckRequestModalProps} from "./elevator-check-request.interface";

import styles from "./elevator-check-request.modal.module.scss";

const cx = classNames.bind(styles);

const {TextArea} = Input;
const {TimePicker} = DatePicker;

const ElevatorCheckRequestModal = ({close, refresh}: ElevatorCheckRequestModalProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [time, setTime] = useState<Dayjs>(dayjs(new Date()));
    const [receiveTime, setReceiveTime] = useState<Dayjs>(dayjs(new Date()));
    const [arriveTimeRaw, setArriveTimeRaw] = useState<Dayjs | null>(null);
    const [completedTimeRaw, setCompletedTimeRaw] = useState<Dayjs | null>(null);
    const [expectedTime, setExpectedTime] = useState<Dayjs | null>(null);
    const [type, setType] = useState<string>("NORMAL");

    const [breakDate, setBreakDate] = useState<string>("");
    const [employeeName, setEmployeeName] = useState<string>("");
    const [breakLocation, setBreakLocation] = useState<string>("");
    const [breakUnit, setBreakUnit] = useState<string>("");
    const [contactTime, setContactTime] = useState<string>("");
    const [recipientName, setRecipientName] = useState<string>("");
    const [content, setContent] = useState<string>("");
    const [inspectorName, setInspectorName] = useState<string>("");
    const [stopTime, setStopTime] = useState<string>("");
    const [arriveTime, setArriveTime] = useState<string>("");
    const [completedTime, setCompletedTime] = useState<string>("");
    const [breakCause, setBreakCause] = useState<string>("");
    const [actionContent, setActionContent] = useState<string>("");
    const [remark, setRemark] = useState<string>("");
    const [expectedArriveTime, setExpectedArriveTime] = useState<string | null>("");

    const [imageFile, setImageFile] = useState<{
        file: string;
        fileName: string;
        filePath: string;
    }>();

    const navigate = useNavigate();
    const location = useLocation();
    const {buildingId} = useUser();
    const isInitialMount = useRef(true);
    const [isBlocking, setIsBlocking] = React.useState(true);

    const {elevatorList} = useElevatorList(buildingId ?? 0);

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            window.history.pushState(null, "", window.location.href);
        }

        const handlePopState = (event: PopStateEvent) => {
            if (isBlocking) {
                Swal.fire({
                    title: "정말 뒤로 가시겠습니까?",
                    text: "변경사항이 저장되지 않을 수 있습니다.",
                    showCancelButton: true,
                    confirmButtonText: "예",
                    cancelButtonText: "아니오",
                }).then((result) => {
                    if (result.isConfirmed) {
                        setIsBlocking(false); // 뒤로가기를 허용
                        navigate(-1); // 사용자가 확인을 누르면 뒤로가기
                    } else {
                        navigate(location.pathname); // 사용자가 취소하면 현재 페이지 유지
                    }
                });
            }
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, [isBlocking, navigate, location.pathname]);

    useEffect(() => setBreakDate(time.format("YYYY-MM-DD HH:mm")), [time]);
    useEffect(() => setContactTime(receiveTime.format("HH:mm")), [receiveTime]);
    useEffect(() => setArriveTime(arriveTimeRaw ? arriveTimeRaw.format("HH:mm") : ""), [arriveTimeRaw]);
    useEffect(() => setCompletedTime(completedTimeRaw ? completedTimeRaw.format("HH:mm") : ""), [completedTimeRaw]);
    useEffect(() => setExpectedArriveTime(expectedTime ? expectedTime.format("HH:mm") : null), [expectedTime]);

    const {request} = useCheckElevator();

    const {user} = useUser();

    const handleSubmit = () => {
        if (loading) {
            Swal.fire({
                title: "이전 요청을 처리중입니다.",
                text: "잠시 후 다시 시도해 주세요.",
                confirmButtonText: "확인",
            });
        } else {
            setLoading(true);
            request({
                buildingId: user!.buildingId,
                breakDate,
                employeeName,
                breakLocation,
                breakUnit,
                contactTime,
                recipientName,
                content,
                remark,
                type,
                expectedArriveTime,
                arriveTime,
                inspectorName,
                actionContent,
                breakCause,
                completedTime,
                stopTime,
                liftImageName: imageFile?.fileName,
                liftImageUrl: imageFile?.filePath,
            })
                .then((res) => {
                    Swal.fire({
                        title: "승강기 점검 요청이 완료되었습니다.",
                        confirmButtonText: "확인",
                    }).then(() => {
                        handleClose();
                    });
                })
                .catch(() => {
                    Swal.fire({
                        title: "오류가 발생했습니다.",
                        text: "잠시 후 다시 시도해 주세요.",
                        confirmButtonText: "확인",
                    });
                })
                .finally(() => {
                    setLoading(false);
                    setTimeout(refresh, 500);
                });
        }
    };

    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
    }, []);

    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => close(), 500);
    }, [isLoading]);

    const handleClose = () => {
        setIsLoading(false);
    };

    return createPortal(
        <div className={cx("container", {active: isLoading})} onClick={handleClose}>
            <div
                className={cx("inner", {active: isLoading})}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div className={cx("contents-container")}>
                    <PageTitle>승강기 점검 요청하기</PageTitle>
                    <div className={cx("half-container")}>
                        <div className={cx("form-container")}>
                            <ul className={cx("list")}>
                                <li className={cx("item")}>
                                    <label htmlFor="category" className={cx("label")}>
                                        구분
                                    </label>
                                    <Radio.Group
                                        onChange={(e) => setType(e.target.value)}
                                        defaultValue={type}
                                        value={type}
                                        className={cx("radio-container")}
                                    >
                                        <Radio value={"NORMAL"}>정상</Radio>
                                        <Radio value={"BREAK"}>고장</Radio>
                                    </Radio.Group>
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="time" className={cx("label")}>
                                        고장일시
                                    </label>
                                    <DatePicker
                                        className={cx("field")}
                                        showTime
                                        id="time"
                                        value={time}
                                        onChange={setTime}
                                        format="YYYY-MM-DD HH:mm"
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="employeeName" className={cx("label")}>
                                        근무자
                                    </label>
                                    <Input
                                        placeholder="근무자"
                                        id="employeeName"
                                        className={cx("field")}
                                        onChange={(e) => setEmployeeName(e.target.value)}
                                        value={employeeName}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="breakLocation" className={cx("label")}>
                                        고장위치
                                    </label>
                                    <Input
                                        placeholder="고장위치"
                                        id="breakLocation"
                                        className={cx("field")}
                                        onChange={(e) => setBreakLocation(e.target.value)}
                                        value={breakLocation}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="breakUnit" className={cx("label")}>
                                        고장호기
                                    </label>
                                    <Select
                                        placeholder={"고장호기"}
                                        options={elevatorList.map((data) => ({
                                            label: data.machineName,
                                            value: data.machineName,
                                        }))}
                                        getPopupContainer={(trigger) => trigger.parentNode}
                                        className={cx("field")}
                                        value={breakUnit ? breakUnit : null}
                                        onChange={(e) => setBreakUnit(e)}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="breakUnit" className={cx("label")}></label>
                                    <Input
                                        placeholder="고장호기 직접입력"
                                        id="breakUnit"
                                        className={cx("field")}
                                        onChange={(e) => setBreakUnit(e.target.value)}
                                        value={breakUnit}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="content" className={cx("label")}>
                                        고장내용
                                    </label>
                                    <TextArea
                                        placeholder="고장내용"
                                        id="content"
                                        className={cx("field")}
                                        onChange={(e) => setContent(e.target.value)}
                                        value={content}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <hr />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="receiveTime" className={cx("label")}>
                                        연락시간
                                    </label>
                                    <TimePicker
                                        onChange={setReceiveTime}
                                        format="HH:mm"
                                        id="receiveTime"
                                        value={receiveTime}
                                        className={cx("field")}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="recipientName" className={cx("label")}>
                                        수신자
                                    </label>
                                    <Input
                                        placeholder="수신자"
                                        id="recipientName"
                                        className={cx("field")}
                                        onChange={(e) => setRecipientName(e.target.value)}
                                        value={recipientName}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="expectedArriveTime" className={cx("label")}>
                                        도착예정시간
                                    </label>
                                    <TimePicker
                                        onChange={setExpectedTime}
                                        format="HH:mm"
                                        value={expectedTime}
                                        className={cx("field")}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="remark" className={cx("label")}>
                                        비고
                                    </label>
                                    <TextArea
                                        placeholder="비고"
                                        id="remark"
                                        className={cx("field")}
                                        value={remark}
                                        onChange={(e) => setRemark(e.target.value)}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={cx("half-container")}>
                        <div className={cx("form-container")}>
                            <ul className={cx("list")}>
                                <li className={cx("item")}>
                                    <label htmlFor="receiveTime" className={cx("label")}>
                                        도착시간
                                    </label>
                                    <TimePicker
                                        onChange={(date) => setArriveTimeRaw(date)}
                                        id="receiveTime"
                                        format="HH:mm"
                                        value={arriveTimeRaw}
                                        className={cx("field")}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="inspectorName" className={cx("label")}>
                                        보수자 성명
                                    </label>
                                    <Input
                                        placeholder="보수자 성명"
                                        id="inspectorName"
                                        className={cx("field")}
                                        value={inspectorName}
                                        onChange={(e) => setInspectorName(e.target.value)}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="completedTimeRaw" className={cx("label")}>
                                        조치완료시간
                                    </label>
                                    <TimePicker
                                        onChange={(date) => setCompletedTimeRaw(date)}
                                        format="HH:mm"
                                        id={"completedTimeRaw"}
                                        value={completedTimeRaw}
                                        className={cx("field")}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="stopTime" className={cx("label")}>
                                        비운행시간
                                    </label>
                                    <Input
                                        placeholder="비운행시간"
                                        id="stopTime"
                                        className={cx("field")}
                                        value={stopTime}
                                        onChange={(e) => setStopTime(e.target.value)}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="breakCause" className={cx("label")}>
                                        고장원인
                                    </label>
                                    <TextArea
                                        placeholder="고장원인"
                                        id="breakCause"
                                        className={cx("field")}
                                        value={breakCause}
                                        onChange={(e) => setBreakCause(e.target.value)}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="actionContent" className={cx("label")}>
                                        조치내용
                                    </label>
                                    <TextArea
                                        placeholder="조치내용"
                                        id="actionContent"
                                        className={cx("field")}
                                        value={actionContent}
                                        onChange={(e) => setActionContent(e.target.value)}
                                    />
                                </li>
                                <li className={cx("item")}>
                                    <label htmlFor="error-message" className={cx("label")}>
                                        현장사진
                                    </label>
                                    <Upload
                                        className={cx("field")}
                                        maxCount={1}
                                        multiple
                                        customRequest={singleFileUpload}
                                        onChange={(res) => {
                                            if (res.fileList.length === 0) setImageFile(undefined);

                                            try {
                                                if (res.file.status === "done") {
                                                    fileToBase64(res.file.originFileObj).then((rst: string) => {
                                                        console.log(res.file.response);
                                                        setImageFile({
                                                            file: rst,
                                                            fileName: res.file.fileName as string,
                                                            filePath: res.file.response.filePath,
                                                        });
                                                    });
                                                }
                                            } catch (e) {
                                                console.error(e);
                                            }
                                        }}
                                    >
                                        <ButtonAntd icon={<FontAwesomeIcon icon={faUpload} />}>
                                            현장사진 첨부하기
                                        </ButtonAntd>
                                    </Upload>
                                </li>
                                {imageFile?.filePath && (
                                    <li>
                                        <img className={cx("thumbnail")} src={imageFile?.file.toString()} alt="" />
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={cx("button-container")}>
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                    <Button onClick={handleSubmit} label="요청하기" />
                </div>
            </div>
        </div>,
        document.body,
    );
};

export {ElevatorCheckRequestModal};
