import {useState} from "react";
import {message} from "antd";

import {httpRequest} from "~/fetch/common/http-request";
import {WorkListType} from "~/pages/work-plan/work-plan.interface";

import {SettingWorkPlanUpdateRequest} from "./setting-work-plan.interface";

const useSettingWorkPlan = (config: SettingWorkPlanUpdateRequest) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const update = () => {
        return httpRequest<SettingWorkPlanUpdateRequest, any>({
            method: "POST",
            url: "/dailyWork/type/update",
            data: config,
        })
            .submit()
            .then((res) => {
                setIsLoading(true);
                if (res.data.result === "SUCCESS") {
                    message.success("저장되었습니다");
                }
            })
            .catch((e) => {
                message.error("에러가 발생하였습니다. 다시 시도해주세요");
                setError(e);
            })
            .finally(() => setIsLoading(false));
    };

    const deleteWork = (work: WorkListType) => {
        return httpRequest<WorkListType, any>({
            method: "POST",
            url: "/dailyWork/type/delete",
            data: {
                workTypeId: work.workTypeId,
                name: work.name,
                defaultYn: work.defaultYn,
                detailYn: work.defaultYn,
                sectorYn: work.sectorYn,
                trainerSectorId: work.trainerSectorId,
                deleteYn: work.deleteYn,
                createDate: work.createDate,
            },
        })
            .submit()
            .then((res) => {
                setIsLoading(true);
                if (res.data.result === "SUCCESS") {
                    message.success("삭제되었습니다");
                }
            })
            .catch((e) => {
                message.error("에러가 발생하였습니다. 다시 시도해주세요");
                setError(e);
            })
            .finally(() => setIsLoading(false));
    };

    return {
        update,
        deleteWork,
        isLoading,
        error,
    };
};

export {useSettingWorkPlan};
