import React, {ChangeEvent, useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {faPaperclip, faTrashCan, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, DatePicker, Input, List, Radio, Spin, Upload, UploadFile, UploadProps} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item/merge-grid-item";
import {MergeQuestionList} from "~/components/merge/merge-question-list/merge-question-list";
import {MergeTargetList} from "~/components/merge/merge-target-list/merge-target-list";
import {PageTitle} from "~/components/page-title/page-title";
import {MergeRegisterCheckDRMResponse} from "~/data/merge/register/register.interface";
import {
    useMergeRegisterCheckDRM,
    useMergeRegisterTargetList,
    useMergeRegistration,
} from "~/data/merge/register/use-register";

import {MergeBoardRegisterModalProps, MergeRegisterInput} from "./merge-board-register.modal.interface";

import styles from "./merge-board-register.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const MergeBoardRegisterModal = ({close, submit}: MergeBoardRegisterModalProps) => {
    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchSpecific, setSearchSpecific] = useState<string>("");
    const [files, setFiles] = useState<{main: File | null; sub: UploadFile[]}>({main: null, sub: []});
    const [input, setInput] = useState<MergeRegisterInput>({
        managerName: "",
        title: "",
        content: "",
        year: dayjs(new Date()).format("YYYY"),
        month: dayjs(new Date()).format("MM"),
        mergeType: "EXCEL",
        targetType: "ALL",
        targetDTOList: [],
        questionDTOList: [
            {
                name: "1",
                value: "",
            },
        ],
        important: 0,
        dateRange: [dayjs(new Date()), dayjs(new Date())],
    }); // 제목, 내용, 담당자

    // 대상 리스트
    const [submitTargetList, targetList, isTargetListLoading] = useMergeRegisterTargetList();
    //DRM 검사
    const [checkDRM, formatFileInfo] = useMergeRegisterCheckDRM({uploadFile: files.main});

    // 등록
    const [submitRegister, isRegLoading] = useMergeRegistration({
        ...input,
        files,
        fileFreezePaneCounts: formatFileInfo?.fileFreezePaneCounts ?? null,
        fileSheetNames: formatFileInfo?.fileSheetNames ?? null,
    });

    // 초기 셋팅
    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
        submitTargetList();
    }, []);
    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => close(), 500);
    }, [isLoading]);

    useEffect(() => {
        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    const handleClose = () => {
        setIsLoading(false);
    };

    // 등록
    const handleRegistration = () => {
        if (input.managerName === "" && input.title === "") {
            Swal.fire({
                text: "필수항목을 모두 입력해주세요",
                confirmButtonText: "확인",
            }).then((info) => {
                if (info.isConfirmed) {
                    return false;
                }
            });
        } else {
            if (input.mergeType === "EXCEL" && files.main === null) {
                Swal.fire({
                    text: "취합양식 파일을 업로드 해주세요",
                    confirmButtonText: "확인",
                }).then((info) => {
                    if (info.isConfirmed) {
                        return false;
                    }
                });
            } else {
                if (input.mergeType === "DATA" && input.questionDTOList.length === 0) {
                    Swal.fire({
                        text: "필수항목을 모두 입력해주세요",
                        confirmButtonText: "확인",
                    }).then((info) => {
                        if (info.isConfirmed) {
                            return false;
                        }
                    });
                } else {
                    Swal.fire({
                        text: "등록하시겠습니까?",
                        showCancelButton: true,
                        confirmButtonText: "확인",
                        cancelButtonText: "취소",
                    })
                        .then((info) => {
                            if (info.isConfirmed) {
                                submitRegister();
                                setIsLoading(false);
                            }
                        })
                        .finally(() => {
                            setTimeout(submit, 1000);
                        });
                }
            }
        }
    };

    // title, content, manage name
    const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    // 항목 추가
    const handleAddQuestion = () => {
        const newName = input.questionDTOList.length
            ? (Math.max(...input.questionDTOList.map((item) => parseInt(item.name))) + 1).toString()
            : "1";
        const newQuestion = {name: newName, value: ""};
        setInput({...input, questionDTOList: [...input.questionDTOList, newQuestion]});
    };

    // 대상 타입 바뀔 때 마다 값 초기화
    useEffect(() => {
        if (input.targetType === "ALL") {
            setInput({...input, targetDTOList: [0]});
        } else {
            setInput({
                ...input,
                targetDTOList: [],
            });
        }
    }, [input.targetType]);

    // 취합파일양식 DRM 검사
    const handleBeforeUpload = async (file: File) => {
        if (input.mergeType === "FILE") {
            setFiles((prev) => ({...prev, main: file}));

            return false;
        } else {
            const result: MergeRegisterCheckDRMResponse | null = await checkDRM(file);

            if (result?.responseResult === "SUCCESS") {
                setFiles((prev) => ({...prev, main: file}));
            } else {
                Swal.fire({
                    title: "파일 오류",
                    text: result?.responseMessage,
                    confirmButtonText: "확인",
                });
                return Upload.LIST_IGNORE;
            }
            return false;
        }
    };

    /* ------------------------------- 추가첨부 ------------------------------- */
    const uploadProps: UploadProps = {
        name: "file",
        multiple: true,
        fileList: files.sub,
        maxCount: 10,
        onChange(info) {
            const newFileList = [...info.fileList];
            setFiles((prev) => ({...prev, sub: newFileList}));
        },
        beforeUpload: () => {
            if (files.sub.length >= 10) {
                Swal.fire({
                    text: "파일은 최대 10개까지 업로드 가능합니다.",
                    confirmButtonText: "확인",
                });
            }
            return false;
        },
        showUploadList: false,
    };

    const handleRemove = (file: UploadFile) => {
        setFiles((prev) => ({...prev, sub: prev.sub.filter((item) => item.uid !== file.uid)}));
    };

    return createPortal(
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading})}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className={cx("contents-container")}>
                    <PageTitle>취합 게시판 등록</PageTitle>
                    {isTargetListLoading ? (
                        <div className={cx("loading")}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <div className={cx("form-container")}>
                            <div className={cx("detail-content", {data: input.mergeType === "DATA"})}>
                                <MergeGridItem name={"연도"}>
                                    <DatePicker
                                        picker="year"
                                        locale={locale}
                                        value={dayjs(input.year)}
                                        onChange={(value) =>
                                            setInput({
                                                ...input,
                                                year: dayjs(value).format("YYYY"),
                                            })
                                        }
                                        allowClear={false}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"월"}>
                                    <DatePicker
                                        picker="month"
                                        locale={locale}
                                        value={dayjs(input.year + input.month)}
                                        format={"MM"}
                                        onChange={(value) =>
                                            setInput({
                                                ...input,
                                                month: dayjs(value).format("MM"),
                                            })
                                        }
                                        cellRender={(date) => (
                                            <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                                        )}
                                        allowClear={false}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"취합기간"}>
                                    <DatePicker.RangePicker
                                        className={cx("range-picker")}
                                        value={input.dateRange}
                                        allowClear={false}
                                        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                        //@ts-ignore
                                        onChange={(value: [Dayjs, Dayjs]) => setInput({...input, dateRange: value})}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"담당자"} require>
                                    <Input
                                        className={gridCx("input")}
                                        name="managerName"
                                        onChange={handleChangeInput}
                                        value={input.managerName}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"제목"} span require>
                                    <Input
                                        className={gridCx("input")}
                                        name="title"
                                        onChange={handleChangeInput}
                                        value={input.title}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"구분"}>
                                    <Radio.Group
                                        className={gridCx("radio-group")}
                                        value={input.mergeType}
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                mergeType: e.target.value,
                                            })
                                        }
                                    >
                                        <Radio className={gridCx("radio")} value={"EXCEL"}>
                                            엑셀 취합
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"FILE"}>
                                            파일 취합
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"DATA"}>
                                            데이터 취합
                                        </Radio>
                                    </Radio.Group>
                                </MergeGridItem>
                                <MergeGridItem name={"중요도"}>
                                    <Radio.Group
                                        className={gridCx("radio-group")}
                                        value={input.important}
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                important: e.target.value,
                                            })
                                        }
                                    >
                                        <Radio className={gridCx("radio")} value={0}>
                                            -
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={1}>
                                            ★
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={2}>
                                            ★★
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={3}>
                                            ★★★
                                        </Radio>
                                    </Radio.Group>
                                </MergeGridItem>
                                <MergeGridItem name={"대상"} span extraClassName="justify">
                                    <Radio.Group
                                        className={gridCx("radio-group-target")}
                                        value={input.targetType}
                                        onChange={(e) =>
                                            setInput({
                                                ...input,
                                                targetType: e.target.value,
                                            })
                                        }
                                    >
                                        <Radio className={gridCx("radio")} value={"ALL"}>
                                            전체
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"SECTOR"}>
                                            특정부문
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"TRAINER"}>
                                            특정 트레이너
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={"SPECIFIC"}>
                                            특정지점
                                        </Radio>
                                    </Radio.Group>
                                    <div className={gridCx("target-input-container")}>
                                        <span className={gridCx("count")}>{input.targetDTOList.length}개 선택됨</span>
                                        {input.targetType === "SPECIFIC" && (
                                            <Input
                                                className={gridCx("input")}
                                                placeholder="지점명 검색"
                                                value={searchSpecific}
                                                onChange={(e) => setSearchSpecific(e.target.value)}
                                            />
                                        )}
                                    </div>
                                    <MergeTargetList
                                        selectedTargetList={input.targetDTOList}
                                        targetType={input.targetType}
                                        targetList={targetList}
                                        searchText={searchSpecific}
                                        onChange={(e) => setInput({...input, targetDTOList: e})}
                                    />
                                </MergeGridItem>
                                <MergeGridItem name={"내용"} span>
                                    <Input.TextArea
                                        className={gridCx("text-area", "input")}
                                        name="content"
                                        onChange={handleChangeInput}
                                        value={input.content}
                                    />
                                </MergeGridItem>
                                {(input.mergeType === "EXCEL" || input.mergeType === "FILE") && (
                                    <MergeGridItem
                                        name={"첨부양식"}
                                        extraClassName={"upload-container"}
                                        require={input.mergeType === "EXCEL"}
                                    >
                                        <Upload
                                            className={gridCx("upload")}
                                            maxCount={1}
                                            beforeUpload={handleBeforeUpload}
                                            onRemove={() => {
                                                setFiles((prev) => ({...prev, main: null}));
                                            }}
                                        >
                                            <p className={cx("reference")}>
                                                {input.mergeType === "EXCEL"
                                                    ? "틀고정한 엑셀파일만 업로드 가능합니다."
                                                    : "다양한 형식의 파일을 업로드 가능합니다."}
                                            </p>
                                            <ButtonAntd
                                                className={gridCx("upload-button")}
                                                icon={<FontAwesomeIcon icon={faUpload} />}
                                            >
                                                취합 파일 업로드
                                            </ButtonAntd>
                                        </Upload>
                                    </MergeGridItem>
                                )}
                                <MergeGridItem
                                    name={"추가첨부"}
                                    extraClassName={"upload-container"}
                                    span={input.mergeType === "DATA"}
                                >
                                    <div className={gridCx("file-container")}>
                                        <Upload.Dragger className={gridCx("upload-dragger")} {...uploadProps}>
                                            {files.sub.length ? (
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={files.sub}
                                                    className={gridCx("file-list")}
                                                    renderItem={(file, index) => (
                                                        <List.Item
                                                            className={gridCx("file-list-item")}
                                                            actions={[
                                                                <ButtonAntd
                                                                    type="link"
                                                                    danger
                                                                    key={index}
                                                                    icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        handleRemove(file);
                                                                    }}
                                                                />,
                                                            ]}
                                                        >
                                                            <List.Item.Meta
                                                                avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                                title={file.name}
                                                                className={gridCx("file-list-item-meta")}
                                                                style={{textAlign: "left"}}
                                                            />
                                                        </List.Item>
                                                    )}
                                                />
                                            ) : (
                                                <>
                                                    <p>드래그하여 파일을 업로드 하거나 파일을 선택하세요</p>
                                                    <p>각 파일의 용량 제한은 500MB 입니다 (최대 10개)</p>
                                                    <p>용량이 초과될 경우 분할 압축을 하여 업로드 해주세요</p>
                                                </>
                                            )}
                                        </Upload.Dragger>
                                    </div>
                                </MergeGridItem>
                                {input.mergeType === "DATA" && (
                                    <MergeGridItem
                                        name={
                                            <div className={gridCx("question-name-container")}>
                                                <span>
                                                    <span style={{color: "red"}}>* </span>항목
                                                </span>
                                                <Button label="추가" color={"primary"} onClick={handleAddQuestion} />
                                            </div>
                                        }
                                        span
                                    >
                                        <MergeQuestionList inputData={input} setInputData={setInput} />
                                    </MergeGridItem>
                                )}
                            </div>
                        </div>
                    )}
                </div>
                <div className={cx("button-container")}>
                    <Button label="등록" onClick={handleRegistration} />
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                </div>
            </div>
        </div>,
        document.body,
    );
};

export {MergeBoardRegisterModal};
