import {useMemo, useState} from "react";
import dayjs from "dayjs";

import {httpRequest} from "~/fetch/common/http-request";

import {MergeBoardRefinedData, MergeBoardRequest, MergeBoardResponse} from "./board.interface";

interface MergeBoardListProps extends MergeBoardRequest {
    selected: string[] | null;
}

const useMergeBoardList = (config: MergeBoardListProps) => {
    const [response, setResponse] = useState<MergeBoardRefinedData | null>(null);
    const [error, setError] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        const {
            isComplete = null,
            mergeType = null,
            month = null,
            year = null,
            startDate = null,
            endDate = null,
            title = null,
            pageNo,
            recordPerPage = 50,
            selected = null,
        } = {
            ...config,
            isComplete: config.isComplete === "" ? null : config.isComplete,
            mergeType: config.mergeType === "" ? null : config.mergeType,
        };

        const refinedData = () => {
            if (config.selected) {
                switch (config.selected[0]) {
                    case "ALL":
                        return null;
                    // break;
                    case "SECTOR":
                        if (config.selected[2] !== "0") {
                            return {searchBuildingId: config.selected[2]};
                        } else {
                            return {
                                searchSectorId: config.selected[1],
                            };
                        }
                    // break;
                    case "TRAINER":
                        if (config.selected[2] !== "0") {
                            return {searchBuildingId: config.selected[2]};
                        } else {
                            return {
                                searchTrainerSectorId: config.selected[1],
                            };
                        }
                    // break;
                    default:
                        return null;
                    // break;
                }
            }
        };

        return httpRequest<MergeBoardRequest, MergeBoardResponse>({
            method: "GET",
            url: "/lotte/merge/list",
            params: {
                isComplete,
                mergeType,
                year,
                month,
                startDate,
                endDate,
                title,
                pageNo,
                recordPerPage,
                ...refinedData(),
            },
            timeout: 1000 * 60,
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const response = await request.submit();

            const data = response.data.responseData.map((data) => ({
                noticeMergeId: data.noticeMergeId,
                mergePeriod:
                    dayjs(new Date(data.startDate)).format("YYYY-MM-DD") +
                    " ~ " +
                    dayjs(new Date(data.endDate)).format("YYYY-MM-DD"),
                year: data.year,
                month: data.month,
                important: data.important,
                targetName: data.targetName,
                title: data.title,
                manageName: data.managerNm,
                targetCnt: data.targetCnt,
                completeCnt: data.completeCnt,
                incompleteCnt: data.targetCnt - data.completeCnt,
                createDate: dayjs(new Date(data.createDate)).format("YYYY-MM-DD"),
            }));
            setResponse({
                resultList: data,
                allRecordCnt: response.data.requestData.allRecordCnt,
            });
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return [submit, response, isLoading, error];
};

export {useMergeBoardList};
