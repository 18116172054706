import {ChangeEvent, useCallback, useEffect, useState} from "react";
import {faCircleChevronLeft, faCircleChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {App, Button as AntdButton, Input} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button/button";
import {CnInput} from "~/components/cn-input";
import {CnSelect} from "~/components/cn-select/cn-select";
import {CenterModal} from "~/components/modal/center-modal";
import {useWorkPlanModal, useWorkPlanUpsert} from "~/data/work-plan/register/use-work-plan-modal";
import {useCommonWorkPlan} from "~/data/work-plan/use-common-work-plan";

import {WorkPlanRegisterModalProps, WorkPlanTableRowProps} from "./work-plan-register.modal.interface";

import styles from "./work-plan-register.modal.module.scss";

import "dayjs/locale/ko";

dayjs.locale("ko");

const cx = classNames.bind(styles);

const WorkPlanTableRow = ({data, dateRange, refresh, mainRefresh}: WorkPlanTableRowProps) => {
    const today = dayjs().format("YYYYMMDD");
    const [input, setInput] = useState<{
        mon: string;
        tue: string;
        wed: string;
        thu: string;
        fri: string;
        sat: string;
        sun: string;
    }>({
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
        sun: "",
    });
    const {upsertSubmit, isUpsertLoading} = useWorkPlanUpsert({
        hrId: data.hrId,
        works: transformWeekDataToWorks(input, dateRange.startDate),
    });

    function transformWeekDataToWorks(
        weekDetails: typeof input,
        startDate: dayjs.Dayjs,
    ): {
        workDate: string;
        detail: string;
    }[] {
        const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

        return daysOfWeek.map((day, index) => {
            const date = startDate.add(index, "day"); // startDate로부터 index 일 후의 날짜 계산
            const detail = weekDetails[day as keyof typeof input]; // 해당 요일의 상세 정보 가져오기

            return {
                workDate: date.format("YYYY-MM-DD"), // 날짜를 원하는 포맷으로 변환
                detail: detail || "", // detail 값이 없으면 기본값 설정
            };
        });
        // .filter((record) => record.detail !== ""); // detail 값이 있는 것만 필터링
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInput((prev) => ({...prev, [e.target.name]: e.target.value}));
    };
    const handleConfirm = async () => {
        await upsertSubmit();
        setTimeout(refresh, 500);
        setTimeout(mainRefresh, 500);
    };
    const getMatchingData = (works: {workDate: string; detail: string}[], index: number) => {
        const workDate = dateRange.startDate.add(index, "day").format("YYYY-MM-DD");
        const matchingWork = works.find((work) => work.workDate === workDate);

        return matchingWork ? matchingWork.detail : "";
    };

    useEffect(() => {
        setInput({
            mon: getMatchingData(data.works, 0),
            tue: getMatchingData(data.works, 1),
            wed: getMatchingData(data.works, 2),
            thu: getMatchingData(data.works, 3),
            fri: getMatchingData(data.works, 4),
            sat: getMatchingData(data.works, 5),
            sun: getMatchingData(data.works, 6),
        });
    }, [data]);

    return (
        <div className={cx("table-row", "table-body")}>
            <div>{data.teamName || "-"}</div>
            <div>{data.name}</div>
            <div>
                <Input
                    placeholder={`월 ${dateRange.startDate.format("YYYYMMDD") === today ? "(오늘)" : ""}`}
                    name={"mon"}
                    value={input.mon}
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    placeholder={`화 ${dateRange.startDate.add(1, "day").format("YYYYMMDD") === today ? "(오늘)" : ""}`}
                    name={"tue"}
                    value={input.tue}
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    placeholder={`수 ${dateRange.startDate.add(2, "day").format("YYYYMMDD") === today ? "(오늘)" : ""}`}
                    name={"wed"}
                    value={input.wed}
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    placeholder={`목 ${dateRange.startDate.add(3, "day").format("YYYYMMDD") === today ? "(오늘)" : ""}`}
                    name={"thu"}
                    value={input.thu}
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    placeholder={`금 ${dateRange.startDate.add(4, "day").format("YYYYMMDD") === today ? "(오늘)" : ""}`}
                    name={"fri"}
                    value={input.fri}
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    placeholder={`토 ${dateRange.startDate.add(5, "day").format("YYYYMMDD") === today ? "(오늘)" : ""}`}
                    name={"sat"}
                    value={input.sat}
                    onChange={handleChange}
                />
            </div>
            <div>
                <Input
                    placeholder={`일 ${dateRange.startDate.add(6, "day").format("YYYYMMDD") === today ? "(오늘)" : ""}`}
                    name={"sun"}
                    value={input.sun}
                    onChange={handleChange}
                />
            </div>
            <div>
                <AntdButton onClick={handleConfirm} loading={isUpsertLoading} className={cx("button")}>
                    저장
                </AntdButton>
            </div>
        </div>
    );
};

const WorkPlanRegisterModal = ({close, isShow, refresh}: WorkPlanRegisterModalProps) => {
    const [workTypeId, setWorkTypeId] = useState<number>(0);
    const [name, setName] = useState<string>("");
    const [dateRange, setDateRange] = useState<{startDate: Dayjs; endDate: Dayjs}>({
        startDate: dayjs().startOf("isoWeek"),
        endDate: dayjs().endOf("isoWeek"),
    });

    const {submitList, workList} = useCommonWorkPlan();
    const {employeeWorkList, employeeWorkListSubmit} = useWorkPlanModal({
        name: name || null,
        workTypeId: workTypeId === 0 ? null : workTypeId,
        startDate: dateRange.startDate.format("YYYY-MM-DD"),
        endDate: dateRange.endDate.format("YYYY-MM-DD"),
    });

    // 오늘로 이동
    const handleToday = () => {
        setDateRange({
            startDate: dayjs().startOf("isoWeek"),
            endDate: dayjs().endOf("isoWeek"),
        });
    };
    // 저번 주로 이동
    const handlePreviousWeek = () => {
        setDateRange({
            startDate: dateRange.startDate.subtract(1, "week").startOf("isoWeek"),
            endDate: dateRange.endDate.subtract(1, "week").endOf("isoWeek"),
        });
    };
    // 다음 주로 이동
    const handleNextWeek = () => {
        setDateRange({
            startDate: dateRange.startDate.add(1, "week").startOf("isoWeek"),
            endDate: dateRange.endDate.add(1, "week").endOf("isoWeek"),
        });
    };
    const handleReset = useCallback(() => {
        setName("");
        setWorkTypeId(0);
    }, []);

    const handleInquiry = async () => {
        await employeeWorkListSubmit();
    };

    useEffect(() => {
        submitList();
    }, []);

    useEffect(() => {
        employeeWorkListSubmit();
    }, [dateRange]);

    return (
        <CenterModal
            isShow={isShow}
            close={close}
            style={{width: "90%", maxWidth: "1600px", top: 30}}
            closeIcon={false}
            title={
                <div className={cx("title-container")}>
                    일일 업무계획 등록
                    <Button label={"닫기"} onClick={() => close()} />
                </div>
            }
            width={1600}
            extraButtons={[]}
            maskClosable={false}
        >
            <App>
                <div className={cx("date-container")}>
                    <div className={cx("date-picker")}>
                        <FontAwesomeIcon
                            icon={faCircleChevronLeft}
                            className={cx("icons")}
                            onClick={handlePreviousWeek}
                        />
                        {dateRange.startDate.format("YYYY-MM-DD")} <span className={cx("tilde")}>~</span>{" "}
                        {dateRange.endDate.format("YYYY-MM-DD")}
                        <FontAwesomeIcon icon={faCircleChevronRight} className={cx("icons")} onClick={handleNextWeek} />
                        <Button label={"오늘"} onClick={handleToday} color="primary" />
                    </div>
                </div>
                <div className={cx("options-container")}>
                    <CnSelect
                        label="팀 선택"
                        options={[
                            {
                                label: "전체",
                                value: 0,
                            },
                            ...workList.map((data) => ({
                                label: data.name,
                                value: data.workTypeId,
                            })),
                        ]}
                        placeholder={"팀을 선택하세요"}
                        value={workTypeId}
                        onChange={(e) => setWorkTypeId(e)}
                    />
                    <CnInput
                        label={"이름 검색"}
                        placeholder={"이름 검색"}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        allowClear
                    />
                    <div className={cx("button-container")}>
                        <Button label={"조회"} color={"primary"} onClick={handleInquiry} />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
                <p className={cx("table-label")}>일일 업무계획</p>
                <div className={cx("table-wrapper")}>
                    <div className={cx("table-row", "table-header")}>
                        <div>소속</div>
                        <div>직원명</div>
                        <div>월 ({dateRange.startDate.format("MM/DD")})</div>
                        <div>화 ({dateRange.startDate.add(1, "day").format("MM/DD")})</div>
                        <div>수 ({dateRange.startDate.add(2, "day").format("MM/DD")})</div>
                        <div>목 ({dateRange.startDate.add(3, "day").format("MM/DD")})</div>
                        <div>금 ({dateRange.startDate.add(4, "day").format("MM/DD")})</div>
                        <div className={cx("sat")}>토 ({dateRange.startDate.add(5, "day").format("MM/DD")})</div>
                        <div className={cx("sun")}>일 ({dateRange.startDate.add(6, "day").format("MM/DD")})</div>
                        <div>저장하기</div>
                    </div>
                    {employeeWorkList?.map((data, index) => (
                        <WorkPlanTableRow
                            data={data}
                            key={index}
                            dateRange={dateRange}
                            refresh={employeeWorkListSubmit}
                            mainRefresh={refresh}
                        />
                    ))}
                </div>
            </App>
        </CenterModal>
    );
};

export {WorkPlanRegisterModal};
