import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {CnSelect} from "~/components/cn-select";
import {httpRequest} from "~/fetch/common/http-request";

import {MachineList} from "./machine.interface";

const useMachineProcess = () => {
    const [process, setProcess] = useState<string | null>(null);
    const [category, setCategory] = useState<string>("ALL");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectMachineList, setSelectMachineList] = useState<Array<{value: string | null; label: string}>>([]);
    const [selectCategoryList, setSelectCategoryList] = useState<Array<{value: string; label: string}>>([]);
    const [outSelectCategoryList, setOutSelectCategoryList] = useState<Array<{value: string; label: string}>>([]);

    const {t} = useTranslation();

    useEffect(() => {
        setIsLoading(true);
        const requestMachine = httpRequest<null, MachineList>({
            url: "/common/detailCodes?groupCd=LC007",
        });
        const requestCategory = httpRequest<null, MachineList>({
            url: "/machine/category",
        });

        requestMachine
            .submit()
            .then((res) => {
                setSelectMachineList([
                    {
                        value: null,
                        label: "전체",
                    },
                    ...res.data.map((machine) => {
                        return {
                            value: machine.code,
                            label: machine.description,
                        };
                    }),
                ]);
            })
            .finally(() => {
                setIsLoading(false);
            });

        requestCategory
            .submit()
            .then((res) => {
                setSelectCategoryList([
                    {
                        value: "ALL",
                        label: "전체",
                    },
                    ...res.data.map((machine) => {
                        return {
                            value: machine.machineCategoryCode,
                            label: machine.machineCategoryName,
                        };
                    }),
                ]);
                setOutSelectCategoryList([
                    ...res.data.map((machine) => {
                        return {
                            value: machine.machineCategoryCode,
                            label: machine.machineCategoryName,
                        };
                    }),
                ]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    useEffect(() => {
        if (process === null) {
            setIsLoading(true);

            const requestCategory = httpRequest<null, MachineList>({
                url: "/machine/category",
            });

            requestCategory
                .submit()
                .then((res) => {
                    setCategory("ALL");
                    setSelectCategoryList([
                        {
                            value: "ALL",
                            label: "전체",
                        },
                        ...res.data.map((machine) => {
                            return {
                                value: machine.machineCategoryCode,
                                label: machine.machineCategoryName,
                            };
                        }),
                    ]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(true);
            const requestCategory = httpRequest<null, MachineList>({
                url: `/machine/category?searchProcessCode=${process}`,
            });

            requestCategory
                .submit()
                .then((res) => {
                    setCategory("ALL");
                    setSelectCategoryList([
                        {
                            value: "ALL",
                            label: "전체",
                        },
                        ...res.data.map((machine) => {
                            return {
                                value: machine.machineCategoryCode,
                                label: machine.machineCategoryName,
                            };
                        }),
                    ]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [process]);

    const handleResetProcess = () => {
        setProcess(null);
        setCategory("ALL");
    };

    const ProcessSelect = () => {
        return (
            <>
                <CnSelect
                    label={t("equipment.process")}
                    // showSearch
                    placeholder={t("equipment.process")}
                    onChange={(value) => setProcess(value)}
                    value={process}
                    loading={isLoading}
                    options={selectMachineList}
                    style={{width: 120}}
                />
                <CnSelect
                    label={t("equipment.category")}
                    // showSearch
                    placeholder={t("equipment.category")}
                    onChange={(value) => setCategory(value)}
                    value={category}
                    loading={isLoading}
                    options={selectCategoryList}
                    style={{width: 150}}
                />
            </>
        );
    };

    return {
        process,
        setProcess,
        category,
        setCategory,
        reset: handleResetProcess,
        outSelectCategoryList,
        ProcessSelect,
    };
};

export {useMachineProcess};
