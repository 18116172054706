import React from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames/bind";

import {useUser} from "~/data/user";
import {MobileDashboardPage} from "~/pages/dashboard/mobile-dashboard.page";
import {PcDashboardPage} from "~/pages/dashboard/pc-dashboard.page";
import {usePlatform} from "~/utills/use-platform";

import styles from "./dashboard.module.scss";

const cx = classNames.bind(styles);

const DashboardPage = () => {
    const {platform} = usePlatform();

    const {t} = useTranslation();
    const {user} = useUser();

    if (platform === "mobile") return user ? <MobileDashboardPage /> : null;
    else return <PcDashboardPage />;
};

export {DashboardPage};
