import {useEffect, useState} from "react";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button/button";
import {CnRangePicker} from "~/components/cn-range-picker/cn-range-picker";
import {CnSelect} from "~/components/cn-select/cn-select";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {PageTitle} from "~/components/page-title/page-title";
import {useInspectionRateList, useInspectionRateOptionList} from "~/data/inspection-rate/use-inspection-rate";
import {LotteFmsPerformanceRatioType} from "~/data/inspection-rate/use-inspection-rate.interface";

import {InspectionRateOptionsType} from "./inspection-rate-status.interface";
import {InspectionRateDetailModal} from "./modal/inspection-rate-datail.modal";

import styles from "./inspection-rate-status.page.module.scss";

const cx = classNames.bind(styles);

const InspectionRateStatusPage = () => {
    const perPage = 30;
    const [page, setPage] = useState<number>(1);
    const [isModal, setIsModal] = useState<LotteFmsPerformanceRatioType | null>(null);
    const [options, setOptions] = useState<InspectionRateOptionsType>({
        date: [dayjs(), dayjs()],
        trainerSectorId: 0,
        storeForm: "",
    });
    const {trainerSectorList, storeFormList} = useInspectionRateOptionList();
    const {submitList, list, excel, isLoading, isExcelLoading} = useInspectionRateList({
        startDate: options.date[0].format("YYYY-MM-DD"),
        endDate: options.date[1].format("YYYY-MM-DD"),
        trainerSectorId: options.trainerSectorId,
        serviceOpeningTypeCode: options.storeForm,
    });

    useEffect(() => {
        submitList();
    }, [options]);

    const handleReset = () => {
        setOptions({
            date: [dayjs(), dayjs()],
            trainerSectorId: 0,
            storeForm: "",
        });
    };

    return (
        <div>
            {isModal && <InspectionRateDetailModal date={options.date} data={isModal} close={() => setIsModal(null)} />}
            <PageTitle>이행률현황</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnRangePicker
                        value={options.date}
                        label="기간"
                        onChange={(e) => setOptions((prev) => ({...prev, date: e}))}
                        allowClear={false}
                    />
                    <CnSelect
                        label="트레이너 권역"
                        value={options.trainerSectorId}
                        placeholder={"권역을 선택하세요"}
                        onChange={(e) => setOptions((prev) => ({...prev, trainerSectorId: e}))}
                        options={[
                            {
                                label: "전체",
                                value: 0,
                            },
                            ...trainerSectorList.map((data) => ({
                                label: data.trainerSectorName,
                                value: data.trainerSectorId,
                            })),
                        ]}
                    />
                    <CnSelect
                        label="출점형태"
                        value={options.storeForm}
                        placeholder={"출점형태를 선택하세요"}
                        options={[
                            {
                                label: "전체",
                                value: "",
                            },
                            ...storeFormList.map((data) => ({
                                label: data.description,
                                value: data.code,
                            })),
                        ]}
                        onChange={(e) => setOptions((prev) => ({...prev, storeForm: e}))}
                    />
                    <div className={cx("button-container")}>
                        <Button
                            label={isExcelLoading ? "다운로드 중.." : "엑셀다운"}
                            color={"primary"}
                            onClick={() => excel()}
                            disabled={isExcelLoading}
                        />
                        <Button label="조회" color={"primary"} onClick={() => submitList()} />
                        <Button label="검색조건 초기화" onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={"전체"}
                    totalCount={0}
                    onPageChange={(e) => console.log(e)}
                    noPagination
                    page={1}
                    perPage={50}
                    pagination={false}
                    loading={isLoading}
                    dataSource={list?.lotteFmsPerformanceRatioTotal ?? []}
                    rowKey={"trainerSectorId"}
                    onRow={(record) => ({
                        onClick: () => {
                            setOptions((prev) => ({...prev, trainerSectorId: record.trainerSectorId}));
                        },
                    })}
                    columns={[
                        {
                            title: "No",
                            render: (_, __, index) => index + 1,
                        },
                        {
                            title: "트레이너 권역",
                            key: "trainerSectorName",
                            dataIndex: "trainerSectorName",
                        },
                        {
                            title: "이행률(%)",
                            key: "statusRatio",
                            dataIndex: "statusRatio",
                        },
                        {
                            title: "점검 개수",
                            key: "statusTotal",
                            dataIndex: "statusTotal",
                        },
                        {
                            title: "완료점검 개수",
                            key: "statusSuccess",
                            dataIndex: "statusSuccess",
                        },
                        {
                            title: "미완료점검 개수",
                            key: "statusIncomplete",
                            dataIndex: "statusIncomplete",
                        },
                        {
                            title: "불량",
                            key: "statusPoor",
                            dataIndex: "statusPoor",
                        },
                    ]}
                />
                <CustomLabelTable
                    label={"지점별"}
                    totalCount={0}
                    onPageChange={(e) => console.log(e)}
                    noPagination
                    page={0}
                    perPage={0}
                    loading={isLoading}
                    onRow={(record) => ({
                        onClick: () => {
                            setIsModal(record);
                        },
                    })}
                    pagination={{
                        pageSize: perPage,
                        onChange(page) {
                            setPage(page);
                        },
                    }}
                    dataSource={list?.lotteFmsPerformanceRatio ?? []}
                    rowKey={"buildingId"}
                    columns={[
                        {
                            title: "No",
                            render: (_, __, index) => (page - 1) * perPage + (index + 1),
                        },
                        {
                            title: "트레이너 권역",
                            key: "trainerSectorName",
                            dataIndex: "trainerSectorName",
                            sorter: (a, b) => a.trainerSectorName.localeCompare(b.trainerSectorName, "ko"),
                        },
                        {
                            title: "지점",
                            key: "buildingName",
                            dataIndex: "buildingName",
                            sorter: (a, b) => a.buildingName.localeCompare(b.buildingName, "ko"),
                        },
                        {
                            title: "이행률(%)",
                            key: "statusRatio",
                            dataIndex: "statusRatio",
                            sorter: (a, b) => a.statusRatio - b.statusRatio,
                        },
                        {
                            title: "점검 개수",
                            key: "statusTotal",
                            dataIndex: "statusTotal",
                            sorter: (a, b) => a.statusTotal - b.statusTotal,
                        },
                        {
                            title: "완료점검 개수",
                            key: "statusSuccess",
                            dataIndex: "statusSuccess",
                            sorter: (a, b) => a.statusSuccess - b.statusSuccess,
                        },
                        {
                            title: "미완료점검 개수",
                            key: "statusIncomplete",
                            dataIndex: "statusIncomplete",
                            sorter: (a, b) => a.statusIncomplete - b.statusIncomplete,
                        },
                        {
                            title: "불량",
                            key: "statusPoor",
                            dataIndex: "statusPoor",
                            sorter: (a, b) => a.statusPoor - b.statusPoor,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {InspectionRateStatusPage};
