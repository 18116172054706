import {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";
import {DetailCodesType} from "~/hooks/use-detail-codes/use-detail-codes.interface";

const useDetailCodes = (groupCd: string): [DetailCodesType[], boolean, any] => {
    const [response, setResponse] = useState<DetailCodesType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<null, DetailCodesType[]>({
            method: "GET",
            url: "/common/detailCodes?groupCd=" + groupCd,
        });
    }, [groupCd]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        submit();
    }, [groupCd]);

    return [response, isLoading, error];
};

export {useDetailCodes};
