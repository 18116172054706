import {useEffect, useState} from "react";
import {faBuildingUser, faGrip, faList, faLocationDot, faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Skeleton, Table} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useBuildingList} from "~/data/building/use-building-list";
import {BuildingList} from "~/data/building/use-building-list.interface";
import {BuildingSettingModal} from "~/pages/building/modal/building-setting.modal";

import styles from "./building-setting.page.module.scss";

const cx = classNames.bind(styles);

const BuildingSettingPage = () => {
    const [listForm, setListForm] = useState<boolean>(true); // t: block, f: list
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<BuildingList | null>(null);

    const {submit, buildingList, loading} = useBuildingList();

    useEffect(() => {
        submit();
    }, []);

    useEffect(() => {
        if (!isShowModal) {
            setModalInfo(null);
        }
    }, [isShowModal]);
    return (
        <div>
            {isShowModal && (
                <BuildingSettingModal
                    data={modalInfo}
                    isShow={isShowModal}
                    close={() => setIsShowModal(false)}
                    refresh={() => submit()}
                />
            )}
            <PageTitle>건물 설정</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <div className={cx("list-form-container")}>
                        <div className={cx("list-form-button", {active: listForm})} onClick={() => setListForm(true)}>
                            <FontAwesomeIcon icon={faGrip} className={cx("icon")} />
                        </div>
                        <div className={cx("list-form-button", {active: !listForm})} onClick={() => setListForm(false)}>
                            <FontAwesomeIcon icon={faList} className={cx("icon")} />
                        </div>
                    </div>
                    <Button label={"건물추가"} onClick={() => setIsShowModal(true)} />
                </div>
            </FilterArea>
            {listForm ? (
                <div className={cx("content-block")}>
                    {loading ? (
                        <>
                            <Skeleton.Button active={true} style={{width: "100%", height: "130px"}} />
                            <Skeleton.Button active={true} style={{width: "100%", height: "130px"}} />
                            <Skeleton.Button active={true} style={{width: "100%", height: "130px"}} />
                            <Skeleton.Button active={true} style={{width: "100%", height: "130px"}} />
                        </>
                    ) : (
                        buildingList?.buildingList.map((data, index) => (
                            <div
                                className={cx("building-card")}
                                key={index}
                                onClick={() => {
                                    setModalInfo(data);
                                    setIsShowModal(true);
                                }}
                            >
                                <FontAwesomeIcon icon={faBuildingUser} className={cx("icon")} />
                                <div className={cx("building-info-container")}>
                                    <p className={cx("building-name")}>{data.buildingName}</p>
                                    <p className={cx("building-info")}>
                                        <FontAwesomeIcon icon={faUser} className={cx("icons")} />
                                        {data.managerName}({data.managerUserName})
                                    </p>
                                    <p className={cx("building-info")}>
                                        <FontAwesomeIcon icon={faLocationDot} className={cx("icons")} />
                                        {data.address}
                                    </p>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            ) : (
                <div className={cx("content-table")}>
                    <Table
                        className={cx("table")}
                        pagination={false}
                        rowKey={"buildingId"}
                        dataSource={buildingList?.buildingList}
                        onRow={(record) => ({
                            onClick: () => {
                                setModalInfo(record);
                                setIsShowModal(true);
                            },
                        })}
                        columns={[
                            {
                                title: "No",
                                render: (_, __, index) => index + 1,
                                align: "center",
                            },
                            {
                                title: "지점명",
                                dataIndex: "buildingName",
                                key: "buildingName",
                                align: "center",
                            },
                            {
                                title: "주소",
                                dataIndex: "address",
                                key: "address",
                            },
                            {
                                title: "연면적 (㎡)",
                                dataIndex: "grossFloorArea",
                                key: "grossFloorArea",
                                render: (value) => (value ? value.toLocaleString() : "-"),
                            },
                            {
                                title: "담당자",
                                render: (record) => `${record.managerName} (${record.managerUserName})`,
                            },
                            {
                                title: "완공일",
                                dataIndex: "establishedDate",
                                key: "establishedDate",
                                render: (value) => (value ? dayjs(value).format("YYYY-MM-DD") : "-"),
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    );
};

export {BuildingSettingPage};
