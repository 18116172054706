import {useEffect, useRef, useState} from "react";
import {Modal} from "antd";
import classNames from "classnames/bind";
import html2canvas from "html2canvas";
import {v4 as uuidV4} from "uuid";

import Button from "~/components/button/button";
import {useWorkPlanList} from "~/data/work-plan/use-work-plan";

import {WorkPlanDetailModalProps} from "./work-plan-detail.modal.interface";

import styles from "./work-plan-detail.modal.module.scss";

const cx = classNames.bind(styles);

const WorkPlanDetailModal = ({close, isShow, date}: WorkPlanDetailModalProps) => {
    const captureRef = useRef(null);
    const [elements, setElements] = useState<{infos: JSX.Element[]; noWorks: JSX.Element[]}>({
        infos: [],
        noWorks: [],
    });
    const {submitDay, day} = useWorkPlanList(date.format("YYYY-MM-DD"));

    useEffect(() => {
        submitDay(date.format("YYYY-MM-DD"));
    }, [date]);

    useEffect(() => {
        if (day) {
            const infoTemp = day.infos.map((data, index) => (
                <div className={cx("list")} key={uuidV4()}>
                    <div className={cx("item", {title: true, empty: !data.dailyWorkList.length})} key={uuidV4()}>
                        {data.name}
                    </div>
                    {data.dailyWorkList.length ? (
                        data.dailyWorkList.map((_data, _index) => (
                            <div className={cx("item")} key={uuidV4()}>
                                {_data.hrName}
                                {_data.detail && `(${_data.detail})`}
                            </div>
                        ))
                    ) : (
                        <div className={cx("item")} key={uuidV4()}>
                            일정없음
                        </div>
                    )}
                </div>
            ));

            // const noWorksTemp = day.noWorkHr.map((data, index) => (
            //     <div className={cx("no-work-item", "item")} key={uuidV4()}>
            //         {data.name}
            //     </div>
            // ));

            setElements({
                infos: infoTemp,
                noWorks: [],
            });
        }
    }, [day]);

    const handleCapture = () => {
        if (captureRef.current) {
            html2canvas(captureRef.current).then((canvas) => {
                const imgData = canvas.toDataURL("image/png");

                // 이미지 다운로드
                const link = document.createElement("a");
                link.href = imgData;
                link.download = "modal-screenshot.png";
                link.click();
            });
        }
    };

    return (
        <Modal
            open={isShow}
            onCancel={close}
            closeIcon={false}
            // title={`일일 업무계획 [ ${date.format("YYYY-MM-DD (dd)")} ]`}
            afterClose={() => {
                // 모달이 닫힌 후 ref를 다시 사용할 수 있도록 처리
                captureRef.current = null;
            }}
            footer={[
                <Button label="캡쳐하기" key={"capture"} onClick={handleCapture} />,
                <Button label="닫기" key={"close"} onClick={close} />,
            ]}
            width={1400}
            style={{width: "90%", maxWidth: "1600px", top: 50}}
            zIndex={11000}
        >
            <div className={cx("modal-container")} ref={captureRef}>
                <h1>{`일일 업무계획 [ ${date.format("YYYY-MM-DD (dd)")} ]`}</h1>
                <p className={cx("info")}>총 : {day?.hrTotalCount}명</p>
                <div className={cx("detail")}>{elements.infos}</div>
                {/*<div className={cx("detail")}>*/}
                {/*    <div className={cx("list", "no-work-container")}>*/}
                {/*        <div className={cx("item", "title", "no-work")}>미입력</div>*/}
                {/*        {elements.noWorks}*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </Modal>
    );
};

export {WorkPlanDetailModal};
