import {useCallback, useEffect, useState} from "react";
import {Button as AntdButton, DatePicker, InputNumber} from "antd";
import classNames from "classnames/bind";

import Button from "~/components/button";
import {CnSelect} from "~/components/cn-select";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area";
import {FilterItem} from "~/components/filter-item";
import {PageTitle} from "~/components/page-title";
import {useStoreCommonData, useStoreStatus} from "~/data/store/use-store-status";
import {StoreStatusType} from "~/data/store/use-store-status.interface";
import {useShop} from "~/hooks/use-shop/use-shop";
import {StoreStatusModal} from "~/pages/store/modal/store-status.modal";
import {StoreStatusInputType} from "~/pages/store/store-status.page.interface";
import {formatCost} from "~/utills/formatCost";

import styles from "./store-status.page.module.scss";

const cx = classNames.bind(styles);

const StoreStatusPage = () => {
    const perPage = 30;
    const [page, setPage] = useState<number>(1);
    // const [isSector, setIsSector] = useState<boolean>(true); // t: sector, f: trainer
    const [modal, setModal] = useState<StoreStatusType | null>(null);
    const [inputs, setInputs] = useState<StoreStatusInputType>({
        buildingId: 0,
        serviceOpeningTypeCode: "",
        facilityPartnerCd: "",
        openingDate: null,
        annual: {start: null, end: null},
        landArea: {start: null, end: null},
        operationArea: {start: null, end: null},
    });

    const {shopList} = useShop();
    const {partner, serviceOpeningTypeCode} = useStoreCommonData();
    const {storeStatusList, storeStatusListSubmit, excel, isLoading} = useStoreStatus({
        buildingId: inputs.buildingId === 0 ? null : inputs.buildingId,
        serviceOpeningTypeCode: inputs.serviceOpeningTypeCode || null,
        facilityPartnerCd: inputs.facilityPartnerCd || null,
        startDate: inputs.openingDate && inputs.openingDate[0] ? inputs.openingDate[0].format("YYYY-MM") : null,
        endDate: inputs.openingDate && inputs.openingDate[1] ? inputs.openingDate[1].format("YYYY-MM") : null,
        startAnnual: inputs.annual.start,
        endAnnual: inputs.annual.end,
        startLandArea: inputs.landArea.start,
        endLandArea: inputs.landArea.end,
        startOperationArea: inputs.operationArea.start,
        endOperationArea: inputs.operationArea.end,
        pageNo: page,
        recordPerPage: perPage,
    });

    const handleReset = useCallback(() => {
        setInputs({
            buildingId: 0,
            serviceOpeningTypeCode: "",
            facilityPartnerCd: "",
            openingDate: null,
            annual: {start: null, end: null},
            landArea: {start: null, end: null},
            operationArea: {start: null, end: null},
        });
    }, []);

    const handleInquiry = () => {
        storeStatusListSubmit();
    };

    useEffect(() => {
        storeStatusListSubmit();
    }, [page]);

    return (
        <div>
            {modal && <StoreStatusModal close={() => setModal(null)} data={modal} refresh={storeStatusListSubmit} />}
            <PageTitle>점포현황</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnSelect
                        label={"지점"}
                        placeholder={"지점명"}
                        options={shopList}
                        value={inputs.buildingId}
                        onChange={(e) => setInputs((prev) => ({...prev, buildingId: e}))}
                    />
                    <CnSelect
                        label={"출점형태"}
                        placeholder={"출점형태"}
                        value={inputs.serviceOpeningTypeCode}
                        onChange={(e) => setInputs((prev) => ({...prev, serviceOpeningTypeCode: e}))}
                        options={[
                            {label: "전체", value: ""},
                            ...serviceOpeningTypeCode.map((data) => ({
                                label: data.description,
                                value: data.code,
                            })),
                        ]}
                    />
                    <CnSelect
                        label={"파트너사"}
                        placeholder={"파트너사"}
                        value={inputs.facilityPartnerCd}
                        onChange={(e) => setInputs((prev) => ({...prev, facilityPartnerCd: e}))}
                        options={[
                            {label: "전체", value: ""},
                            ...partner.responseData.map((data) => ({
                                label: data.facilityPartnerName,
                                value: data.facilityPartnerCd,
                            })),
                        ]}
                    />
                    <FilterItem label={"오픈일"}>
                        <DatePicker.RangePicker
                            picker={"month"}
                            value={inputs.openingDate}
                            onChange={(e) => setInputs((prev) => ({...prev, openingDate: e}))}
                        />
                    </FilterItem>{" "}
                    <FilterItem label={"오픈연차(년)"}>
                        <div className={cx("range-input-container")}>
                            <InputNumber
                                className={cx("range-input")}
                                placeholder={"최소값"}
                                controls={false}
                                value={inputs.annual.start}
                                formatter={(value) => formatCost(value)}
                                onChange={(e) =>
                                    setInputs((prev) => ({
                                        ...prev,
                                        annual: {start: e, end: prev.annual.end},
                                    }))
                                }
                            />{" "}
                            ~{" "}
                            <InputNumber
                                className={cx("range-input")}
                                placeholder={"최대값"}
                                controls={false}
                                formatter={(value) => formatCost(value)}
                                value={inputs.annual.end}
                                onChange={(e) =>
                                    setInputs((prev) => ({
                                        ...prev,
                                        annual: {start: prev.annual.start, end: e},
                                    }))
                                }
                            />
                        </div>
                    </FilterItem>
                    <FilterItem label={"연면적(평)"}>
                        <div className={cx("range-input-container")}>
                            <InputNumber
                                className={cx("range-input")}
                                placeholder={"최소값"}
                                controls={false}
                                formatter={(value) => formatCost(value)}
                                value={inputs.landArea.start}
                                onChange={(e) =>
                                    setInputs((prev) => ({
                                        ...prev,
                                        landArea: {start: e, end: prev.landArea.end},
                                    }))
                                }
                            />{" "}
                            ~{" "}
                            <InputNumber
                                className={cx("range-input")}
                                placeholder={"최대값"}
                                controls={false}
                                formatter={(value) => formatCost(value)}
                                value={inputs.landArea.end}
                                onChange={(e) =>
                                    setInputs((prev) => ({
                                        ...prev,
                                        landArea: {start: prev.landArea.start, end: e},
                                    }))
                                }
                            />
                        </div>
                    </FilterItem>{" "}
                    <FilterItem label={"영업면적(평)"}>
                        <div className={cx("range-input-container")}>
                            <InputNumber
                                className={cx("range-input")}
                                placeholder={"최소값"}
                                controls={false}
                                formatter={(value) => formatCost(value)}
                                value={inputs.operationArea.start}
                                onChange={(e) =>
                                    setInputs((prev) => ({
                                        ...prev,
                                        operationArea: {start: e, end: prev.operationArea.end},
                                    }))
                                }
                            />{" "}
                            ~{" "}
                            <InputNumber
                                className={cx("range-input")}
                                placeholder={"최대값"}
                                controls={false}
                                formatter={(value) => formatCost(value)}
                                value={inputs.operationArea.end}
                                onChange={(e) =>
                                    setInputs((prev) => ({
                                        ...prev,
                                        operationArea: {start: prev.operationArea.start, end: e},
                                    }))
                                }
                            />
                        </div>
                    </FilterItem>
                    <div className={cx("button-container")}>
                        <Button label={"조회"} color={"primary"} onClick={handleInquiry} />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <div className={cx("table-title")}>
                            <span>{storeStatusList?.responseData.length} 건 검색됨</span>
                            <AntdButton className={cx("button")} onClick={excel}>
                                엑셀다운
                            </AntdButton>
                        </div>
                    }
                    totalCount={storeStatusList?.responseData.length ?? 0}
                    pagination={false}
                    noPagination={true}
                    loading={isLoading}
                    bordered
                    perPage={perPage}
                    page={page}
                    onRow={(record) => ({
                        onClick: () => setModal(record),
                    })}
                    onPageChange={(e) => setPage(e)}
                    rowKey={"buildingId"}
                    dataSource={storeStatusList?.responseData}
                    columns={[
                        {
                            title: "No",
                            render: (_, __, index) => index + 1 + (page - 1) * perPage,
                        },
                        {
                            title: "부문 / 트레이너 권역",
                            // title: (
                            //     <>
                            //         {isSector ? "부문" : "트레이너 권역"}
                            //         <FontAwesomeIcon
                            //             icon={faRotate}
                            //             className={cx("rotate-icon")}
                            //             onClick={() => setIsSector(!isSector)}
                            //         />
                            //     </>
                            // ),
                            dataIndex: "sectorName",
                            key: "sectorName",
                            render: (value, record) => {
                                return (
                                    <>
                                        <p>{value}</p>
                                        <p>{record.trainerSectorName}</p>
                                    </>
                                );
                            },
                        },
                        {
                            title: "지점",
                            dataIndex: "buildingName",
                            key: "buildingName",
                        },
                        {
                            title: "오픈일",
                            dataIndex: "startOpenDate",
                            key: "startOpenDate",
                        },
                        {
                            title: "오픈연차",
                            dataIndex: "annual",
                            key: "annual",
                        },
                        {
                            title: "연면적(평)",
                            dataIndex: "landArea",
                            key: "landArea",
                            render: (value) => (value ? value.toLocaleString() : "-"),
                        },
                        {
                            title: "영업면적(평)",
                            dataIndex: "operationArea",
                            key: "operationArea",
                            render: (value) => (value ? value.toLocaleString() : "-"),
                        },
                        {
                            title: "파트너사",
                            dataIndex: "facilityPartnerName",
                            key: "facilityPartnerName",
                        },
                        {
                            title: "출점형태",
                            dataIndex: "serviceOpeningTypeName",
                            key: "serviceOpeningTypeName",
                        },
                        {
                            title: "영업시간",
                            dataIndex: "serviceOpenTime",
                            key: "serviceOpenTime",
                            render: (value, record) => {
                                return value && record.serviceCloseTime ? `${value} ~ ${record.serviceCloseTime}` : "-";
                            },
                        },
                        {
                            title: "의무 휴무 현황",
                            dataIndex: "dutyCloseState",
                            key: "dutyCloseState",
                            render: (value, record) => {
                                return value && record.dutyCloseState2 ? (
                                    <>
                                        <p>{value}</p>
                                        <p>{record.dutyCloseState2}</p>
                                    </>
                                ) : (
                                    "-"
                                );
                            },
                        },
                        {
                            title: "에너지 관리형태",
                            dataIndex: "energyManageTypeName",
                            key: "energyManageTypeName",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {StoreStatusPage};
