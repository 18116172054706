import React, {Fragment, useEffect, useState} from "react";
import {faDownload, faPaperclip, faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, Form, Input, List, Radio} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {usefile} from "~/data/drive/use-files";
import {useUser} from "~/data/user";
import {singleFileUpload} from "~/fetch/single-file-upload";
import {FileDetailInterface} from "~/pages/drive/file-detail.interface";

import styles from "./file-detail.modal.module.scss";

const cx = classNames.bind(styles);

const FileDetailModal = (props: FileDetailInterface) => {
    const {fileBoardSeq, onClose} = props;
    const {data, updateFile, fileDownload, deleteFile, deleteBoardFile} = usefile(fileBoardSeq);
    const [isModify, setIsModify] = useState<boolean>(false);
    const [files, setFiles] = useState<
        Array<{
            fileBoardDataSeq: number;
            fileOriginalName: string;
            filePath: string;
        }>
    >([]);
    const [title, setTitle] = useState<string>("");
    const [contents, setContents] = useState<string>("");
    const [lockYn, setLockYn] = useState<"Y" | "N">("N");

    useEffect(() => {
        if (data) {
            setTitle(data.title);
            setContents(data.contents);
            setLockYn(data.lockYn);
            setFiles([
                ...data.fileBoardDataDTOList.map((file) => {
                    return {
                        fileBoardDataSeq: file.fileBoardDataSeq,
                        fileOriginalName: file.fileOriginalName,
                        filePath: file.filePath,
                    };
                }),
            ]);
        }
    }, [data]);

    const handleDelete = () => {
        Swal.fire({
            html: "게시물을 삭제하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then(async (info) => {
            if (info.isConfirmed) {
                deleteBoardFile(fileBoardSeq)
                    .submit()
                    .then(() => {
                        onClose();
                    })
                    .catch(() => {
                        Swal.fire({
                            text: "오류가 발생하였습니다. 다시 시도해주세요",
                            confirmButtonText: "확인",
                        });
                    });
            }
        });
    };

    const handleFileChange = (param: UploadChangeParam) => {
        console.log(param);
        if (param.file.status === "done") {
            setFiles((prevFiles) => {
                return [
                    ...prevFiles,
                    {
                        fileBoardDataSeq: 0,
                        fileOriginalName: param.file.name,
                        filePath: param.file.response.filePath,
                    },
                ];
            });
        }
        if (param.file.status === "removed") {
            setFiles((prevFiles) => {
                return prevFiles.filter((f) => f.filePath !== param.file.response.filePath);
            });
        }
    };

    const removeFile = (file: {fileBoardDataSeq: number; fileOriginalName: string; filePath: string}) => {
        if (file.fileBoardDataSeq !== 0) deleteFile(file.fileBoardDataSeq).submit();

        setFiles((prevFiles) => {
            return prevFiles.filter((f) => f.filePath !== file.filePath);
        });
    };

    const handleSave = () => {
        updateFile({
            contents: contents,
            title: title,
            fileBoardDataDTOList: files,
            lockYn: lockYn,
            fileBoardSeq: fileBoardSeq,
        })
            .submit()
            .then((res) => {
                setIsModify(false);
            })
            .catch((err) => {
                Swal.fire({
                    title: "오류가 발생했습니다.",
                    text: "문제가 지속될 경우 관리자에 문의하세요.",
                    confirmButtonText: "확인",
                });
            });
    };

    const {user} = useUser();

    return (
        <SideModal
            onClose={onClose}
            extraButton={
                isModify ? (
                    <Button label={"저장하기"} onClick={handleSave} />
                ) : (
                    <Fragment>
                        {user?.division === "MEMBER" && <Button label={"삭제하기"} onClick={handleDelete} />}
                        {user?.division === "MEMBER" && (
                            <Button label={"수정하기"} color={"primary"} onClick={() => setIsModify(true)} />
                        )}
                    </Fragment>
                )
            }
        >
            <PageTitle>파일{isModify ? "수정" : "상세"}</PageTitle>
            <div className={cx("container")}>
                <Form.Item label="제목" name="title" labelCol={{style: {width: 80}}}>
                    {!isModify && <h4>{title}</h4>}
                    {isModify && (
                        <Input
                            value={title}
                            defaultValue={title}
                            onChange={(e) => setTitle(e.target.value)}
                            disabled={!isModify}
                        />
                    )}
                </Form.Item>
                <Form.Item name="isPublic" label="공개여부" labelCol={{style: {width: 80}}}>
                    {!isModify && <p>{lockYn === "Y" ? "비공개" : "공개"}</p>}
                    {isModify && (
                        <Radio.Group
                            key={lockYn}
                            defaultValue={lockYn}
                            onChange={(e) => {
                                console.log(e);
                                setLockYn(e.target.value);
                            }}
                            disabled={!isModify}
                        >
                            <Radio value="N">공개</Radio>
                            <Radio value="Y">비공개</Radio>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item name="file" label="첨부파일" labelCol={{style: {width: 80}}}>
                    {isModify && (
                        <Dragger
                            key={JSON.stringify(files)}
                            customRequest={singleFileUpload}
                            multiple
                            onChange={handleFileChange}
                            defaultFileList={files.map((file) => ({
                                uid: file.filePath,
                                name: file.fileOriginalName,
                                fileName: file.fileOriginalName,
                                filePath: file.filePath,
                                response: {
                                    filePath: file.filePath,
                                },
                            }))}
                            showUploadList={false}
                        >
                            <p className="ant-upload-text">파일을 끌어다 놓거나 클릭하여 파일을 선택하세요.</p>
                            <p className="ant-upload-hint">각 파일의 용량 제한은 500MB입니다.</p>
                            <p className="ant-upload-hint">용량이 초과될 경우 분할 압축을 하여 업로드 해주십시오.</p>
                        </Dragger>
                    )}
                    <List
                        itemLayout="horizontal"
                        dataSource={files}
                        renderItem={(file, index) => {
                            const btns = [];

                            if (user?.division === "MEMBER")
                                btns.push(
                                    <ButtonAntd
                                        type="link"
                                        danger
                                        key={index}
                                        icon={<FontAwesomeIcon icon={faTrashCan} />}
                                        onClick={async (e) => {
                                            e.stopPropagation();
                                            Swal.fire({
                                                html: "파일을 삭제하시겠습니까? <br/> 파일삭제는 즉시 저장됩니다",
                                                showCancelButton: true,
                                                confirmButtonText: "확인",
                                                cancelButtonText: "취소",
                                            }).then(async (info) => {
                                                if (info.isConfirmed) {
                                                    removeFile(file);
                                                }
                                            });
                                        }}
                                    />,
                                );

                            if (file.fileBoardDataSeq !== 0)
                                btns.unshift(
                                    <ButtonAntd
                                        type="link"
                                        key={index}
                                        icon={<FontAwesomeIcon icon={faDownload} />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            fileDownload(file.fileBoardDataSeq);
                                        }}
                                    />,
                                );
                            return (
                                <List.Item key={index} actions={btns}>
                                    <List.Item.Meta
                                        avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                        title={file.fileOriginalName}
                                        style={{textAlign: "left"}}
                                    />
                                </List.Item>
                            );
                        }}
                    />
                </Form.Item>
                <Form.Item label="내용" name="description" labelCol={{style: {width: 80}}}>
                    {!isModify && <p dangerouslySetInnerHTML={{__html: contents?.replaceAll("\n", "<br />")}} />}
                    {isModify && (
                        <Input.TextArea
                            size={"large"}
                            value={contents}
                            defaultValue={contents}
                            onChange={(e) => setContents(e.target.value)}
                            disabled={!isModify}
                            className={cx("textarea")}
                        />
                    )}
                </Form.Item>
            </div>
        </SideModal>
    );
};

export {FileDetailModal};
