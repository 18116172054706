import {useNavigate} from "react-router-dom";
import {LoadingOutlined} from "@ant-design/icons";
import {Spin} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {CenterModal} from "~/components/modal/center-modal";
import {PageTitle} from "~/components/page-title";
import {DashboardAlertModalProps} from "~/pages/dashboard/modal/dashboard-alert.modal.interface";

import styles from "./dashboard-alert.modal.module.scss";

const cx = classNames.bind(styles);

const DashboardAlertModal = ({isShow, close, noticeList, mergeList, mergeLoading}: DashboardAlertModalProps) => {
    const navigate = useNavigate();
    const filterPostsByDate = () => {
        const today = dayjs();
        const sevenDaysAgo = today.subtract(7, "days");

        return noticeList.filter((post) => {
            const createDate = dayjs(post.createDate);
            return createDate.isAfter(sevenDaysAgo) && createDate.isBefore(today.add(1, "day"));
        });
    };

    return (
        <CenterModal isShow={isShow} close={close} extraButtons={[]} closeIcon={false} width={800}>
            <>
                <PageTitle>알림</PageTitle>
                <div className={cx("board-container")}>
                    <div>공지사항</div>
                    <div onClick={() => navigate("/board/notice")}>{filterPostsByDate().length ?? 0} 건</div>
                    <div>취합게시판</div>
                    <div onClick={() => navigate("/merge")}>{mergeList.length ?? 0} 건</div>
                </div>
                <div className={cx("merge-container", "title")}>신규 취합건</div>
                <div className={cx("merge-container")}>
                    <ul className={cx("merge-list")}>
                        {mergeLoading ? (
                            <li className={cx("merge-item", "no-hover")}>
                                <Spin indicator={<LoadingOutlined spin />} size="small" className={cx("loading")} />
                                불러오는 중
                            </li>
                        ) : mergeList.length ? (
                            mergeList.map((post, index) => (
                                <li
                                    className={cx("merge-item")}
                                    key={index}
                                    onClick={() => navigate(`/merge/${post.title}`)}
                                >
                                    {post.title}
                                </li>
                            ))
                        ) : (
                            <li className={cx("merge-item")}>신규 취합건이 없습니다.</li>
                        )}
                    </ul>
                </div>
            </>
        </CenterModal>
    );
};

export {DashboardAlertModal};
