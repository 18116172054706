import React, {useEffect, useState} from "react";
import {Form, Input, Radio, Segmented, Space, Upload} from "antd";
import {UploadChangeParam} from "antd/es/upload";
import classNames from "classnames/bind";

import Button from "~/components/button";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {singleFileUpload} from "~/fetch/single-file-upload";

import {AddNewFileProps} from "./add-new-file.interface";

import styles from "./add-new-file.modal.module.scss";

const cx = classNames.bind(styles);

const {Dragger} = Upload;

const AddNewFileModal = ({onClose, onSave}: AddNewFileProps) => {
    const formItemLayout = {
        labelCol: {span: 4},
    };

    const [files, setFiles] = useState<
        Array<{
            fileOriginalName: string;
            filePath: string;
        }>
    >([]);
    const [title, setTitle] = useState<string>("");
    const [contents, setContents] = useState<string>("");
    const [lockYn, setLockYn] = useState<"Y" | "N">("N");

    const handleFileChange = (param: UploadChangeParam) => {
        if (param.file.status === "done") {
            setFiles((prevFiles) => {
                return [
                    ...prevFiles,
                    {
                        fileOriginalName: param.file.name,
                        filePath: param.file.response.filePath,
                    },
                ];
            });
        }
        if (param.file.status === "removed") {
            setFiles((prevFiles) => {
                return prevFiles.filter((f) => f.filePath !== param.file.response.filePath);
            });
        }
    };

    useEffect(() => {
        console.log(files);
    }, [files]);

    const handleSave = () => {
        onSave({
            contents: contents,
            fileBoardDataDTOList: files,
            lockYn: lockYn,
            title: title,
        });
    };

    return (
        <SideModal onClose={onClose} extraButton={<Button label={"저장하기"} onClick={handleSave} />}>
            <PageTitle>파일추가</PageTitle>
            <div className={cx("container")}>
                <Form {...formItemLayout}>
                    <Form.Item label="제목" name="title">
                        <Input value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Form.Item>
                    <Form.Item name="isPublic" label="공개여부">
                        <Radio.Group defaultValue={"N"} onChange={(e) => setLockYn(e.target.value)}>
                            <Radio value="N">공개</Radio>
                            <Radio value="Y">비공개</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="file" label="첨부파일">
                        <Dragger customRequest={singleFileUpload} multiple onChange={handleFileChange}>
                            <p className="ant-upload-text">파일을 끌어다 놓거나 클릭하여 파일을 선택하세요.</p>
                            <p className="ant-upload-hint">각 파일의 용량 제한은 500MB입니다.</p>
                            <p className="ant-upload-hint">용량이 초과될 경우 분할 압축을 하여 업로드 해주십시오.</p>
                        </Dragger>
                    </Form.Item>
                    <Form.Item label="내용" name="description">
                        <Input.TextArea size={"large"} value={contents} onChange={(e) => setContents(e.target.value)} />
                    </Form.Item>
                </Form>
            </div>
        </SideModal>
    );
};

export {AddNewFileModal};
