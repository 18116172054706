import {useMemo, useState} from "react";
import {message} from "antd";
import Swal from "sweetalert2";

import {
    WorkPlanModalRequest,
    WorkPlanModalResponse,
    WorkPlanUpdateRequest,
    WorkRecord,
    WorkSummary,
} from "~/data/work-plan/register/use-work-plan-modal.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useWorkPlanModal = (config: WorkPlanModalRequest) => {
    const [response, setResponse] = useState<WorkSummary[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<WorkPlanModalRequest, WorkPlanModalResponse>({
            method: "GET",
            url: "/dailyWork/week",
            params: config,
        });
    }, [config]);

    function transformData(data: {[key: string]: WorkRecord[]}): WorkSummary[] {
        return Object.values(data).map((records) => {
            const {hrId, name, teamName, createDate, updateDate} = records[0]; // 공통된 필드를 사용
            const works = records.map(({workDate, detail}) => ({workDate, detail}));

            return {
                hrId,
                name,
                teamName,
                createDate,
                updateDate,
                works,
            };
        });
    }

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            const refinedData = transformData(res.data);
            setResponse(refinedData);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    return {employeeWorkList: response, employeeWorkListSubmit: submit, isLoading};
};

const useWorkPlanUpsert = (config: WorkPlanUpdateRequest) => {
    const [isLoading, setIsLoading] = useState(false);
    const request = useMemo(() => {
        return httpRequest<WorkPlanUpdateRequest, null>({
            method: "POST",
            url: "/dailyWork/upsertDailyWork",
            data: config,
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            await request.submit();
            message.success("저장되었습니다.");
        } catch (e) {
            console.log(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        } finally {
            setIsLoading(false);
        }
    };

    return {upsertSubmit: submit, isUpsertLoading: isLoading};
};

export {useWorkPlanModal, useWorkPlanUpsert};
