import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {FacilityListRequest, FacilityListResponse, MachineCategoryType} from "./use-facility-list.interface";

// 설비 리스트
const useFacilityList = (
    buildingId: number,
    machineCategoryCode: string,
): [
    () => Promise<void>,
    null | FacilityListResponse[],
    () => Promise<void>,
    {label: string; value: string}[],
    boolean,
    any,
] => {
    const [response, setResponse] = useState<null | FacilityListResponse[]>(null);
    const [machineCategory, setMachineCategory] = useState<{label: string; value: string}[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<FacilityListRequest, FacilityListResponse[]>({
            method: "GET",
            url: "/common/machines",
            params: {
                buildingId,
                machineCategoryCode: machineCategoryCode === "ALL" ? null : machineCategoryCode,
            },
        });
    }, [buildingId, machineCategoryCode]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const getMachineCategory = () => {
        return httpRequest<{buildingId: number}, MachineCategoryType[]>({
            method: "GET",
            url: "/machine/category",
            params: {
                searchMachineUseYn: "Y",
                buildingId,
            },
        })
            .submit()
            .then((res) => {
                const data = res.data;
                const refined = data.map((data) => ({
                    label: data.machineCategoryName,
                    value: data.machineCategoryCode,
                }));
                refined.unshift({
                    label: "전체",
                    value: "ALL",
                });
                setMachineCategory(refined);
            })
            .catch((e) => {
                console.log(e);
            });
    };
    return [submit, response, getMachineCategory, machineCategory, isLoading, error];
};

export {useFacilityList};
