import {useEffect, useMemo, useState} from "react";

import {useUser} from "~/data/user";
import {httpRequest} from "~/fetch/common/http-request";

import {
    FormRaw,
    MobileCheckList,
    MobileCheckListConfig,
    MobileCheckPreRequest,
    MobileCheckPreResponse,
    MobileCheckReport,
    MobileCheckRequest,
    MobileCheckResponse,
    SaveForm,
} from "./use-mobile-check-list.interface";

const UseMobileCheckList = ({employeeId, buildingId, orderDate, category}: MobileCheckListConfig) => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState<Array<MobileCheckReport>>([]);

    const request = useMemo(() => {
        if (buildingId && orderDate)
            return httpRequest<MobileCheckListConfig, MobileCheckList>({
                method: "GET",
                url: "/mobileCheck/r",
                params: {
                    employeeId,
                    buildingId,
                    orderDate,
                    category,
                },
            });
    }, [employeeId, buildingId, orderDate]);

    useEffect(() => {
        if (orderDate) {
            setLoading(true);
            if (request)
                request
                    .submit()
                    .then((res) => setList(res.data.reportList))
                    .finally(() => setLoading(false));
        }
    }, [request]);

    return {loading, list};
};

const useMobileCheck = (orderDate: string, checkFormIdArr: string, regionId: number) => {
    const [loading, setLoading] = useState(false);
    const [raw, setRaw] = useState<Array<FormRaw>>([]);
    const [regionName, setRegionName] = useState<string>("");

    const {user} = useUser();

    const request = useMemo(() => {
        return httpRequest<MobileCheckPreRequest, MobileCheckPreResponse>({
            method: "GET",
            url: "/mobileCheck/c",
            params: {
                buildingId: user?.buildingId,
                orderDate: orderDate,
                employeeId: user?.userId,
                checkFormIdArr: checkFormIdArr,
                regionId: regionId,
            },
        });
    }, [regionId, orderDate, checkFormIdArr, user]);

    useEffect(() => {
        request.submit().then((res) => {
            const checkItems: Array<FormRaw> = [];
            res.data.reportCheckList.map((checkItem) => {
                setRegionName(checkItem.regionName);
                const notiTimes = checkItem.notiTimeArr.split(",");
                checkItem.checkHistoryIdArr.split(",").map((checkHistoryId, index) => {
                    checkItems.push({
                        remark: "",
                        checkFormId: checkItem.checkFormId,
                        checkFormName: checkItem.checkFormName,
                        checkHistoryId: Number(checkHistoryId),
                        notiTime: notiTimes[index],
                        formItems: [],
                        regionId: regionId,
                        orderDate: orderDate,
                    });
                });
            });

            const requests = checkItems.map((item: any) => {
                return httpRequest<MobileCheckRequest, MobileCheckResponse>({
                    method: "GET",
                    url: "/mobileCheck/i",
                    params: {
                        buildingId: user?.buildingId,
                        orderDate: orderDate,
                        employeeId: user?.userId,
                        checkFormIdArr: checkFormIdArr,
                        checkHistoryId: item.checkHistoryId,
                    },
                }).submit();
            });

            Promise.all(requests).then((rsts) => {
                rsts.map((rst, index) => {
                    checkItems[index]["remark"] = rst.data.remark;
                    checkItems[index]["formItems"] = rst.data.reportCheckItemList;
                });

                setRaw(checkItems);
            });
        });
    }, [request]);

    const save = (form: SaveForm, buildingId: number | string, checkHistoryId: number | string) => {
        return httpRequest<SaveForm, any>({
            method: "PUT",
            url: `/buildings/${buildingId}/checks/${checkHistoryId}`,
            data: form,
        }).submit();
    };

    return {
        regionName,
        loading,
        raw,
        save,
    };
};

export {useMobileCheck, UseMobileCheckList};
