import {useEffect, useMemo, useState} from "react";

import {
    ServiceOpeningTypeCodeType,
    StorePartnerTypeResponse,
    StoreStatusListRequest,
    StoreStatusListResponse,
} from "~/data/store/use-store-status.interface";
import {axiosInstance} from "~/fetch/common/axios-instance";
import {httpRequest} from "~/fetch/common/http-request";

const useStoreStatus = (config: StoreStatusListRequest) => {
    const [response, setResponse] = useState<StoreStatusListResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<StoreStatusListRequest, StoreStatusListResponse>({
            method: "GET",
            url: "lotte/ems/buildingState/list",
            params: {
                ...config,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };

    const excel = () => {
        const params = Object.entries(config)
            .map(([key, value]) => key !== "pageNo" && key !== "recordPerPage" && value !== null && `${key}=${value}`)
            .filter((value) => value !== false)
            .join("&");

        window.open(
            axiosInstance.defaults.baseURL + `/lotte/ems/buildingState/excel${params ? "?" + params : ""}`,
            "_blank",
        );
    };

    return {storeStatusList: response, storeStatusListSubmit: submit, isLoading, excel};
};

// 출점형태 & 파트너사 & 에너지 관리형태
const useStoreCommonData = () => {
    const [partner, setPartner] = useState<StorePartnerTypeResponse>({
        buildingStateSearchVO: {
            allRecordCnt: 0,
        },
        responseData: [],
    });
    const [serviceOpeningTypeCode, setServiceOpeningTypeCode] = useState<ServiceOpeningTypeCodeType[]>([]);
    const [energyManage, setEnergyManage] = useState<ServiceOpeningTypeCodeType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const serviceOpeningTypeCodeRequest = useMemo(() => {
        return httpRequest<null, ServiceOpeningTypeCodeType[]>({
            method: "GET",
            url: `/common/detailCodes?groupCd=LC006`,
        });
    }, []);

    const partnerRequest = useMemo(() => {
        return httpRequest<null, StorePartnerTypeResponse>({
            method: "GET",
            url: "lotte/ems/buildingState/facility/partner",
        });
    }, []);

    const energyManageRequest = useMemo(() => {
        return httpRequest<null, ServiceOpeningTypeCodeType[]>({
            method: "GET",
            url: `/common/detailCodes?groupCd=LC010`,
        });
    }, []);

    useEffect(() => {
        try {
            setIsLoading(true);
            serviceOpeningTypeCodeRequest
                .submit()
                .then((res) => setServiceOpeningTypeCode(res.data))
                .catch((e) => console.log(e));
            partnerRequest
                .submit()
                .then((res) => setPartner(res.data))
                .catch((e) => console.log(e));
            energyManageRequest
                .submit()
                .then((res) => setEnergyManage(res.data))
                .catch((e) => console.log(e));
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }, []);

    return {partner, serviceOpeningTypeCode, energyManage, isLoading};
};

export {useStoreCommonData, useStoreStatus};
