import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {InspectionRateDetailRequest, InspectionRateDetailResponse} from "./use-inspection-rate-detail.interface";

const useInspectionRateDetail = (config: InspectionRateDetailRequest) => {
    const [response, setResponse] = useState<InspectionRateDetailResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<InspectionRateDetailRequest, InspectionRateDetailResponse>({
            method: "GET",
            url: "/lotte/fms/performance/detail",
            params: {
                ...config,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const excel = () => {
        httpRequest<InspectionRateDetailRequest, Blob>({
            method: "GET",
            url: "/lotte/fms/performance/detail/exceldownload",
            params: {
                ...config,
            },
            responseType: "blob",
        })
            .submit()
            .then((res) => {
                // 파일 다운로드 처리
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                // 파일명추출
                const contentType = res.headers["content-type"];
                let fileName = "downloaded_file";
                if (contentType) {
                    const matches = /filename=([^;]*)/.exec(contentType);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].trim().replace(/['"]/g, "");
                    }
                }

                link.setAttribute("download", decodeURIComponent(fileName.replace(/\+/g, " ")));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((e) => setError(e));
    };

    return {submitList: submit, list: response, excel, isLoading, error};
};

export {useInspectionRateDetail};
