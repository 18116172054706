import {useEffect, useState} from "react";
import {Input} from "antd";
import {valueType} from "antd/es/statistic/utils";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button";
import {CnRangePicker} from "~/components/cn-range-picker";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area";
import {FilterItem} from "~/components/filter-item";
import {PageTitle} from "~/components/page-title";
import {useBoardList} from "~/data/board/use-board-list";
import {SurplusBoardModal} from "~/pages/board/surplus/modal/surplus-board.modal";
import {SurplusBoardInputType} from "~/pages/board/surplus/surplus-board.page.interface";

import styles from "./surplus-board.page.module.scss";

const cx = classNames.bind(styles);

const SurplusBoardPage = () => {
    const perPage = 50;
    const [modal, setModal] = useState<boolean>(false);
    const [noticeId, setNoticeId] = useState<number | null>(null);
    const [page, setPage] = useState<number>(1);
    const [inputs, setInputs] = useState<SurplusBoardInputType>({
        dateRange: null,
        title: "",
        content: "",
        comment: "",
    });

    const [submitList, tableList, isListLoading] = useBoardList({
        category: "surplus",
        title: inputs.title || null,
        startDate: inputs.dateRange ? inputs.dateRange[0].format("YYYY-MM-DD") : null,
        endDate: inputs.dateRange ? inputs.dateRange[1].format("YYYY-MM-DD") : null,
        content: inputs.content || null,
        comment: inputs.comment || null,
        page,
        perPage,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        submitList();
    }, [page]);

    const handleReset = () => {
        setInputs({
            dateRange: null,
            title: "",
            content: "",
            comment: "",
        });
    };

    const handleSubmit = () => {
        setPage(1);
        submitList();
        window.scrollTo(0, 0);
    };

    return (
        <div>
            {modal && <SurplusBoardModal close={() => setModal(false)} noticeId={noticeId} refresh={submitList} />}
            <PageTitle>잉여자재 게시판</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnRangePicker
                        label="기간"
                        value={inputs.dateRange}
                        onChange={(value) => setInputs((prev) => ({...prev, dateRange: value}))}
                        allowClear={true}
                    />
                    <FilterItem label={"제목"}>
                        <Input
                            style={{width: "180px"}}
                            placeholder="제목 검색"
                            value={inputs.title as valueType}
                            onChange={(e) => setInputs((prev) => ({...prev, title: e.target.value}))}
                        />
                    </FilterItem>
                    <FilterItem label={"내용"}>
                        <Input
                            style={{width: "180px"}}
                            placeholder="내용 검색"
                            value={inputs.content as valueType}
                            onChange={(e) => setInputs((prev) => ({...prev, content: e.target.value}))}
                        />
                    </FilterItem>
                    <FilterItem label={"답글내용"}>
                        <Input
                            style={{width: "180px"}}
                            placeholder="답글내용 검색"
                            value={inputs.comment as valueType}
                            onChange={(e) => setInputs((prev) => ({...prev, comment: e.target.value}))}
                        />
                    </FilterItem>
                    <div className={cx("button-container")}>
                        <Button label={"조회"} color={"primary"} onClick={handleSubmit} />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <div className={cx("table-label-container")}>
                            <span>
                                {tableList?.allRecordCnt} 건{" "}
                                {`(${page}/${Math.ceil((tableList?.allRecordCnt ? tableList.allRecordCnt : 1) / perPage)} 페이지)`}
                            </span>
                            <Button
                                label="등록하기"
                                onClick={() => {
                                    setModal(true);
                                    setNoticeId(null);
                                }}
                            />
                        </div>
                    }
                    totalCount={tableList?.allRecordCnt ?? 0}
                    onPageChange={(e) => setPage(e)}
                    page={page}
                    perPage={perPage}
                    pagination={false}
                    loading={isListLoading}
                    rowKey={"id"}
                    onRow={(record) => ({
                        onClick: () => {
                            setModal(true);
                            setNoticeId(record.id);
                        },
                    })}
                    dataSource={tableList?.list}
                    columns={[
                        {title: "No", key: "rowNum", dataIndex: "rowNum", width: "4%"},
                        {title: "작성자", key: "name", dataIndex: "name", width: "10%"},
                        {
                            title: "제목",
                            key: "title",
                            dataIndex: "title",
                            width: "50%",
                            ellipsis: true,
                            render: (value, record) => (
                                <>
                                    {value}{" "}
                                    {record.commentCnt !== 0 && (
                                        <span className={cx("comment-count")}> ({record.commentCnt})</span>
                                    )}
                                </>
                            ),
                        },
                        {
                            title: "등록일",
                            key: "createDate",
                            dataIndex: "createDate",
                            width: "12%",
                            render: (record) => {
                                return dayjs(record).format("YYYY-MM-DD HH:mm");
                            },
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {SurplusBoardPage};
