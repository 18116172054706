import {useState} from "react";
import {message} from "antd";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

import {CommonBoardRegisterRequest, CommonBoardUpdateRequest} from "./common-board.interface";
import {BoardCategory} from "./use-board-list.interface";

const useCommonBoard = (info: {noticeId: number; category: BoardCategory}) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const register = async (config: CommonBoardRegisterRequest) => {
        setIsLoading(true);

        const formData = new FormData();

        const temp = JSON.stringify({
            category: info.category,
            title: config.title,
            content: config.content,
        });
        formData.append("data", temp);
        config.files.forEach((file) => {
            formData.append(`files`, file);
        });
        const hideLoading = message.loading("등록 중입니다...", 0);
        return await httpRequest<FormData, any>({
            method: "POST",
            url: "/commonBoard/createCommonBoard",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            timeout: 1000 * 60 * 5,
        })
            .submit()
            .then(() => {
                hideLoading();
                message.success("등록이 완료되었습니다.");
            })
            .catch((e) => {
                hideLoading();
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    }; // register

    const update = async (config: CommonBoardUpdateRequest) => {
        setIsLoading(true);
        const formData = new FormData();
        const temp = JSON.stringify({
            id: info.noticeId,
            category: info.category,
            title: config.title,
            content: config.content,
            removalFileIds: config.removalFileIds.filter((item) => !isNaN(item)),
        });

        formData.append("data", temp);

        config.files.forEach((file) => formData.append("files", file));
        const hideLoading = message.loading("수정 중입니다...", 0);

        return await httpRequest<FormData, any>({
            method: "POST",
            url: "/commonBoard/modifyCommonBoard",
            headers: {
                "Content-Type": "multipart/form-data",
            },
            data: formData,
            timeout: 1000 * 60 * 5,
        })
            .submit()
            .then(() => {
                hideLoading();
                message.success("수정이 완료되었습니다.");
            })
            .catch((e) => {
                hideLoading();
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                }).finally(() => setIsLoading(false));
            });
    }; // update

    const deleteBoard = async () => {
        setIsLoading(true);
        const hideLoading = message.loading("삭제 중입니다...", 0);
        return await httpRequest<null, any>({
            method: "DELETE",
            url: "/commonBoard/removeCommonBoard/" + info.noticeId,
            timeout: 1000 * 60 * 5,
        })
            .submit()
            .then(() => {
                hideLoading();
                message.success("삭제가 완료되었습니다.");
            })
            .catch((e) => {
                hideLoading();
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    }; // delete

    const fileDownload = async (fileId: number | string) => {
        const hideLoading = message.loading("파일 다운로드 중입니다...", 0);
        return await httpRequest<null, Blob>({
            method: "GET",
            url: "/commonBoard/download",
            params: {
                id: fileId,
            },
            responseType: "blob",
            timeout: 1000 * 60 * 5,
        })
            .submit()
            .then((res) => {
                hideLoading();
                // 파일 다운로드 처리
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                // 파일명추출
                const contentType = res.headers["content-type"];
                let fileName = "downloaded_file";
                if (contentType) {
                    const matches = /filename=([^;]*)/.exec(contentType);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].trim().replace(/['"]/g, "");
                    }
                }

                link.setAttribute("download", decodeURIComponent(fileName.replace(/\+/g, " ")));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((e) => {
                hideLoading();
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            });
    };

    return {register, update, deleteBoard, fileDownload, isLoading, error};
};

export {useCommonBoard};
