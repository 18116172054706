import React, {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {UseMobileCheckList} from "~/data/check/mobile/use-mobile-check-list";
import {useUser} from "~/data/user";
import {calculatePercentage} from "~/utills/calculatePercentage";

import styles from "./pc-dashboard.page.module.scss";

const cx = classNames.bind(styles);

const CheckStatusManager = () => {
    const {buildingId, user} = useUser();
    const navigate = useNavigate();

    const {summary} = useCheckElevatorList({
        perPage: 1000,
        page: 1,
        buildingId: buildingId,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
    });

    const legal = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: undefined as any,
        category: "LEGAL",
    });

    const checklist = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: undefined as any,
        category: "CHECK",
    });

    const extra = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: undefined as any,
        category: "ADD",
    });

    const normal = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: undefined as any,
        category: "NORMAL",
    });

    const legalCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        legal.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [legal.list]);

    const checklistCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        checklist.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [checklist.list]);

    const extraCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        extra.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [extra.list]);

    const normalCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        normal.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [normal.list]);

    return (
        <div className={cx("card", "check-status")}>
            <div className={cx("body")}>
                <div className={cx("check-analytics")}>
                    <div className={cx("analytics-area")}>
                        <ul className={cx("analytics-list")}>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/legal/history")}
                                >
                                    법정점검
                                </span>
                                <span className={cx("analytics-item-desc")}>{legalCount[0] + legalCount[1]}건</span>
                                <span className={cx("analytics-item-tag")}>점검전</span>
                                <span className={cx("analytics-item-desc")}>
                                    {legalCount[2]}건(
                                    {calculatePercentage(legalCount[2], legalCount[0] + legalCount[1] + legalCount[2])}
                                    %)
                                </span>
                                <span className={cx("analytics-item-tag")}>이행률</span>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/extra/history")}
                                >
                                    일반점검
                                </span>
                                <span className={cx("analytics-item-desc")}>{extraCount[0] + extraCount[1]}건</span>
                                <span className={cx("analytics-item-tag")}>점검전</span>
                                <span className={cx("analytics-item-desc")}>
                                    {extraCount[2]}건(
                                    {calculatePercentage(extraCount[2], extraCount[0] + extraCount[1] + extraCount[2])}
                                    %)
                                </span>
                                <span className={cx("analytics-item-tag")}>이행률</span>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/elevator")}
                                >
                                    승강기점검
                                </span>
                                <span className={cx("analytics-item-desc")}>{summary.REQUEST}건</span>
                                <span className={cx("analytics-item-tag")}>접수완료</span>
                                <span className={cx("analytics-item-desc")}>{summary.COMPLETED}건</span>
                                <span className={cx("analytics-item-tag")}>조치완료</span>
                            </li>
                        </ul>
                    </div>
                    <div className={cx("analytics-area")}>
                        <ul className={cx("analytics-list")}>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/checklist/history")}
                                >
                                    체크리스트
                                </span>
                                <span className={cx("analytics-item-desc")}>
                                    {checklistCount[0] + checklistCount[1]}건
                                </span>
                                <span className={cx("analytics-item-tag")}>점검전</span>
                                <span className={cx("analytics-item-desc")}>
                                    {checklistCount[2]}건(
                                    {calculatePercentage(
                                        checklistCount[2],
                                        checklistCount[0] + checklistCount[1] + checklistCount[2],
                                    )}
                                    %)
                                </span>
                                <span className={cx("analytics-item-tag")}>이행률</span>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/normal/history")}
                                >
                                    추가점검
                                </span>
                                <span className={cx("analytics-item-desc")}>{normalCount[0] + normalCount[1]}건</span>
                                <span className={cx("analytics-item-tag")}>점검전</span>
                                <span className={cx("analytics-item-desc")}>
                                    {normalCount[2]}건(
                                    {calculatePercentage(
                                        normalCount[2],
                                        normalCount[0] + normalCount[1] + normalCount[2],
                                    )}
                                    %)
                                </span>
                                <span className={cx("analytics-item-tag")}>이행률</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {CheckStatusManager};
