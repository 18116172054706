import {useEffect, useMemo, useState} from "react";

import {useGeoLocation} from "~/hooks/use-geo-location/use-geo-location";

const useWeather = () => {
    const [weather, setWeather] = useState<any>({});
    const [lat, setLat] = useState<number | undefined>(undefined);
    const [long, setLong] = useState<number | undefined>(undefined);
    const [weatherLoading, setWeatherLoading] = useState<boolean>(false);

    const {location} = useGeoLocation();

    useEffect(() => {
        setLat(location?.latitude);
        setLong(location?.longitude);
    }, [location]);

    useEffect(() => {
        setWeatherLoading(true);
        if (lat && long) {
            fetch(
                `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${long}&lang=kr&units=metric&mode=json&APPID=0e0dad479afb51b05e6ac8462a8fcb94`,
            )
                .then((res) => res.json())
                .then((data) => setWeather(data))
                .finally(() => {
                    setWeatherLoading(false);
                });
        }
    }, [lat, long]);

    return {weather, weatherLoading};
};

export {useWeather};
