import {useEffect, useState} from "react";
import {Input} from "antd";

import {AreaSettingInputProps} from "~/pages/area/area-setting.page.interface";

const AreaSettingInput = ({setIsUpdate, setIsAdd, initialize, ...props}: AreaSettingInputProps) => {
    const [value, setValue] = useState<string>(initialize ?? "");

    useEffect(() => {
        if (setIsUpdate) {
            setIsUpdate((prev) => {
                return prev ? {...prev, value: value} : null;
            });
        } else if (setIsAdd) {
            setIsAdd((prev) => {
                return prev ? {...prev, regionName: value} : null;
            });
        }
    }, [value, setIsUpdate]);

    return <Input value={value} onChange={(e) => setValue(e.target.value)} {...props} />;
};

export {AreaSettingInput};
