import {ChangeEvent, useEffect, useState} from "react";
import DaumPostcodeEmbed, {Address} from "react-daum-postcode";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {App, Button as AntdButton, DatePicker, Input, InputNumber} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CenterModal} from "~/components/modal/center-modal";
import {useBuildingList} from "~/data/building/use-building-list";
import {
    SectorSettingModalInput,
    SectorSettingModalProps,
} from "~/pages/building/modal/building-setting.modal.interface";
import {formatCost} from "~/utills/formatCost";

import styles from "./building-setting.modal.module.scss";

const cx = classNames.bind(styles);

const BuildingSettingModal = ({isShow, close, data, refresh}: SectorSettingModalProps) => {
    const [findAddress, setFindAddress] = useState<boolean>(false);
    const [inputs, setInputs] = useState<SectorSettingModalInput>({
        buildingName: "",
        address: null,
        subAddress: "",
        establishedDate: null,
        grossFloorArea: 0,
        purpose: "",
        ctor: "",
        startOpenDate: null,
    });
    const {register, update, loading} = useBuildingList();

    useEffect(() => {
        if (data) {
            setInputs({
                buildingName: data.buildingName,
                address: data.address,
                subAddress: data.subAddress ?? "",
                establishedDate: dayjs(data.establishedDate),
                grossFloorArea: data.grossFloorArea ?? 0,
                ctor: data.ctor ?? "",
                purpose: data.purpose ?? "",
                startOpenDate: data.startOpenDate ? dayjs(data.startOpenDate) : null,
            });
        }
    }, [data]);

    const handleComplete = (data: Address) => {
        setInputs((prev) => ({
            ...prev,
            address: data.address,
        }));
        setFindAddress(false);
    };
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setInputs((prev) => ({...prev, [e.target.name]: e.target.value}));
    };
    const handleConfirm = () => {
        if (
            inputs.buildingName &&
            inputs.address &&
            inputs.establishedDate &&
            inputs.grossFloorArea &&
            inputs.ctor &&
            inputs.purpose
        ) {
            Swal.fire({
                text: "저장하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "저장하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    if (data) {
                        await update(
                            {
                                ...inputs,
                                address: inputs.address!,
                                establishedDate: inputs.establishedDate!.format("YYYY-MM-DD"),
                                startOpenDate: inputs.startOpenDate ? inputs.startOpenDate.format("YYYY-MM-DD") : null,
                            },
                            data.buildingId,
                        );
                        setTimeout(refresh, 500);
                        close();
                    } else {
                        await register({
                            ...inputs,
                            address: inputs.address!,
                            establishedDate: inputs.establishedDate!.format("YYYY-MM-DD"),
                            startOpenDate: inputs.startOpenDate ? inputs.startOpenDate.format("YYYY-MM-DD") : null,
                        });
                        setTimeout(refresh, 500);
                        close();
                    }
                } // isConfirmed
            });
        } else {
            Swal.fire({
                text: "필수요소를 입력해야합니다.",
                confirmButtonText: "확인",
            });
        }
    };

    return (
        <CenterModal
            isShow={isShow}
            close={close}
            closeIcon={false}
            width={500}
            extraButtons={
                findAddress
                    ? [<Button key={"back"} label={"뒤로가기"} onClick={() => setFindAddress(false)} />]
                    : [<Button label={"저장하기"} key={"save"} onClick={handleConfirm} />]
            }
            title={<>{data ? "건물정보 수정" : "건물추가"}</>}
        >
            <App>
                {findAddress ? (
                    <div>
                        <DaumPostcodeEmbed onComplete={handleComplete} />
                    </div>
                ) : (
                    <ul className={cx("content")}>
                        <li className={cx("item")}>
                            <span className={cx("name")}>지점명</span>
                            <div className={cx("input-box")}>
                                <Input
                                    className={cx("input")}
                                    placeholder={"지점명"}
                                    name={"buildingName"}
                                    status={inputs.buildingName === "" ? "error" : ""}
                                    value={inputs.buildingName}
                                    onChange={handleChange}
                                />
                            </div>
                        </li>
                        <li className={cx("item")}>
                            <span className={cx("name")}>주소</span>
                            <div className={cx("input-box")}>
                                <AntdButton
                                    className={cx("input", "button")}
                                    onClick={() => {
                                        setFindAddress(true);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faSearch} className={cx("icon")} /> 주소찾기
                                </AntdButton>
                                <div className={cx("address")}>{inputs.address ?? "주소를 선택해주세요"}</div>
                            </div>
                        </li>
                        <li className={cx("item")}>
                            <span className={cx("name")}>상세주소</span>
                            <div className={cx("input-box")}>
                                <Input
                                    className={cx("input")}
                                    placeholder={"상세주소"}
                                    name={"subAddress"}
                                    value={inputs.subAddress}
                                    onChange={handleChange}
                                />
                            </div>
                        </li>
                        <li className={cx("item")}>
                            <span className={cx("name")}>연면적</span>
                            <div className={cx("input-box")}>
                                <InputNumber
                                    className={cx("input", "have-unit")}
                                    status={inputs.grossFloorArea === 0 ? "error" : ""}
                                    placeholder={"소수점 이하는 생략"}
                                    addonAfter={<span className={cx("unit")}>㎡</span>}
                                    value={inputs.grossFloorArea}
                                    formatter={(value) => formatCost(value)}
                                    onChange={(e) => setInputs((prev) => ({...prev, grossFloorArea: e as number}))}
                                />
                            </div>
                        </li>
                        <li className={cx("item")}>
                            <span className={cx("name")}>용도</span>
                            <div className={cx("input-box")}>
                                <Input
                                    className={cx("input", "have-unit")}
                                    placeholder={"건물 용도"}
                                    status={inputs.purpose === "" ? "error" : ""}
                                    value={inputs.purpose}
                                    name={"purpose"}
                                    onChange={handleChange}
                                />
                            </div>
                        </li>
                        <li className={cx("item")}>
                            <span className={cx("name")}>완공일</span>
                            <div className={cx("input-box")}>
                                <DatePicker
                                    placeholder={"완공일 선택"}
                                    style={{width: "100%", borderRadius: 0}}
                                    status={inputs.establishedDate ? "" : "error"}
                                    value={inputs.establishedDate}
                                    onChange={(e) => setInputs((prev) => ({...prev, establishedDate: e}))}
                                />
                            </div>
                        </li>
                        <li className={cx("item")}>
                            <span className={cx("name")}>시공사</span>
                            <div className={cx("input-box")}>
                                <Input
                                    className={cx("input", "have-unit")}
                                    placeholder={"시공사"}
                                    name={"ctor"}
                                    status={inputs.ctor === "" ? "error" : ""}
                                    value={inputs.ctor}
                                    onChange={handleChange}
                                />
                            </div>
                        </li>
                        <li className={cx("item")}>
                            <span className={cx("name")}>오픈일</span>
                            <div className={cx("input-box")}>
                                <DatePicker
                                    placeholder={"오픈일 선택"}
                                    style={{width: "100%", borderRadius: 0}}
                                    value={inputs.startOpenDate}
                                    onChange={(e) => setInputs((prev) => ({...prev, startOpenDate: e}))}
                                />
                            </div>
                        </li>
                    </ul>
                )}
            </App>
        </CenterModal>
    );
};

export {BuildingSettingModal};
