import {useEffect, useState} from "react";
import {faDownload, faPaperclip, faTrashCan, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, Input, List, Spin, Upload} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import {v4 as uuidv4} from "uuid";

import Button from "~/components/button";
import {MergeGridItem} from "~/components/merge/merge-grid-item";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {useCommonBoardDetail} from "~/data/board/inquiry/use-inquiry-board";
import {useInquiryComment} from "~/data/board/inquiry/use-inquiry-comment";
import {FileInfo} from "~/data/board/use-board-list.interface";
import {useCommonBoard} from "~/data/board/use-common-board";
import {useUser} from "~/data/user";
import {InquiryBoardModalCommentInput} from "~/pages/board/inquiry/modal/inquiry-board.modal.interface";
import {
    SurplusBoardModalInputType,
    SurplusBoardModalProps,
} from "~/pages/board/surplus/modal/surplus-board.modal.interface";

import styles from "./surplus-board.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const SurplusBoardModal = ({close, noticeId, refresh}: SurplusBoardModalProps) => {
    const [inputs, setInputs] = useState<SurplusBoardModalInputType>({
        title: "",
        content: "",
        files: [],
    });
    const [fileList, setFileList] = useState<FileInfo[]>([]);
    const [commentInputs, setCommentInputs] = useState<InquiryBoardModalCommentInput>({
        comment: "",
        file: null,
    });
    const [deleteFileList, setDeleteFileList] = useState<number[]>([]);
    const [isOwner, setIsOwner] = useState<boolean>(false);

    const {user} = useUser();

    const {submitDetail, detail, isDetailLoading} = useCommonBoardDetail(noticeId ?? 0);
    const {register, update, deleteBoard, fileDownload, isLoading} = useCommonBoard({
        noticeId: noticeId ?? 0,
        category: "surplus",
    });

    const {commentRegister, deleteComment} = useInquiryComment(noticeId ?? 0);

    const handleConfirm = () => {
        if (noticeId) {
            Swal.fire({
                text: "수정하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "수정하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await update({
                        title: inputs.title,
                        content: inputs.content,
                        files: inputs.files,
                        removalFileIds: deleteFileList,
                    });
                    setTimeout(refresh, 500);

                    close();
                }
            });
        } else {
            Swal.fire({
                text: "등록하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "등록하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await register({
                        title: inputs.title,
                        content: inputs.content,
                        files: inputs.files,
                    });
                    setTimeout(refresh, 500);

                    close();
                }
            });
        }
    };
    const handleDelete = () => {
        if (noticeId)
            Swal.fire({
                text: "삭제하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "삭제하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await deleteBoard();
                    setTimeout(refresh, 500);
                    close();
                }
            });
    };
    const handleRemoveFile = (file: FileInfo) => {
        setFileList((prev) => [...prev.filter((item) => item.id !== file.id)]);
        setDeleteFileList((prev) => [...prev, Number(file.id)]);
        setInputs((prev) => ({...prev, files: prev.files.filter((item) => item.name !== file.fileOriginName)}));
    };

    const handleCommentRegister = () => {
        if (commentInputs.comment === "" && commentInputs.file === null) {
            Swal.fire({
                text: "답글 내용을 입력해주세요",
                confirmButtonText: " 확인",
            });
        } else {
            Swal.fire({
                text: "등록하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "등록하기",
                cancelButtonText: "취소하기",
            }).then(async (info) => {
                if (info.isConfirmed) {
                    await commentRegister({
                        content: commentInputs.comment,
                        file: commentInputs.file,
                    });

                    setTimeout(submitDetail, 500);
                    setTimeout(refresh, 500);
                    setCommentInputs({
                        comment: "",
                        file: null,
                    });
                }
            });
        }
    };

    useEffect(() => {
        if (noticeId) submitDetail();
    }, [noticeId]);

    useEffect(() => {
        if (detail) {
            setFileList(detail?.files ?? []);
            setIsOwner(detail?.commonBoard.username === user?.username);
            setInputs((prev) => ({
                ...prev,
                title: detail?.commonBoard.title,
                content: detail?.commonBoard.content,
            }));
        }
    }, [detail]);

    useEffect(() => {
        const temp = inputs.files.map((data) => ({
            id: uuidv4(),
            fileOriginName: data.name,
            fileStorePath: "",
        }));
        setFileList((prev) => {
            const mergedList = [...prev, ...temp];
            return mergedList.filter(
                (file, index, self) => index === self.findIndex((f) => f.fileOriginName === file.fileOriginName),
            );
        });
    }, [inputs.files]);

    return (
        <SideModal
            onClose={close}
            confirmLabel={noticeId ? (isOwner ? "수정하기" : undefined) : "등록하기"}
            onConfirm={handleConfirm}
            extraButton={noticeId && isOwner ? [<Button label="삭제하기" onClick={handleDelete} key={"delete"} />] : []}
        >
            <PageTitle>잉여자재 게시판 상세</PageTitle>
            {isDetailLoading || isLoading ? (
                <div className={cx("loading")}>
                    <Spin size="large" />
                </div>
            ) : (
                <div className={cx("form-container")}>
                    <div className={cx("question-content", {register: noticeId === null})}>
                        {noticeId !== null && (
                            <>
                                <MergeGridItem name={"작성자"}>
                                    {detail?.commonBoard.name}
                                    <span className={cx("create-user-id")}>({detail?.commonBoard.username})</span>
                                </MergeGridItem>
                                <MergeGridItem name={"작성일"}>
                                    {dayjs(detail?.commonBoard.createDate).format("YYYY-MM-DD HH:mm")}
                                </MergeGridItem>
                            </>
                        )}
                        <MergeGridItem name={"제목"} span>
                            {noticeId && !isOwner ? (
                                detail?.commonBoard.title
                            ) : (
                                <Input
                                    className={cx("input")}
                                    placeholder="제목을 입력하세요"
                                    value={inputs.title}
                                    onChange={(e) => setInputs((prev) => ({...prev, title: e.target.value}))}
                                    disabled={detail?.commonBoard.status === "COMPLETED"}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"내용"} span extraClassName={"padding-container"}>
                            {noticeId && !isOwner ? (
                                <pre className={cx("content")}>{detail?.commonBoard.content}</pre>
                            ) : (
                                <Input.TextArea
                                    className={cx("text-area")}
                                    placeholder="내용을 입력하세요"
                                    value={inputs.content}
                                    onChange={(e) => setInputs((prev) => ({...prev, content: e.target.value}))}
                                    disabled={detail?.commonBoard.status === "COMPLETED"}
                                />
                            )}
                        </MergeGridItem>
                        <MergeGridItem name={"첨부파일"} span extraClassName={"upload-container"}>
                            <div className={gridCx("file-container")}>
                                <Upload.Dragger
                                    className={gridCx("upload-dragger")}
                                    name={"file"}
                                    multiple={true}
                                    maxCount={5}
                                    showUploadList={false}
                                    disabled={
                                        (noticeId !== null && !isOwner) || detail?.commonBoard.status === "COMPLETED"
                                    }
                                    beforeUpload={(e) => {
                                        setInputs((prev) => {
                                            const newFiles = [...prev.files];
                                            newFiles.push(e);
                                            return {
                                                ...prev,
                                                files: newFiles,
                                            };
                                        });
                                        return false;
                                    }}
                                >
                                    {fileList.length ? (
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={fileList}
                                            className={gridCx("file-list")}
                                            renderItem={(file, index) => (
                                                <List.Item
                                                    className={gridCx("file-list-item")}
                                                    key={index}
                                                    actions={[
                                                        <ButtonAntd
                                                            type="link"
                                                            key={index}
                                                            icon={<FontAwesomeIcon icon={faDownload} />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                fileDownload(file.id);
                                                            }}
                                                        />,
                                                        <ButtonAntd
                                                            type="link"
                                                            danger
                                                            key={index}
                                                            icon={<FontAwesomeIcon icon={faTrashCan} />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleRemoveFile(file);
                                                            }}
                                                            disabled={!isOwner && noticeId !== null}
                                                        />,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                        title={file.fileOriginName}
                                                        className={gridCx("file-list-item-meta")}
                                                        style={{textAlign: "left"}}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    ) : (
                                        <>
                                            {noticeId !== null && !isOwner ? (
                                                <p>첨부된 파일이 없습니다</p>
                                            ) : detail?.commonBoard.status === "COMPLETED" ? (
                                                <p>첨부된 파일이 없습니다</p>
                                            ) : (
                                                <p>드래그하여 파일을 업로드 하거나 파일을 선택하세요</p>
                                            )}
                                        </>
                                    )}
                                </Upload.Dragger>
                            </div>
                        </MergeGridItem>
                    </div>
                    {detail?.commonBoard.status === "COMPLETED" && <div className={cx("subtitle")}>답글</div>}
                    {noticeId && detail?.commonBoard.status !== "COMPLETED" && (
                        <>
                            {/* 답글 */}
                            <div className={cx("subtitle")}>답글</div>
                            {/* 답글 달기 */}
                            <div className={cx("comment-container")}>
                                <div className={cx("input-container")}>
                                    <Input.TextArea
                                        className={cx("comment-text-area")}
                                        placeholder={"답글내용을 입력하세요"}
                                        value={commentInputs.comment}
                                        onChange={(e) =>
                                            setCommentInputs((prev) => ({...prev, comment: e.target.value}))
                                        }
                                    />
                                    <ButtonAntd className={cx("add-file-button", {button: true})}>
                                        <Upload
                                            className={cx("upload")}
                                            showUploadList={false}
                                            rootClassName={cx("upload")}
                                            maxCount={1}
                                            beforeUpload={(e) => {
                                                setCommentInputs((prev) => ({...prev, file: e}));
                                                return false;
                                            }}
                                        >
                                            <div className={cx("upload-button")}>파일 첨부</div>
                                        </Upload>
                                    </ButtonAntd>
                                    <ButtonAntd
                                        className={cx("confirm-button", {button: true})}
                                        onClick={handleCommentRegister}
                                    >
                                        등록
                                    </ButtonAntd>
                                </div>
                                <div />
                                {commentInputs.file && (
                                    <div className={cx("file-info")}>
                                        <FontAwesomeIcon icon={faPaperclip} style={{marginRight: "10px"}} />
                                        <span className={cx("file-name")}>{commentInputs.file.name}</span>
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            className={cx("remove-icon")}
                                            color={"red"}
                                            onClick={() => setCommentInputs((prev) => ({...prev, file: null}))}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    {noticeId && (
                        <>
                            {/* 답글 리스트 */}
                            {detail?.comments.length ? (
                                detail?.comments.map((data, index) => (
                                    <div className={cx("comment")} key={index}>
                                        <span className={cx("comment-name")}>{data.name}</span>
                                        <span className={cx("comment-id")}>({data.username})</span>
                                        {user?.username === data.username && (
                                            <span
                                                className={cx("comment-delete")}
                                                onClick={() => {
                                                    Swal.fire({
                                                        text: "답글을 삭제하시겠습니까?",
                                                        showCancelButton: true,
                                                        confirmButtonText: "확인",
                                                        cancelButtonText: "취소",
                                                    }).then(async (info) => {
                                                        if (info.isConfirmed) {
                                                            await deleteComment(data.id);
                                                            setTimeout(submitDetail, 500);
                                                        }
                                                    });
                                                }}
                                            >
                                                삭제
                                            </span>
                                        )}
                                        <span className={cx("comment-date")}>
                                            {dayjs(data.createDate).format("YYYY-MM-DD HH:mm")}
                                        </span>
                                        <p className={cx("comment-content")}>{data.content}</p>
                                        {data.fileId && (
                                            <p className={cx("comment-file")} onClick={() => fileDownload(data.fileId)}>
                                                <FontAwesomeIcon
                                                    icon={faPaperclip}
                                                    className={cx("comment-file-icon")}
                                                />{" "}
                                                {data.fileOriginName}
                                            </p>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className={cx("none-comment")}>등록된 답글이 없습니다.</div>
                            )}
                        </>
                    )}
                </div>
            )}
        </SideModal>
    );
};

export {SurplusBoardModal};
