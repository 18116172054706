import {useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {Category, RawCategory, RawCategoryResponse} from "./use-drive.interface";

const useDrive = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [rawCategories, setRawCategories] = useState<Array<RawCategory>>([]);

    const submit = () => {
        setLoading(true);
        httpRequest<any, RawCategoryResponse>({
            method: "GET",
            url: "/fileBoard/categoryList",
        })
            .submit()
            .then((res) => {
                setRawCategories(res.data.responseData);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => submit(), []);

    const formattedCategory = useMemo(() => {
        const formattedMap: {[key: number]: Category} = {};
        const tree: Array<Category> = [];

        rawCategories.forEach((category) => {
            formattedMap[category.id] = {
                parent: category.parent,
                categoryId: category.categoryId,
                id: category.id,
                companyId: category.companyId,
                text: category.text,
                state: category.state,
                createUser: category.createUser,
                updateUser: category.updateUser,
                children: [],
            };
        });

        rawCategories.forEach((category) => {
            if (category.parent === "#") {
                tree.push(formattedMap[category.id]);
            } else {
                const parent = formattedMap[category.parent];
                if (parent) {
                    parent.children?.push(formattedMap[category.id]);
                }
            }
        });

        return tree;
    }, [rawCategories]);

    const changeParentFolderForFolder = (target: number, to: number) => {
        return httpRequest<
            {
                categoryId: number;
                targetCategoryId: number;
            },
            any
        >({
            method: "PUT",
            url: "/fileBoard/changeCategory",
            data: {
                categoryId: target,
                targetCategoryId: to,
            },
        });
    };

    const changeParentFolderForFile = (target: number, to: number) => {
        return httpRequest<
            {
                fileBoardSeq: number;
                targetCategoryId: number;
            },
            any
        >({
            method: "PUT",
            url: "/fileBoard/changeCategory",
            data: {
                fileBoardSeq: target,
                targetCategoryId: to,
            },
        });
    };

    return {
        loading,
        formattedCategory,
        rawCategories,
        refresh: submit,
        changeParentFolderForFolder,
        changeParentFolderForFile,
    };
};

export {useDrive};
