import {useState} from "react";
import {message} from "antd";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CenterModal} from "~/components/modal/center-modal";
import {PageTitle} from "~/components/page-title";
import {Region} from "~/data/shop/use-region.interface";
import {httpRequest} from "~/fetch/common/http-request";
import {ChangeSectorModalInterface} from "~/pages/check/history/modal/change-sector.modal.interface";
import {RegionSelect} from "~/pages/check/instruction/region-select";

const ChangeSectorModal = ({close, isShow, refresh, checkHistoryId}: ChangeSectorModalInterface) => {
    const [region, setRegion] = useState<Region | null>(null);

    const update = async () => {
        if (region) {
            return httpRequest<{regionId: number; checkHistoryId: number}, null>({
                method: "PUT",
                url: "/buildings/checks/region",
                data: {
                    regionId: region?.regionId,
                    checkHistoryId: checkHistoryId,
                },
            })
                .submit()
                .then(() => {
                    message.success("수정이 완료되었습니다.");
                    refresh();
                    close();
                })
                .catch((e) => {
                    console.log(e);
                    Swal.fire({
                        text: "오류가 발생하였습니다. 다시 시도해주세요",
                        confirmButtonText: "확인",
                    });
                });
        } else {
            Swal.fire({
                text: "수정하실 구역을 선택해주세요",
                confirmButtonText: "확인",
            });
        }
    };

    const handleUpdate = async () => {
        await update();
    };
    return (
        <CenterModal
            isShow={isShow}
            close={close}
            extraButtons={[<Button label={"수정하기"} onClick={handleUpdate} key={"update"} />]}
        >
            <PageTitle>구역 수정</PageTitle>
            <p style={{width: "100%", margin: "10px 0", borderBottom: `2px solid #d8d8d8`, paddingBottom: 5}}>
                선택됨 : {region?.regionName}
            </p>
            <RegionSelect
                onChange={(region) => {
                    setRegion(region);
                }}
                onChangeMember={() => {
                    return false;
                }}
            />
        </CenterModal>
    );
};

export {ChangeSectorModal};
