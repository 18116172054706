import {message} from "antd";
import Swal from "sweetalert2";

import {StoreUpdateRequest} from "~/data/store/use-store-update.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useStoreUpdate = (config: StoreUpdateRequest) => {
    const update = async () => {
        return httpRequest<StoreUpdateRequest, null>({
            method: "PUT",
            url: "/lotte/ems/buildingState/upd",
            data: {...config},
        })
            .submit()
            .then(() => message.success("수정이 완료되었습니다."))
            .catch((e) => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
                console.log(e);
            });
    };

    return {update};
};

export {useStoreUpdate};
