import React, {Dispatch, SetStateAction, useEffect, useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {
    CheckList,
    CheckListFormDetail,
    NewCheckListForm,
    NewCheckListFormResponse,
    UpdateCheckListForm,
    UpdateCheckListFormResponse,
} from "./use-checklist-form.interface";
import {LegalForm, ReportCategory} from "./use-legal-form.interface";

function useLegalForms(type: string) {
    const [searchType, setSearchType] = useState<Array<{key: string | number; value: number | null; label: string}>>(
        [],
    );
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<
            {
                category: string;
            },
            Array<LegalForm>
        >({
            url: "/reportCheck/userReportList",
            params: {
                category: type === "legal" ? "LEGAL" : "CHECK",
            },
        });
    }, [type]);

    useEffect(() => {
        setLoading(true);
        request
            .submit()
            .then((response) => {
                setSearchType([
                    {
                        key: "전체",
                        value: null,
                        label: "전체",
                    },
                    ...response.data.map((list) => {
                        return {
                            key: list.reportInfoId,
                            value: list.reportInfoId,
                            label: list.reportNm,
                        };
                    }),
                ]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [request]);

    return {
        loading,
        searchTypes: searchType,
    };
}

function useCheckListForms(buildingId: number, type: string) {
    const [searchType, setSearchType] = useState<Array<{key: string; value: string | null; label: string}>>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const request = useMemo(() => {
        return httpRequest<undefined, CheckListFormDetail>({
            url: `/buildings/${buildingId}/checks-form`,
            params: {
                limit: 500,
                offset: 1,
                category: type === "normal" ? "NORMAL" : "ADD",
            },
        });
    }, [type]);

    useEffect(() => getList(), [request]);

    const getList = () => {
        setLoading(true);
        request
            .submit()
            .then((response) => {
                setSearchType([
                    {
                        key: "전체",
                        value: null,
                        label: "전체",
                    },
                    ...Array.from(new Set(response.data.checkFormList.map((list) => list.checkFormName))).map(
                        (checkFormName) => ({
                            key: checkFormName,
                            value: checkFormName,
                            label: checkFormName,
                        }),
                    ),
                ]);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {
        loading,
        refresh: getList,
        searchTypes2: searchType,
    };
}

export {useCheckListForms, useLegalForms};
export type {CheckList, CheckListFormDetail, ReportCategory};
