import React, {Fragment, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import classNames from "classnames/bind";

import {CnTable} from "~/components/cn-table/cn-table";
import {PageTitle} from "~/components/page-title";
import {useLegalForms} from "~/data/check/form/use-legal-form";
import {LegalForm} from "~/data/check/form/use-legal-form.interface";
import {UpdateFormModal} from "~/pages/check/form/legal/update/update-form.modal";
import {useCheckDev} from "~/utills/useCheckDev";

import styles from "./legal-form.module.scss";

const cx = classNames.bind(styles);

const LegalFormPage = () => {
    const perPage = 50;
    const [page, setPage] = useState(1);
    const [modalData, setModalData] = useState<LegalForm | null>(null);

    const {type} = useParams<{type: "legal" | "checklist"}>();
    const isDev = useCheckDev();

    const title = useMemo(() => {
        if (type === "checklist") return "체크리스트";
        else if (type === "legal") return "법정점검";
    }, [type]);

    const {legalForms, loading, totalCount} = useLegalForms(type as string);

    const handleModalClose = () => setModalData(null);

    return (
        <Fragment>
            {modalData && <UpdateFormModal form={modalData as LegalForm} onClose={handleModalClose} />}
            <div>
                <PageTitle>{title} 양식관리</PageTitle>
                <div className={cx("table-container")}>
                    <CnTable
                        totalCount={totalCount}
                        onPageChange={(page) => setPage(page)}
                        perPage={totalCount}
                        page={1}
                        dataSource={legalForms.map((row: any) => {
                            row["key"] = row.reportId;
                            return row;
                        })}
                        pagination={false}
                        loading={loading}
                        onRow={(data) => ({
                            onClick: () => setModalData(data),
                        })}
                        columns={[
                            {
                                title: "No",
                                key: "index",
                                render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                                align: "center",
                            },
                            {
                                title: "양식제목",
                                dataIndex: "reportNm",
                                key: "reportNm",
                            },
                            // {
                            //     title: "생성자",
                            //     dataIndex: "createUser",
                            //     key: "createUser",
                            //     align: "center",
                            // },
                            // {
                            //     title: "생성일시",
                            //     dataIndex: "createDate",
                            //     key: "createDate",
                            // },
                            // {
                            //     title: "수정자",
                            //     dataIndex: "updateUser",
                            //     key: "updateUser",
                            //     align: "center",
                            // },
                            // {
                            //     title: "수정일시",
                            //     dataIndex: "updateDate",
                            //     key: "updateDate",
                            // },
                        ]}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export {LegalFormPage};
