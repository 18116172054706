import {useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";

import {WorkPlanDayListResponse, WorkPlanWeekListResponse} from "./work-plan.interface";

const useWorkPlanList = (date: string) => {
    const [week, setWeek] = useState<WorkPlanWeekListResponse[]>([]);
    const [day, setDay] = useState<WorkPlanDayListResponse | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const submitWeek = async () => {
        setIsLoading(true);
        return await httpRequest<{startDate: string}, WorkPlanWeekListResponse[]>({
            method: "GET",
            url: "/dailyWork/DailyWorkList",
            params: {
                startDate: date,
            },
        })
            .submit()
            .then((res) => {
                setWeek(res.data);
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    const submitDay = async (day: string) => {
        setIsLoading(true);
        return await httpRequest<{day: string}, WorkPlanDayListResponse>({
            method: "GET",
            url: "/dailyWork/day",
            params: {
                day,
            },
        })
            .submit()
            .then((res) => {
                setDay(res.data);
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false));
    };

    return {submitWeek, week, submitDay, day, isLoading, error};
};

export {useWorkPlanList};
