import {useMemo, useState} from "react";
import {message} from "antd";
import Swal from "sweetalert2";

import {BuildingListResponse, BuildingRequest} from "~/data/building/use-building-list.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useBuildingList = () => {
    const [response, setResponse] = useState<null | BuildingListResponse>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<any, BuildingListResponse>({
            method: "GET",
            url: "/buildings",
        });
    }, []);

    const submit = async () => {
        try {
            setLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            setError(e);
        } finally {
            setLoading(false);
        }
    };

    const register = async (config: BuildingRequest) => {
        setLoading(true);
        return await httpRequest<BuildingRequest, any>({
            method: "POST",
            url: "/buildings",
            data: config,
        })
            .submit()
            .then(() => {
                message.success("등록되었습니다.");
            })
            .catch((err) => {
                setError(err);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setLoading(false));
    };

    const update = async (config: BuildingRequest, buildingId: number) => {
        setLoading(true);
        return await httpRequest<BuildingRequest, any>({
            method: "PUT",
            url: `/buildings/${buildingId}`,
            data: config,
        })
            .submit()
            .then(() => {
                message.success("수정되었습니다.");
            })
            .catch((err) => {
                setError(err);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setLoading(false));
    };

    return {submit, buildingList: response, register, update, loading, error};
};

export {useBuildingList};
