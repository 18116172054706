import {useMemo, useState} from "react";
import {message} from "antd";
import Swal from "sweetalert2";

import {HrListRequest, HrListResponse, HrRegisterRequest, HrUpdateRequest} from "~/data/hr/use-hr-list.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useHrList = (config: HrListRequest) => {
    const [response, setResponse] = useState<HrListResponse[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<HrListRequest, HrListResponse[]>({
            method: "GET",
            url: "/human/list",
            params: {
                positionCode: config.positionCode === "" ? null : config.positionCode,
                name: config.name === "" ? null : config.name,
                pageNo: config.pageNo,
                workTypeId: config.workTypeId,
            },
        });
    }, [config]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    return {hrList: response, submit, isLoading, error};
};

const useHrHandler = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const register = async ({name, positionCode, workTypeId}: HrRegisterRequest) => {
        setIsLoading(true);
        return await httpRequest<HrRegisterRequest, null>({
            method: "POST",
            url: "/human/create",
            data: {
                name,
                positionCode,
                workTypeId,
            },
        })
            .submit()
            .then(() => {
                message.success("등록되었습니다.");
            })
            .catch((e) => {
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    };
    const update = async ({hrId, name, positionCode, workTypeId}: HrUpdateRequest) => {
        setIsLoading(true);
        return await httpRequest<HrUpdateRequest, null>({
            method: "POST",
            url: "/human/update",
            data: {
                hrId,
                name,
                positionCode,
                workTypeId,
            },
        })
            .submit()
            .then(() => {
                message.success("수정되었습니다.");
            })
            .catch((e) => {
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    };

    const remove = async (selectedList: number[]) => {
        setIsLoading(true);
        return await httpRequest<number[], null>({
            method: "POST",
            url: "/human/delete",
            data: selectedList,
        })
            .submit()
            .then(async () => {
                message.success("삭제되었습니다.");
            })
            .catch((e) => {
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    };

    return {error, isLoading, register, update, remove};
};

export {useHrHandler, useHrList};
