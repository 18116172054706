import {useEffect, useState} from "react";

interface ILocation {
    latitude: number;
    longitude: number;
}

export const useGeoLocation = () => {
    const [location, setLocation] = useState<ILocation>();
    const [error, setError] = useState("");

    const handleSuccess = (pos: GeolocationPosition) => {
        const {latitude, longitude} = pos.coords;

        setLocation({
            latitude,
            longitude,
        });
    };

    const handleError = (err: GeolocationPositionError) => {
        setError(err.message);
    };

    useEffect(() => {
        const {geolocation} = navigator;

        if (!geolocation) {
            setError("Geolocation is not supported.");
            return;
        }

        geolocation.getCurrentPosition(handleSuccess, handleError);
    }, []);

    return {location, error};
};
