import {useEffect, useState} from "react";
import {faMinus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Checkbox, DatePicker, Input, InputNumber, Select} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import {MergeGridItem} from "~/components/merge/merge-grid-item";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {useStoreCommonData} from "~/data/store/use-store-status";
import {useStoreUpdate} from "~/data/store/use-store-update";
import {useShopCascader} from "~/hooks/use-shop-cascader/use-shop-cascader";
import {StoreStatusModalInput, StoreStatusModalProps} from "~/pages/store/modal/store-status.modal.interface";
import {formatCost} from "~/utills/formatCost";

import styles from "./store-status.modal.module.scss";

const cx = classNames.bind(styles);

const StoreStatusModal = ({close, data, refresh}: StoreStatusModalProps) => {
    const [inputs, setInputs] = useState<StoreStatusModalInput>({
        landArea: null,
        operationArea: null,
        facilityPartnerCd: null,
        serviceOpeningTypeCode: null,
        serviceOpenTime: null,
        serviceCloseTime: null,
        dutyCloseState: "",
        dutyCloseState2: "",
        energyManageTypeCd: null,
        isSun: "N",
        closeYn: "N",
        isBudgetIncluded: "N",
        sectorId: null,
        trainerSectorId: null,
    });

    const {partner, serviceOpeningTypeCode, energyManage} = useStoreCommonData();
    const {commonData} = useShopCascader(0);
    const {update} = useStoreUpdate({
        buildingId: data.buildingId,
        buildingName: data.buildingName,
        closeYn: inputs.closeYn,
        isBudgetIncluded: inputs.isBudgetIncluded,
        sectorId: inputs.sectorId,
        trainerSectorId: inputs.trainerSectorId,
        dutyCloseState: inputs.dutyCloseState,
        dutyCloseState2: inputs.dutyCloseState2,
        energyManageTypeCd: inputs.energyManageTypeCd,
        isSun: inputs.isSun,
        facilityPartnerCd: inputs.facilityPartnerCd,
        serviceOpeningTypeCode: inputs.serviceOpeningTypeCode,
        serviceOpenTime: inputs.serviceOpenTime?.format("HH:mm") ?? null,
        serviceCloseTime: inputs.serviceCloseTime?.format("HH:mm") ?? null,
        energyUseItem: null,
        heatInfo: null,
        heatName: null,
        landArea: inputs.landArea,
        operationArea: inputs.operationArea,
        startOpenDate: data.startOpenDate,
    });

    useEffect(() => {
        setInputs({
            landArea: data.landArea,
            operationArea: data.operationArea,
            facilityPartnerCd: data.facilityPartnerCd,
            serviceOpeningTypeCode: data.serviceOpeningTypeCode,
            serviceOpenTime: data.serviceOpenTime ? dayjs(data.serviceOpenTime, "HH:mm:ss") : null,
            serviceCloseTime: data.serviceCloseTime ? dayjs(data.serviceCloseTime, "HH:mm:ss") : null,
            dutyCloseState: data.dutyCloseState,
            dutyCloseState2: data.dutyCloseState2,
            energyManageTypeCd: data.energyManageTypeCd,
            isSun: data.isSun ?? "N",
            closeYn: data.closeYn ?? "N",
            isBudgetIncluded: data.isBudgetIncluded ?? "N",
            sectorId: data.sectorId,
            trainerSectorId: data.trainerSectorId,
        });
    }, [data]);

    const handleConfirm = async () => {
        if (inputs.sectorId === 12 && inputs.trainerSectorId === 20 && inputs.closeYn === "N") {
            Swal.fire({
                text: "폐점 여부를 체크해주세요",
                confirmButtonText: "확인",
            });
        } else {
            close();
            await update();
            setTimeout(refresh, 100);
        }
    };

    return (
        <SideModal onClose={close} nonePrevent confirmLabel={"저장하기"} onConfirm={handleConfirm}>
            <PageTitle>점포현황 상세</PageTitle>
            <div className={cx("detail-content")}>
                <MergeGridItem name={"지점"}>{data.buildingName}</MergeGridItem>
                <MergeGridItem name={"오픈연차"}>{data.annual}</MergeGridItem>
                <MergeGridItem name={"연면적(평)"}>
                    <InputNumber
                        className={cx("input")}
                        controls={false}
                        formatter={(value) => formatCost(value)}
                        placeholder={"연면적 입력"}
                        value={inputs.landArea}
                        onChange={(e) => setInputs((prev) => ({...prev, landArea: e}))}
                    />
                </MergeGridItem>
                <MergeGridItem name={"영업면적(평)"}>
                    <InputNumber
                        className={cx("input")}
                        controls={false}
                        formatter={(value) => formatCost(value)}
                        placeholder={"영업면적 입력"}
                        value={inputs.operationArea}
                        onChange={(e) => setInputs((prev) => ({...prev, operationArea: e}))}
                    />
                </MergeGridItem>
                <MergeGridItem name={"파트너사"}>
                    <Select
                        className={cx("input")}
                        placeholder={"파트너사 선택"}
                        value={inputs.facilityPartnerCd}
                        onChange={(e) => setInputs((prev) => ({...prev, facilityPartnerCd: e}))}
                        options={partner.responseData.map((data) => ({
                            label: data.facilityPartnerName,
                            value: data.facilityPartnerCd,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                    />
                </MergeGridItem>
                <MergeGridItem name={"출점형태"}>
                    <Select
                        className={cx("input")}
                        placeholder={"출점형태 선택"}
                        value={inputs.serviceOpeningTypeCode}
                        onChange={(e) => setInputs((prev) => ({...prev, serviceOpeningTypeCode: e}))}
                        options={serviceOpeningTypeCode.map((data) => ({
                            label: data.description,
                            value: data.code,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                    />
                </MergeGridItem>
                <MergeGridItem name={"영업시간"} span extraClassName={"text-left"}>
                    <DatePicker
                        className={cx("time-picker")}
                        picker={"time"}
                        showSecond={false}
                        showNow={false}
                        needConfirm={false}
                        value={inputs.serviceOpenTime}
                        onChange={(e) => setInputs((prev) => ({...prev, serviceOpenTime: e}))}
                    />
                    <FontAwesomeIcon className={cx("icon-arrow")} icon={faMinus} />
                    <DatePicker
                        className={cx("time-picker")}
                        picker={"time"}
                        showSecond={false}
                        showNow={false}
                        needConfirm={false}
                        value={inputs.serviceCloseTime}
                        onChange={(e) => setInputs((prev) => ({...prev, serviceCloseTime: e}))}
                    />
                </MergeGridItem>
                <MergeGridItem
                    name={
                        <div className={cx("duty-title")}>
                            <p>의무 휴무 현황</p>
                            <span>(매월)</span>
                        </div>
                    }
                    span
                    extraClassName={"text-left"}
                >
                    <Input
                        className={cx("duty-input")}
                        placeholder={"의무 휴무일 1"}
                        value={inputs.dutyCloseState}
                        onChange={(e) => setInputs((prev) => ({...prev, dutyCloseState: e.target.value}))}
                    />
                    <FontAwesomeIcon className={cx("icon-arrow")} icon={faMinus} />
                    <Input
                        className={cx("duty-input")}
                        placeholder={"의무 휴무일 2"}
                        value={inputs.dutyCloseState2}
                        onChange={(e) => setInputs((prev) => ({...prev, dutyCloseState2: e.target.value}))}
                    />
                </MergeGridItem>
                <MergeGridItem name={"트레이너 권역"}>
                    <Select
                        className={cx("input")}
                        placeholder={"트레이너 권역 선택"}
                        value={inputs.trainerSectorId}
                        onChange={(e) =>
                            setInputs((prev) => ({
                                ...prev,
                                trainerSectorId: e,
                                sectorId: e === 20 ? 12 : prev.sectorId,
                                closeYn: e === 20 ? "Y" : "N",
                            }))
                        }
                        options={commonData.trainer.map((data) => ({
                            label: data.trainerSectorName,
                            value: data.trainerSectorId,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                    />
                </MergeGridItem>
                <MergeGridItem name={"부문"}>
                    <Select
                        className={cx("input")}
                        placeholder={"부문 선택"}
                        value={inputs.sectorId}
                        onChange={(e) =>
                            setInputs((prev) => ({
                                ...prev,
                                sectorId: e,
                                trainerSectorId: e === 12 ? 20 : prev.trainerSectorId,
                                closeYn: e === 12 ? "Y" : "N",
                            }))
                        }
                        options={commonData.sector.map((data) => ({
                            label: data.sectorName,
                            value: data.sectorId,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                    />
                </MergeGridItem>
                <MergeGridItem name={"에너지 관리형태"}>
                    <Select
                        className={cx("input")}
                        placeholder={"관리형태 선택"}
                        value={inputs.energyManageTypeCd}
                        onChange={(e) => setInputs((prev) => ({...prev, energyManageTypeCd: e}))}
                        options={energyManage.map((data) => ({
                            label: data.description,
                            value: data.code,
                        }))}
                        getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
                    />
                </MergeGridItem>
                <MergeGridItem name={"예산 포함 여부"}>
                    <Checkbox
                        checked={inputs.isBudgetIncluded === "Y"}
                        onChange={(e) =>
                            setInputs((prev) => ({
                                ...prev,
                                isBudgetIncluded: e.target.checked ? "Y" : "N",
                            }))
                        }
                    />
                </MergeGridItem>
                <MergeGridItem name={"태양광 사용 여부"}>
                    <Checkbox
                        checked={inputs.isSun === "Y"}
                        onChange={(e) => setInputs((prev) => ({...prev, isSun: e.target.checked ? "Y" : "N"}))}
                    />
                </MergeGridItem>
                <MergeGridItem name={"폐점 여부"}>
                    <Checkbox
                        checked={inputs.closeYn === "Y"}
                        // disabled={inputs.sectorId === 12 && inputs.trainerSectorId === 20}
                        onChange={(e) =>
                            setInputs((prev) => ({
                                ...prev,
                                closeYn: e.target.checked ? "Y" : "N",
                                sectorId: e.target.checked ? 12 : prev.sectorId,
                                trainerSectorId: e.target.checked ? 20 : prev.trainerSectorId,
                            }))
                        }
                    />
                </MergeGridItem>
            </div>
        </SideModal>
    );
};

export {StoreStatusModal};
