import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {DndContext} from "@dnd-kit/core";
import type {DragEndEvent} from "@dnd-kit/core/dist/types";
import {arrayMove, SortableContext} from "@dnd-kit/sortable";
import {faChevronDown, faChevronUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Checkbox, Input, Radio} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";
import {v4 as uuidV4} from "uuid";

import Button from "~/components/button";
import {SideModal} from "~/components/modal/side-modal";
import {PageTitle} from "~/components/page-title";
import {ChecklistItemForm, useCheckListForms} from "~/data/check/form/use-checklist-form";
import {useUser} from "~/data/user";
import {UpdateFormItem} from "~/pages/check/form/checklist/update/update-form-item";
import {UpdateFormList} from "~/pages/check/form/checklist/update/update-form-list";

import {UpdateFormProps} from "./update-form.interface";

import styles from "./update-form.modal.module.scss";

const cx = classNames.bind(styles);

const {TextArea} = Input;

const UpdateFormModal = ({onClose, form}: UpdateFormProps) => {
    const [isUpdate, setIsUpdate] = useState<boolean>(false);
    const [division, setDivision] = useState<string>(form.division);
    const [name, setName] = useState<string>(form.checkFormName);
    const [note, setNote] = useState<string>(form.note);
    const [items, setItems] = useState<Array<ChecklistItemForm>>(
        form.checkFormItemList.map((data) => ({
            orderNumber: data.orderNumber,
            imageYn: data.imageYn,
            valueYn: data.valueYn,
            checkFormItemId: data.checkFormItemId.toString(),
            checkFormItemName: data.checkFormItemName,
        })),
    );

    const {user} = useUser();
    const {type} = useParams<{type: "checklist" | "extra" | "normal"}>();
    const {updateCheckListRequest} = useCheckListForms(user!.buildingId, type as string);

    const title = useMemo(() => {
        if (type === "checklist") return "체크리스트";
        else if (type === "extra") return "추가점검";
        else if (type === "normal") return "일반점검";
    }, [type]);

    const addChecklist = () => {
        setItems([
            ...items,
            {
                orderNumber: items.length + 1,
                imageYn: false,
                valueYn: false,
                checkFormItemName: "",
                checkFormItemId: uuidV4(),
            },
        ]);
    };

    const handleMove = (index: number, n: number) => {
        const newIndex = index + n;
        if (newIndex < 0 || newIndex >= items.length) {
            return;
        }
        const newItems = [...items];
        [newItems[index], newItems[newIndex]] = [newItems[newIndex], newItems[index]];
        setItems(newItems);
    };

    const handleSubmit = () => {
        if (isUpdate) {
            const set = new Set(items.map((item: ChecklistItemForm) => item.checkFormItemName));
            if (set.size === items.length) {
                Swal.fire({
                    title: "저장하시겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "저장하기",
                    cancelButtonText: "취소하기",
                }).then((result) => {
                    if (result.isConfirmed) {
                        updateCheckListRequest(
                            {
                                division: division,
                                checkFormName: name,
                                note: note,
                                checkFormItemList: items.map((data) => ({
                                    orderNumber: data.orderNumber,
                                    imageYn: data.imageYn,
                                    valueYn: data.valueYn,
                                    checkFormItemName: data.checkFormItemName,
                                })),
                            },
                            form.checkFormId,
                        )
                            .submit()
                            .then((res) => Swal.fire("저장되었습니다."))
                            .catch(() => Swal.fire("오류가 발생했습니다.\n잠시 후 다시 시도해 주세요."))
                            .finally(() => {
                                onClose();
                            });
                    }
                });
            } else {
                Swal.fire({
                    title: "중복된 항목이 있습니다",
                    text: "제목이 중복되지 않게 작성해 주시기 바랍니다.",
                    confirmButtonText: "확인",
                });
            }
        }
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const {active, over} = event;
        if (over?.id) {
            if (active.id !== over.id) {
                setItems((items) => {
                    const oldIndex = items.findIndex((t) => t.checkFormItemId === active.id);
                    const newIndex = items.findIndex((t) => t.checkFormItemId === over.id);
                    return arrayMove(items, oldIndex, newIndex);
                });
            }
        }
    };

    return (
        <SideModal
            onClose={onClose}
            size="normal"
            confirmLabel={isUpdate ? "저장하기" : "수정하기"}
            onConfirm={isUpdate ? handleSubmit : () => setIsUpdate(true)}
        >
            <PageTitle>{form.checkFormName + (isUpdate ? " 수정하기" : "")}</PageTitle>

            {!isUpdate ? (
                <>
                    <div className={cx("description")}>
                        <p>{form.division}</p>
                        <p>{form.note}</p>
                    </div>
                    <ul className={cx("list")}>
                        {form.checkFormItemList.map((item, index) => (
                            <li
                                key={item.checkFormItemId}
                                className={cx("item")}
                            >{`${index + 1}. ${item.checkFormItemName}`}</li>
                        ))}
                    </ul>
                </>
            ) : (
                <>
                    <div className={cx("description")}>
                        <p>{title} 수정하기.</p>
                    </div>
                    <div className={cx("form-container")}>
                        <Input
                            placeholder="제목"
                            id="checkFormName"
                            className={cx("name")}
                            defaultValue={name}
                            onChange={(e) => setName(e.target.value)}
                            disabled={type === "extra"}
                        />
                        <Radio.Group
                            onChange={(e) => setDivision(e.target.value)}
                            defaultValue={division}
                            className={cx("radio-container")}
                        >
                            <Radio value={"일일"}>일일</Radio>
                            <Radio value={"주간"}>주간</Radio>
                            <Radio value={"야간"}>야간</Radio>
                            <Radio value={"주간+야간"}>주간+야간</Radio>
                        </Radio.Group>
                        <TextArea
                            placeholder="기타"
                            id="error-message"
                            className={cx("note")}
                            defaultValue={note}
                            onChange={(e) => setNote(e.target.value)}
                        />

                        <table className={cx("table")}>
                            <colgroup>
                                <col width="5%" />
                                <col width="5%" />
                                <col width="5%" />
                                <col width="5%" />
                                <col width="70%" />
                                <col width="10%" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>순번</th>
                                    <th>이미지</th>
                                    <th>값</th>
                                    <th>제목</th>
                                    <th>삭제</th>
                                </tr>
                            </thead>
                            <DndContext onDragEnd={handleDragEnd}>
                                <SortableContext
                                    items={items.map((item) => {
                                        return {
                                            ...item,
                                            id: item.checkFormItemId,
                                        };
                                    })}
                                >
                                    <UpdateFormList>
                                        {items.map((item, index) => (
                                            <UpdateFormItem item={item} key={item.checkFormItemId}>
                                                <Fragment key={item.checkFormItemId}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <Checkbox
                                                            key={uuidV4()}
                                                            onChange={(value) => {
                                                                setItems((prevItems) => {
                                                                    const newItems = [...prevItems];
                                                                    newItems[index].imageYn = value.target.checked;
                                                                    return newItems;
                                                                });
                                                            }}
                                                            checked={item.imageYn}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Checkbox
                                                            key={uuidV4()}
                                                            onChange={(value) => {
                                                                setItems((prevItems) => {
                                                                    const newItems = [...prevItems];
                                                                    newItems[index].valueYn = value.target.checked;
                                                                    return newItems;
                                                                });
                                                            }}
                                                            checked={item.valueYn}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Input
                                                            placeholder="제목"
                                                            id="checkFormName"
                                                            className={cx("title")}
                                                            onChange={(value) => {
                                                                setItems((prevItems) => {
                                                                    const newItems = [...prevItems];
                                                                    newItems[index].checkFormItemName =
                                                                        value.target.value;
                                                                    return newItems;
                                                                });
                                                            }}
                                                            defaultValue={item.checkFormItemName}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            label={"삭제"}
                                                            color="signature"
                                                            size="small"
                                                            onClick={() => {
                                                                setItems(items.filter((_, idx) => idx !== index));
                                                            }}
                                                        />
                                                    </td>
                                                </Fragment>
                                            </UpdateFormItem>
                                        ))}
                                    </UpdateFormList>
                                </SortableContext>
                            </DndContext>
                        </table>
                        <Button label={"추가하기"} color="primary" onClick={addChecklist} />
                    </div>
                </>
            )}
        </SideModal>
    );
};

export {UpdateFormModal};
