import React from "react";
import {useDraggable} from "@dnd-kit/core";
import {faFile} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";

import {useUser} from "~/data/user";

import {FileProps} from "./file.interface";

import styles from "./file.module.scss";

const cx = classNames.bind(styles);

const File = (props: FileProps) => {
    const {selected, onClick, fileData, onOpenFile} = props;
    const {user} = useUser();

    const {attributes, listeners, setNodeRef, transform} = useDraggable({
        id: `file-${fileData.fileBoardSeq}`,
        data: fileData,
    });

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(fileData);
    };
    const handleDoubleClick = () => {
        onOpenFile(fileData.fileBoardSeq);
    };

    return (
        <div
            onDoubleClick={handleDoubleClick}
            // className={cx("folder", {selected})}
            className={cx("folder")}
            onClick={handleClick}
            ref={(ref) => {
                user?.division === "MEMBER" && setNodeRef(ref);
            }}
            {...listeners}
            {...attributes}
        >
            <div className={cx("icon-container")}>
                <FontAwesomeIcon icon={faFile} className={cx("folder-icon")} />
            </div>
            <div className={cx("text-container")}>
                <p>{fileData.title}</p>
            </div>
            <div className={cx("select-container")}></div>
        </div>
    );
};

export {File};
