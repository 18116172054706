import {useCallback, useEffect, useState} from "react";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CnInput} from "~/components/cn-input";
import {CnSelect} from "~/components/cn-select";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useHrHandler, useHrList} from "~/data/hr/use-hr-list";
import {HrListResponse} from "~/data/hr/use-hr-list.interface";
import {useCommonWorkPlan} from "~/data/work-plan/use-common-work-plan";
import {useDetailCodes} from "~/hooks/use-detail-codes/use-detail-codes";
import {HrSettingPageInputType} from "~/pages/hr/hr-setting.page.interface";
import {HrSettingModal} from "~/pages/hr/modal/hr-setting.modal";
import {WorkPlanWorkModal} from "~/pages/work-plan/modal/work-plan-work.modal";

import styles from "./hr-setting.page.module.scss";

const cx = classNames.bind(styles);

const HrSettingPage = () => {
    const perPage = 50;
    const [page, setPage] = useState<number>(1);
    const [selectedRows, setSelectedRows] = useState<number[]>([]);
    const [modal, setModal] = useState<boolean>(false);
    const [teamModal, setTeamModal] = useState<boolean>(false);
    const [modalInfo, setModalInfo] = useState<HrListResponse | null>(null);
    const [inputs, setInputs] = useState<HrSettingPageInputType>({
        positionCode: "",
        name: "",
        workTypeId: 0,
    });
    const [ranks] = useDetailCodes("LC029");
    const {submitList, workList} = useCommonWorkPlan();
    const {hrList, submit} = useHrList({
        positionCode: inputs.positionCode,
        name: inputs.name,
        pageNo: page,
        workTypeId: inputs.workTypeId,
    });
    const {remove} = useHrHandler();

    const handleReset = useCallback(() => {
        setInputs({
            positionCode: "",
            name: "",
            workTypeId: 0,
        });
    }, []);

    const handleInquiry = async () => {
        await submit();
    };
    const handleRemove = async () => {
        Swal.fire({
            text: "삭제하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then(async (info) => {
            if (info.isConfirmed) {
                await remove(selectedRows);
                setTimeout(submit, 500);
            }
        });
    };

    useEffect(() => {
        submitList();
        submit();
    }, []);

    return (
        <div>
            {teamModal && <WorkPlanWorkModal isShow={teamModal} refresh={submit} close={() => setTeamModal(false)} />}
            {modal && (
                <HrSettingModal
                    isShow={modal}
                    refresh={submit}
                    close={() => setModal(false)}
                    ranks={ranks}
                    workList={workList}
                    hrInfo={modalInfo}
                />
            )}
            <PageTitle>인사 설정</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnSelect
                        label={"소속"}
                        value={inputs.workTypeId}
                        onChange={(e) => setInputs((prev) => ({...prev, workTypeId: e}))}
                        options={[
                            {
                                label: "전체",
                                value: 0,
                            },
                            ...workList.map((data) => ({
                                label: data.name,
                                value: data.workTypeId,
                            })),
                        ]}
                    />
                    <CnSelect
                        label={"직책"}
                        value={inputs.positionCode}
                        onChange={(e) => setInputs((prev) => ({...prev, positionCode: e}))}
                        options={[
                            {
                                label: "전체",
                                value: "",
                            },
                            ...ranks.map((data) => ({
                                label: data.description,
                                value: data.code,
                            })),
                        ]}
                    />
                    <CnInput
                        label={"이름"}
                        value={inputs.name}
                        onChange={(e) => setInputs((prev) => ({...prev, name: e.target.value}))}
                        placeholder={"이름 검색"}
                    />
                    <div className={cx("button-container")}>
                        <Button label={"조회"} color={"primary"} onClick={handleInquiry} />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <div className={cx("table-title")}>
                            <span className={cx("select-text")}>{selectedRows.length} 건 선택됨</span>
                            <Button label={"삭제"} onClick={handleRemove} />
                            <Button
                                label={"등록"}
                                onClick={() => {
                                    setModalInfo(null);
                                    setModal(true);
                                }}
                            />
                            <Button
                                label={"팀 설정"}
                                onClick={() => {
                                    setTeamModal(true);
                                }}
                            />
                        </div>
                    }
                    page={page}
                    onPageChange={(e) => setPage(e)}
                    perPage={perPage}
                    totalCount={hrList.length}
                    onRow={(record) => ({
                        onClick: (event: React.MouseEvent<HTMLTableRowElement>) => {
                            const clickedElement = event.target as HTMLElement;
                            const closestCheckboxCell = clickedElement.closest(".ant-table-selection-column");
                            if (closestCheckboxCell) {
                                return;
                            } else {
                                setModalInfo(record);
                                setModal(true);
                            }
                        },
                    })}
                    rowKey={"hrId"}
                    pagination={false}
                    rowSelection={{
                        selectedRowKeys: selectedRows,
                        onChange: (selectedRowKeys: React.Key[]) => {
                            setSelectedRows(selectedRowKeys as number[]);
                        },
                    }}
                    dataSource={hrList}
                    columns={[
                        {
                            title: "No",
                            render: (_, __, index) => index + 1 + (page - 1) * perPage,
                            width: "4%",
                        },
                        {
                            title: "소속",
                            dataIndex: "teamName",
                            key: "teamName",
                            align: "center",
                            render: (value) => (value ? value : "-"),
                        },
                        {
                            title: "직급",
                            dataIndex: "positionName",
                            key: "positionName",
                            align: "center",
                            render: (value) => (value ? value : "-"),
                        },
                        {
                            title: "이름",
                            dataIndex: "name",
                            key: "name",
                            align: "center",
                        },
                        {
                            title: "최종 수정일",
                            dataIndex: "updateDate",
                            key: "updateDate",
                            align: "center",
                            render: (value) => (value ? value : "-"),
                        },
                        {
                            title: "등록일",
                            dataIndex: "createDate",
                            key: "createDate",
                            align: "center",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {HrSettingPage};
