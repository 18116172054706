import React, {Fragment, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {ColumnsType} from "antd/es/table";
import {Toast} from "antd-mobile";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {CnTable} from "~/components/cn-table/cn-table";
import {PageTitle} from "~/components/page-title";
import {CheckList, useCheckListForms} from "~/data/check/form/use-checklist-form";
import {useUser} from "~/data/user";
import {httpRequest} from "~/fetch/common/http-request";

import {NewFormModal} from "./new/new-form.modal";
import {UpdateFormModal} from "./update/update-form.modal";

import styles from "./checklist-form.module.scss";

const cx = classNames.bind(styles);

const ChecklistFormPage = () => {
    const perPage = 50;
    const [page, setPage] = useState(1);
    const [modalData, setModalData] = useState<CheckList | null>(null);
    const [modalNew, setModalNew] = useState<boolean>(false);

    const {type} = useParams<{type: "checklist" | "extra" | "normal"}>();

    const title = useMemo(() => {
        if (type === "checklist") return "체크리스트";
        else if (type === "extra") return "일반점검";
        else if (type === "normal") return "추가점검";
    }, [type]);

    const {user} = useUser();

    const {checklistForms, loading, totalCount, refresh} = useCheckListForms(user!.buildingId, type as string);

    const handleModalClose = () => {
        refresh();
        setModalData(null);
    };

    const handleNewModalClose = () => {
        refresh();
        setModalNew(false);
    };

    const handleDelete = (row: any) => {
        Swal.fire({
            title: "삭제하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "삭제하기",
            cancelButtonText: "돌아가기",
        }).then((result) => {
            if (result.isConfirmed) {
                httpRequest({
                    url: `/buildings/${user!.buildingId}/checks-form/${row.checkFormId}`,
                    method: "DELETE",
                })
                    .submit()
                    .then(() => {
                        refresh();
                        Toast.show("삭제되었습니다");
                    });
            }
        });
    };

    const cols: ColumnsType = useMemo(() => {
        const col: ColumnsType = [
            {
                title: "No",
                key: "index",
                render: (_, __, index) => Number((page - 1) * perPage + index + 1).toLocaleString(),
                align: "center",
            },
            {
                title: "양식제목",
                dataIndex: "checkFormName",
                key: "checkFormName",
            },
            {
                title: "구분",
                dataIndex: "division",
                key: "division",
                align: "center",
            },
            {
                title: "기타",
                dataIndex: "note",
                key: "note",
            },
        ];

        if (type === "normal") {
            col.push({
                title: "삭제",
                dataIndex: "checkFormName",
                key: "checkFormName",
                align: "center",
                render: (data, row) => (
                    <Button
                        color={"primary"}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleDelete(row);
                        }}
                        label={"삭제"}
                    />
                ),
            });
        }

        return col;
    }, [type]);

    return (
        <Fragment>
            {modalData && <UpdateFormModal form={modalData} onClose={handleModalClose} />}
            {modalNew && <NewFormModal onClose={handleNewModalClose} />}
            <PageTitle>{title} 양식관리</PageTitle>
            <div className={cx("contents-container")}>
                <div className={cx("button-container")}>
                    {type === "normal" && (
                        <Button color="signature" label={`${title} 추가`} onClick={() => setModalNew(true)}></Button>
                    )}
                </div>
                <div className={cx("table-container")}>
                    <CnTable
                        totalCount={totalCount}
                        onPageChange={(page) => setPage(page)}
                        perPage={totalCount}
                        page={1}
                        dataSource={checklistForms.map((row: any) => {
                            row["key"] = row.checkFormId;
                            return row;
                        })}
                        pagination={false}
                        loading={loading}
                        onRow={(data) => ({
                            onClick: () => setModalData(data),
                        })}
                        columns={cols}
                    />
                </div>
            </div>
        </Fragment>
    );
};

export {ChecklistFormPage};
