import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {DatePicker, Select} from "antd";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import Button from "~/components/button/button";
import {CnInput} from "~/components/cn-input/cn-input";
import {CnTable} from "~/components/cn-table/cn-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {FilterItem} from "~/components/filter-item";
import {PageTitle} from "~/components/page-title/page-title";
import {useMergeBoardList} from "~/data/merge/board/use-board";
import {useShopCascader} from "~/hooks/use-shop-cascader/use-shop-cascader";
import {MergeBoardAnswerModal} from "~/pages/merge/answer/merge-board-answer.modal";
import {MergeBoardDetailModal} from "~/pages/merge/detail/merge-board-detail.modal";
import {MergeBoardRegisterModal} from "~/pages/merge/register/merge-board-register.modal";
import {useLocalStorage} from "~/utills/useLocalStorage";

import styles from "./merge-board.module.scss";
import filterStyles from "~/components/filter-item/filter-item.module.scss";

import "dayjs/locale/ko";

const cx = classNames.bind(styles);
const filterCx = classNames.bind(filterStyles);

const MergeBoardPage = () => {
    const perPage = 50;
    const {title} = useParams();

    const [date, setDate] = useState<{year: Dayjs | null; month: Dayjs | null}>({
        year: dayjs(),
        month: title ? null : dayjs(),
    });
    const [division, setDivision] = useState<"MEMBER" | "MANAGER" | "EMPLOYEE" | "">("");
    const [isComplete, setIsComplete] = useState<"Y" | "N" | "">("N");
    const [mergeType, setMergeType] = useState<"EXCEL" | "DATA" | "FILE" | "">(""); // "":전체
    const [searchTitle, setSearchTitle] = useState<string | null>(title ? title : null); // 제목 검색
    const [isShowModal, setIsShowModal] = useState<boolean>(false); // 상세 모달
    const [isShowRegisterModal, setIsShowRegisterModal] = useState<boolean>(false); // 게시물 등록 모달
    const [noticeMergeId, setNoticeMergeId] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const {ShopCascader, selected, setSelected} = useShopCascader(200);

    const [submit, response, isLoading] = useMergeBoardList({
        isComplete: isComplete,
        mergeType: mergeType,
        year: date.year?.format("YYYY") ?? null,
        month: date.month?.format("MM") ?? null,
        startDate: null,
        endDate: null,
        title: searchTitle,
        pageNo: page,
        recordPerPage: perPage,
        selected,
    });

    dayjs.locale("ko");

    const {setItem, getItem} = useLocalStorage();

    // 계정 권한
    useEffect(() => {
        setDivision(JSON.parse(getItem("LM_USER")!).division);
    }, [getItem]);

    useEffect(() => {
        window.scrollTo(0, 0);
        submit();
    }, [page]);

    const handleSubmit = () => {
        setPage(1);
        submit();
    };
    const handleResetFilter = useCallback(() => {
        setPage(1);
        setDate({year: dayjs(), month: dayjs()});
        setIsComplete("");
        setMergeType("");
        setSearchTitle(null);
        setSelected(["ALL"]);
    }, []);

    const handleDetailModal = (data: number) => {
        setNoticeMergeId(data);
        setIsShowModal(true);
    };
    const handleCreateModal = () => {
        setIsShowRegisterModal(true);
    };

    return (
        <div>
            {isShowRegisterModal && (
                <MergeBoardRegisterModal submit={submit} close={() => setIsShowRegisterModal(false)} />
            )}
            {isShowModal &&
                (division === "MEMBER" ? (
                    <MergeBoardDetailModal
                        submit={submit}
                        noticeMergeId={noticeMergeId}
                        close={() => setIsShowModal(false)}
                    />
                ) : (
                    <MergeBoardAnswerModal
                        submit={submit}
                        noticeMergeId={noticeMergeId}
                        close={() => setIsShowModal(false)}
                    />
                ))}
            <PageTitle>취합 게시판</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <FilterItem label={"연도"}>
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "90px"}}
                            status={date.year === null ? "error" : ""}
                            value={date.year}
                            onChange={(e) => {
                                if (e === null) {
                                    setDate((prev) => ({...prev, year: e, month: null, quarter: null}));
                                }
                                setDate((prev) => ({...prev, year: e}));
                            }}
                        />
                    </FilterItem>
                    <FilterItem label={"월"}>
                        <DatePicker
                            placeholder={"월 선택"}
                            className={filterCx("filter-datepicker")}
                            popupClassName="no-header"
                            picker={"month"}
                            disabled={date.year === null}
                            format={"MM"}
                            style={{width: "90px"}}
                            value={date.month}
                            onChange={(e) => setDate((prev) => ({...prev, month: e, quarter: null}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                        />
                    </FilterItem>
                    <FilterItem label={"부문/트레이너권역"}>
                        <ShopCascader />
                    </FilterItem>
                    <FilterItem label={"완료여부"}>
                        <Select
                            style={{width: "90px"}}
                            placeholder={"완료여부"}
                            onChange={(value) => setIsComplete(value)}
                            value={isComplete}
                            options={[
                                {
                                    value: "",
                                    label: "전체",
                                },
                                {
                                    value: "Y",
                                    label: "완료",
                                },
                                {
                                    value: "N",
                                    label: "미완료",
                                },
                            ]}
                        />
                    </FilterItem>
                    <FilterItem label={"구분"}>
                        <Select
                            style={{width: "120px"}}
                            placeholder={"구분선택"}
                            onChange={(value) => setMergeType(value)}
                            value={mergeType}
                            options={[
                                {
                                    value: "",
                                    label: "전체",
                                },
                                {
                                    value: "EXCEL",
                                    label: "엑셀 취합",
                                },
                                {
                                    value: "FILE",
                                    label: "파일 취합",
                                },
                                {
                                    value: "DATA",
                                    label: "데이터 취합",
                                },
                            ]}
                        />
                    </FilterItem>
                    <CnInput
                        label="제목"
                        placeholder="제목 입력"
                        value={searchTitle ? searchTitle : ""}
                        onChange={(value) => setSearchTitle(value.target.value)}
                        allowClear={true}
                    />
                    <div className={cx("button-container")}>
                        <Button onClick={handleSubmit} label={"조회"} color={"primary"} />
                        <Button onClick={handleResetFilter} label={"검색조건 초기화"} />
                        {division === "MEMBER" && <Button onClick={handleCreateModal} label={"게시물 등록"} />}
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CnTable
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    loading={isLoading}
                    rowClassName={cx("table-row")}
                    totalCount={response?.allRecordCnt}
                    onRow={(data) => ({
                        onClick: () => {
                            handleDetailModal(data.noticeMergeId);
                        },
                    })}
                    dataSource={response?.resultList}
                    rowKey={"noticeMergeId"}
                    pagination={false}
                    page={page}
                    columns={[
                        {
                            title: "No",
                            key: "noticeMergeId",
                            dataIndex: "noticeMergeId",
                            width: "5%",
                        },
                        {
                            title: "연도",
                            key: "year",
                            dataIndex: "year",
                            width: "5%",
                        },
                        {
                            title: "월",
                            key: "month",
                            dataIndex: "month",
                            width: "5%",
                        },
                        {
                            title: "취합기간",
                            key: "mergePeriod",
                            dataIndex: "mergePeriod",
                            width: "17%",
                        },
                        {
                            title: "중요도",
                            dataIndex: "important",
                            key: "important",
                            width: "5%",
                            render: (value) => <span> {["-", "★", "★★", "★★★"][value] || "-"}</span>,
                        },
                        {
                            title: "대상",
                            key: "targetName",
                            dataIndex: "targetName",
                            ellipsis: true,
                            width: "7%",
                        },
                        {
                            title: "제목",
                            key: "title",
                            dataIndex: "title",
                            width: "20%",
                            ellipsis: true,
                        },
                        {
                            title: "담당자",
                            key: "managerName",
                            dataIndex: "manageName",
                        },
                        {
                            title: "대상지점",
                            key: "targetCnt",
                            dataIndex: "targetCnt",
                            width: "6%",
                        },
                        {
                            title: "완료지점",
                            key: "completeCnt",
                            dataIndex: "completeCnt",
                            width: "6%",
                        },
                        {
                            title: "미완료지점",
                            key: "incompleteCnt",
                            dataIndex: "incompleteCnt",
                            width: "7%",
                        },
                        {
                            title: "등록일시",
                            key: "createDate",
                            dataIndex: "createDate",
                            width: "8%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {MergeBoardPage};
