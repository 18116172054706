import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {DndContext, DragOverlay, MouseSensor, useSensor, useSensors} from "@dnd-kit/core";
import type {DragEndEvent} from "@dnd-kit/core/dist/types";
import {faTurnUp} from "@fortawesome/free-solid-svg-icons";
import {Flex} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import Button from "~/components/button";
import {File} from "~/components/drive/file";
import {Folder} from "~/components/drive/folder";
import {PageTitle} from "~/components/page-title";
import {useDrive} from "~/data/drive/use-drive";
import {Category} from "~/data/drive/use-drive.interface";
import {useFiles} from "~/data/drive/use-files";
import {AddFileRequest, File as IFile} from "~/data/drive/use-files.interface";
import {useUser} from "~/data/user";
import {httpRequest} from "~/fetch/common/http-request";
import {AddNewFileModal} from "~/pages/drive/add-new-file.modal";
import {FileDetailModal} from "~/pages/drive/file-detail.modal";

import styles from "./drive.page.module.scss";

const cx = classNames.bind(styles);

const DrivePage = () => {
    const perPage = 5000;
    const [searchParams, setSearchParams] = useSearchParams();
    const targetFolder = useMemo(() => searchParams.get("folder") || 1, [searchParams.get("folder")]);

    const {formattedCategory, rawCategories, refresh, changeParentFolderForFolder, changeParentFolderForFile} =
        useDrive();
    const {loading, submit, fileList, addFile} = useFiles(targetFolder);
    const [page, setPage] = useState<number>(1);
    const [selectedFiles, setSelectedFiles] = useState<Array<number>>([]);
    const [isMultiSelect, setIsMultiSelect] = useState<boolean>(false);
    const [isNewFile, setIsNewFile] = useState<boolean>(false);
    const [isOpenFile, setIsOpenFile] = useState<number | false>(false);

    const [dragFile, setDragFile] = useState<IFile | null>(null);
    const [dragFolder, setDragFolder] = useState<Category | null>(null);

    const displayFolders: Category[] = useMemo(() => {
        function findSubFolders(tree: Category[], parentId: string): Category[] {
            let result: Category[] = [];

            for (const node of tree) {
                if (String(node.id) === String(parentId)) {
                    result = result.concat(node.children || []);
                }
                const subResult = findSubFolders(node.children || [], parentId);
                if (subResult.length > 0) {
                    result = result.concat(subResult);
                }
            }

            return result;
        }

        return findSubFolders(formattedCategory, String(targetFolder));
    }, [formattedCategory, targetFolder]);

    const getParentFolderId = (targetFolder: number) => {
        let target = "";
        rawCategories.some((category) => {
            if (category.id === targetFolder) target = String(category.parent);
            return category.id === targetFolder;
        });

        if (target === "#") target = "";
        return target;
    };

    useEffect(() => {
        setSelectedFiles([]);
        if (targetFolder)
            submit({
                allRecordCnt: 0,
                categoryId: Number(targetFolder),
                pageNo: page,
                recordPerPage: perPage,
                startRecordNo: 0,
                includeSubfolders: false,
            });
    }, [targetFolder]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => (event.ctrlKey || event.metaKey) && setIsMultiSelect(true);
        const handleKeyUp = (event: KeyboardEvent) =>
            (event.key === "Control" || event.key === "Meta") && setIsMultiSelect(false);
        const handleClick = () => setSelectedFiles([]);

        window.addEventListener("keydown", handleKeyDown);
        window.addEventListener("keyup", handleKeyUp);
        window.addEventListener("click", handleClick);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("keyup", handleKeyUp);
            window.removeEventListener("click", handleClick);
        };
    }, []);

    const handleFileClick = (target: IFile) => {
        setSelectedFiles((files) => {
            if (isMultiSelect) {
                if (files.indexOf(target.fileBoardSeq) > -1) {
                    return [...files.filter((seq) => seq !== target.fileBoardSeq)];
                } else {
                    return [...files, target.fileBoardSeq];
                }
            } else return [target.fileBoardSeq];
        });
    };

    const addFolder = () => {
        Swal.fire({
            title: "폴더 이름을 입력해주세요.",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "추가하기",
            cancelButtonText: "취소",
            showLoaderOnConfirm: true,
            preConfirm: async (folderName: string) => {
                try {
                    const response = await httpRequest<
                        {
                            parent: string | number;
                            text: string;
                        },
                        any
                    >({
                        method: "POST",
                        url: "/fileBoard/setFileCategory",
                        data: {
                            parent: targetFolder,
                            text: folderName,
                        },
                    }).submit();

                    return response;
                } catch (error) {
                    Swal.showValidationMessage(
                        `오류가 발생했습니다.<br />문제가 지속될 경우 관리자에게 문의 바랍니다.`,
                    );
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                refresh();
            }
        });
    };

    const modFolder = (id: number, value: string) => {
        Swal.fire({
            title: "폴더 이름을 입력해주세요.",
            input: "text",
            inputValue: value,
            inputAttributes: {
                autocapitalize: "off",
            },
            showCancelButton: true,
            confirmButtonText: "변경하기",
            cancelButtonText: "취소",
            showLoaderOnConfirm: true,
            preConfirm: async (folderName: string) => {
                try {
                    const response = await httpRequest<
                        {
                            id: string | number;
                            text: string;
                        },
                        any
                    >({
                        method: "PUT",
                        url: "/fileBoard/setModifyFileCategory",
                        data: {
                            id: String(id),
                            text: folderName,
                        },
                    }).submit();

                    return response;
                } catch (error) {
                    Swal.showValidationMessage(
                        `오류가 발생했습니다.<br />문제가 지속될 경우 관리자에게 문의 바랍니다.`,
                    );
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
            if (result.isConfirmed) {
                refresh();
            }
        });
    };

    const delFolder = (id: number, folderName: string) => {
        Swal.fire({
            title: "폴더를 삭제하시겠습니까?",
            text: `[${folderName}]`,
            showCancelButton: true,
            confirmButtonText: "삭제하기",
            cancelButtonText: "취소",
        }).then((result) => {
            if (result.isConfirmed) {
                httpRequest<
                    {
                        id: string | number;
                    },
                    any
                >({
                    method: "DELETE",
                    url: "/fileBoard/setDeleteFileCategory",
                    params: {
                        id: id,
                    },
                })
                    .submit()
                    .then((res) => {
                        refresh();
                        Swal.fire({
                            title: "폴더가 삭제되었습니다.",
                            confirmButtonText: "확인",
                        });
                    })
                    .catch(() => {
                        Swal.fire({
                            title: "오류가 발생했습니다.",
                            text: "문제가 지속될 경우 관리자에 문의하세요.",
                            confirmButtonText: "확인",
                        });
                    });
            }
        });
    };

    const handleSave = (data: Omit<AddFileRequest, "categoryId">) => {
        addFile({
            ...data,
            categoryId: Number(targetFolder),
        })
            .submit()
            .then(() => {
                submit({
                    allRecordCnt: 0,
                    categoryId: Number(targetFolder),
                    pageNo: page,
                    recordPerPage: perPage,
                    startRecordNo: 0,
                    includeSubfolders: false,
                });
                setIsNewFile(false);
            })
            .catch(() => {
                Swal.fire({
                    title: "오류가 발생했습니다.",
                    text: "문제가 지속될 경우 관리자에 문의하세요.",
                    confirmButtonText: "확인",
                });
            });
    };

    const handleOpenFile = (fileBoardSeq: number) => {
        setIsOpenFile(fileBoardSeq);
    };

    const {user} = useUser();

    const handleDragStart = (event: DragEndEvent) => {
        const target = String(event.active.id).split("-");
        if (target[0] === "file") setDragFile(event.active.data.current as any);
        if (target[0] === "folder") setDragFolder(event.active.data.current as any);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const target = String(event.active.id).split("-");
        if (event.over) {
            const to = String(event.over.id).split("-");

            // 다른 폴더로 이동
            if (target[0] === to[0] && target[1] !== to[1])
                changeParentFolderForFolder(Number(target[1]), Number(to[1]))
                    .submit()
                    .then(() => {
                        refresh();
                        submit({
                            allRecordCnt: 0,
                            categoryId: Number(targetFolder),
                            pageNo: page,
                            recordPerPage: perPage,
                            startRecordNo: 0,
                            includeSubfolders: false,
                        });
                    });
            // 파일 -> 폴더 이동
            if (target[0] !== to[0])
                changeParentFolderForFile(Number(target[1]), Number(to[1]))
                    .submit()
                    .then(() => {
                        refresh();
                        submit({
                            allRecordCnt: 0,
                            categoryId: Number(targetFolder),
                            pageNo: page,
                            recordPerPage: perPage,
                            startRecordNo: 0,
                            includeSubfolders: false,
                        });
                    });
        }
        setDragFile(null);
        setDragFolder(null);
    };

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: {delay: 100, tolerance: 5},
        }),
    );

    return (
        <Fragment>
            {isNewFile && (
                <AddNewFileModal
                    onSave={handleSave}
                    onClose={() => {
                        setIsNewFile(false);
                    }}
                />
            )}
            {isOpenFile !== false && (
                <FileDetailModal
                    fileBoardSeq={isOpenFile}
                    onClose={() => {
                        setIsOpenFile(false);
                        submit({
                            allRecordCnt: 0,
                            categoryId: Number(targetFolder),
                            pageNo: page,
                            recordPerPage: perPage,
                            startRecordNo: 0,
                            includeSubfolders: false,
                        });
                    }}
                />
            )}
            <div>
                <PageTitle>자료실</PageTitle>
                <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={sensors}>
                    <Flex vertical wrap gap={"middle"}>
                        <h3 className={cx("type")}>
                            폴더
                            {user?.division === "MEMBER" && (
                                <Button label={"추가하기"} size="small" onClick={addFolder} />
                            )}
                        </h3>
                        <Flex wrap gap={"middle"}>
                            {Number(targetFolder) !== 1 && (
                                <Folder
                                    icon={faTurnUp}
                                    key={"..."}
                                    onDoubleClick={() => {
                                        setSearchParams({
                                            ...searchParams,
                                            folder: getParentFolderId(Number(targetFolder)),
                                        });
                                    }}
                                    folderId={Number(getParentFolderId(Number(targetFolder)))}
                                >
                                    상위폴더
                                </Folder>
                            )}
                            {displayFolders.map((folder) => (
                                <Folder
                                    category={folder}
                                    key={folder.id}
                                    onDoubleClick={() => {
                                        setSearchParams({
                                            ...searchParams,
                                            folder: String(folder.id),
                                        });
                                    }}
                                    onChangeName={() => modFolder(folder.id, folder.text)}
                                    onDelete={() => delFolder(folder.id, folder.text)}
                                    folderId={folder.id}
                                >
                                    {folder.text}
                                </Folder>
                            ))}
                        </Flex>
                        <h3 className={cx("type")}>
                            파일
                            {user?.division === "MEMBER" && (
                                <Button label={"추가하기"} size="small" onClick={() => setIsNewFile(true)} />
                            )}
                        </h3>
                        <Flex wrap gap={"middle"}>
                            {fileList.map((file) => (
                                <File
                                    key={file.fileBoardSeq}
                                    selected={selectedFiles.indexOf(file.fileBoardSeq) > -1}
                                    onClick={handleFileClick}
                                    fileData={file}
                                    onOpenFile={handleOpenFile}
                                />
                            ))}
                        </Flex>
                    </Flex>
                    <DragOverlay
                        style={{
                            opacity: 0.7,
                        }}
                    >
                        {dragFile && (
                            <File
                                key={dragFile.fileBoardSeq}
                                selected={false}
                                onClick={() => void 0}
                                fileData={dragFile}
                                onOpenFile={() => void 0}
                            />
                        )}
                        {dragFolder && (
                            <Folder
                                category={dragFolder}
                                key={dragFolder.id}
                                onDoubleClick={() => void 0}
                                onChangeName={() => void 0}
                                onDelete={() => void 0}
                                folderId={dragFolder.id}
                            >
                                {dragFolder.text}
                            </Folder>
                        )}
                    </DragOverlay>
                </DndContext>
            </div>
        </Fragment>
    );
};

export {DrivePage};
