import React, {useEffect, useMemo, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {Toast} from "antd-mobile";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";

import {UseMobileCheckList} from "~/data/check/mobile/use-mobile-check-list";
import {MobileCheckReport} from "~/data/check/mobile/use-mobile-check-list.interface";
import {useUser} from "~/data/user";

import styles from "./all-list.module.scss";

const cx = classNames.bind(styles);

const AllListPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [date, setDate] = useState<Dayjs>(dayjs(searchParams.get("date") || dayjs()));

    useEffect(() => {
        searchParams.set("date", date.format("YYYY-MM-DD"));
        setSearchParams(searchParams, {replace: true});
        Toast.show(date.format("YYYY-MM-DD"));
    }, [date, searchParams.get("type")]);

    const {user} = useUser();

    const {loading, list} = UseMobileCheckList({
        buildingId: user!.buildingId,
        orderDate: searchParams.get("date") as string,
        employeeId: user!.userId,
        category: undefined as any,
    });

    const timeBasedList = useMemo(() => {
        const timesArr = list
            .map((row) => row.notiTimeArr.split(","))
            .flat()
            .sort()
            .filter((el, index, self) => self.indexOf(el) === index);
        const response: {
            [key: string]: Array<MobileCheckReport>;
        } = {};
        timesArr.map((time) => {
            list.map((item) => {
                if (!Array.isArray(response[time])) response[time] = [];
                if (
                    (item.notiTimeArr?.includes(time) && !item.endNotitimeArr?.includes(time)) ||
                    item.ingNotitimeArr?.includes(time)
                )
                    response[time].push(item);
            });
        });
        return response;
    }, [list]);

    const navigate = useNavigate();

    const handleCheckClick = (check: MobileCheckReport) => {
        const isTableView = check.reportName.replaceAll(" ", "").indexOf("수변전") > -1;
        navigate(
            `/mobile/check/${isTableView ? "table" : "view"}/${check.regionId}/${check.checkFormIdArr}/${date.format("YYYY-MM-DD")}`,
        );
    };

    return (
        <div className={cx("container")}>
            <h1 className={cx("title")}>오늘의 점검사항</h1>
            <p className={cx("desc")}>
                오늘 완료되지 않은 점검입니다.
                <br />
                점검을 완료하면 아래 목록에서 보이지 않습니다.
                <br />
                전체 목록은 각 점검 메뉴에서 확인하실 수 있습니다.
            </p>
            <ul className={cx("check-time-list")}>
                {Object.keys(timeBasedList).map((time) => {
                    if (timeBasedList[time].length === 0) return;
                    return (
                        <li key={time} className={cx("check-time")}>
                            <p className={cx("time")}>{time.slice(0, 5)}</p>
                            <ul className={cx("check-list")}>
                                {timeBasedList[time].map((check: any) => {
                                    return (
                                        <li
                                            key={check.reportName}
                                            onClick={() => handleCheckClick(check)}
                                            className={cx("check")}
                                        >
                                            <p className={cx("report-name")}>
                                                <span className={cx("category")}>
                                                    [
                                                    {check.category === "LEGAL"
                                                        ? "법정점검"
                                                        : check.category === "CHECK"
                                                          ? "체크리스트"
                                                          : check.category === "ADD"
                                                            ? "일반점검"
                                                            : "추가점검"}
                                                    ]
                                                </span>{" "}
                                                {check.reportName}
                                            </p>
                                            <p className={cx("region-name")}>{check.regionName}</p>
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export {AllListPage};
