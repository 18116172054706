import {useEffect, useState} from "react";
import {faCircleCheck, faCirclePlus, faCircleXmark, faPen, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {App, Tooltip, Tree, TreeProps} from "antd";
import classNames from "classnames/bind";
import Swal from "sweetalert2";

import {PageTitle} from "~/components/page-title";
import {useBuildingArea} from "~/data/area/use-building-area";
import {BuildingAreaType} from "~/data/area/use-building-area.interface";
import {useUser} from "~/data/user";
import {AreaSettingInput} from "~/pages/area/area-setting.input";

import styles from "./area-setting.page.module.scss";

const cx = classNames.bind(styles);
const AreaSettingPage = () => {
    const [data, setData] = useState([]);
    const [hoverArea, setHoverArea] = useState<string | null>(null);
    const [selectedArea, setSelectedArea] = useState<string | null>(null);
    const [isUpdate, setIsUpdate] = useState<{id: string; value: string; parentId: number | null} | null>(null);
    const [isAdd, setIsAdd] = useState<BuildingAreaType | null>(null);
    const [isOpened, setIsOpened] = useState<string[]>([]);

    const {buildingId} = useUser();
    const {submit, areas, register, update, remove} = useBuildingArea({buildingId: buildingId ?? 0});

    const handleSelect: TreeProps["onSelect"] = (selectedKeys, info) => {
        if (selectedArea === info.node.key.toString()) {
            setSelectedArea(null);
        } else {
            setSelectedArea(info.node.key.toString());
        }
    };
    const handleDelete = (regionId: number) => {
        Swal.fire({
            html: "<p>삭제하시겠습니까?</p>" + "<p>하위 항목들까지 모두 삭제됩니다.</p>",
            showCancelButton: true,
            cancelButtonText: "취소하기",
            confirmButtonText: "삭제하기",
        }).then(async (info) => {
            if (info.isConfirmed) {
                await remove({
                    buildingId: buildingId ?? 0,
                    regionId: regionId,
                });
                setTimeout(submit, 300);
                setIsAdd(null);
            }
        });
    };
    const handleUpdate = async () => {
        await update({
            buildingId: buildingId ?? 0,
            regionName: isUpdate?.value ?? "",
            parentId: isUpdate?.parentId ?? 0,
            regionId: Number(isUpdate?.id) ?? 0,
        });
        setTimeout(submit, 300);
        setIsUpdate(null);
    };
    const handleAdd = async () => {
        await register({
            parentId: isAdd?.parentId ?? 0,
            regionName: isAdd?.regionName ?? "",
            buildingId: buildingId ?? 0,
        });
        setTimeout(submit, 300);
        setIsAdd(null);
    };

    const buildTree = (data: typeof areas) => {
        const map = {};
        const result: any = [];
        if (data) {
            // 모든 아이템을 맵에 추가하여 빠르게 참조할 수 있도록 설정
            data.forEach((item) => {
                (map as any)[item.regionId] = {
                    title: (value: any) => {
                        return (
                            <span
                                onMouseEnter={() => {
                                    setHoverArea(value.key);
                                }}
                                onMouseLeave={() => {
                                    setHoverArea(null);
                                }}
                            >
                                {isUpdate?.id === value.key ? (
                                    <AreaSettingInput
                                        className={cx("update-input")}
                                        placeholder={"수정내용을 입력하세요"}
                                        initialize={item.regionName}
                                        setIsUpdate={setIsUpdate}
                                    />
                                ) : value.key === "0" ? (
                                    <AreaSettingInput
                                        className={cx("update-input")}
                                        placeholder={"구역명을 입력하세요"}
                                        initialize={""}
                                        setIsAdd={setIsAdd}
                                    />
                                ) : (
                                    item.regionName
                                )}
                                {isUpdate?.id === value.key || value.key === "0" ? (
                                    <>
                                        <Tooltip title={value.key === "0" ? "저장하기" : "수정하기"}>
                                            <FontAwesomeIcon
                                                icon={faCircleCheck}
                                                className={cx("icon")}
                                                onClick={value.key === "0" ? handleAdd : handleUpdate}
                                            />
                                        </Tooltip>
                                        <Tooltip title={"취소하기"}>
                                            <FontAwesomeIcon
                                                icon={faCircleXmark}
                                                className={cx("icon")}
                                                onClick={() => (value.key === "0" ? setIsAdd(null) : setIsUpdate(null))}
                                            />
                                        </Tooltip>
                                    </>
                                ) : (
                                    (hoverArea === value.key || selectedArea === value.key) && (
                                        <>
                                            <Tooltip title={"편집하기"}>
                                                <FontAwesomeIcon
                                                    icon={faPen}
                                                    className={cx("icon")}
                                                    onClick={() =>
                                                        setIsUpdate({
                                                            id: value.key,
                                                            value: "",
                                                            parentId: item.parentId,
                                                        })
                                                    }
                                                />
                                            </Tooltip>
                                            <Tooltip title={"삭제하기"}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    className={cx("icon")}
                                                    onClick={() => handleDelete(item.regionId)}
                                                />
                                            </Tooltip>
                                            <Tooltip title={"하위 항목 추가하기"}>
                                                <FontAwesomeIcon
                                                    icon={faCirclePlus}
                                                    className={cx("icon")}
                                                    onClick={() => {
                                                        setIsAdd({
                                                            parentId: Number(value.key),
                                                            regionName: "",
                                                            regionId: 0,
                                                            buildingId: buildingId ?? 0,
                                                        });
                                                        setIsOpened((prev) => [...prev, value.key]);
                                                    }}
                                                />
                                            </Tooltip>
                                        </>
                                    )
                                )}
                            </span>
                        );
                    },
                    key: item.regionId.toString(),
                    children: [],
                };
            });

            // 트리 구조를 빌드
            data.forEach((item) => {
                if (item.parentId === null) {
                    // 부모가 없는 경우 최상위 노드로 추가
                    result.push((map as any)[item.regionId]);
                } else {
                    // 부모가 있는 경우 해당 부모의 children에 추가
                    if ((map as any)[item.parentId]) {
                        (map as any)[item.parentId].children.push((map as any)[item.regionId]);
                    }
                }
            });
        }
        return result;
    };

    useEffect(() => {
        if (buildingId !== 0 && buildingId !== undefined) submit();
    }, [buildingId]);

    useEffect(() => {
        if (isAdd && areas) {
            const temp: BuildingAreaType[] = [...areas];
            temp.push(isAdd);
            setData(buildTree(temp));
        } else {
            setData(buildTree(areas));
        }
    }, [areas, hoverArea, selectedArea, isUpdate, isAdd]);

    return (
        <App>
            <div>
                <PageTitle>구역 설정</PageTitle>
                <div className={cx("content")}>
                    <Tree
                        treeData={data}
                        showLine={true}
                        onSelect={handleSelect}
                        expandedKeys={isOpened}
                        onExpand={(keys) => setIsOpened(keys as string[])}
                    />
                </div>
            </div>
        </App>
    );
};

export {AreaSettingPage};
