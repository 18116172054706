import React, {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {UseMobileCheckList} from "~/data/check/mobile/use-mobile-check-list";
import {useUser} from "~/data/user";

import styles from "./mobile-dashboard.module.scss";

const cx = classNames.bind(styles);

const MobileDashboardPage = () => {
    const navigate = useNavigate();

    const {buildingId, user} = useUser();

    const {summary} = useCheckElevatorList({
        perPage: 1000,
        page: 1,
        buildingId: buildingId,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
    });

    const legal = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: user!.userId,
        category: "LEGAL",
    });

    const checklist = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: user!.userId,
        category: "CHECK",
    });

    const extra = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: user!.userId,
        category: "ADD",
    });

    const normal = UseMobileCheckList({
        buildingId: buildingId as number,
        orderDate: dayjs().format("YYYY-MM-DD"),
        employeeId: user!.userId,
        category: "NORMAL",
    });

    const legalCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        legal.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [legal.list]);

    const checklistCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        checklist.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [checklist.list]);

    const extraCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        extra.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [extra.list]);

    const normalCount = useMemo(() => {
        const cntArr = [0, 0, 0];
        normal.list.map((row) => {
            cntArr[0] += row.beforeCnt;
            cntArr[1] += row.ingCnt;
            cntArr[2] += row.endCnt;
        });
        return cntArr;
    }, [normal.list]);

    return (
        <div className={cx("mobile-container")}>
            <div className={cx("row-1")}>
                <button
                    className={cx("button")}
                    onClick={() => {
                        navigate("/mobile/check/all-list");
                    }}
                >
                    <p>오늘의 점검사항</p>
                </button>
            </div>
            <div className={cx("row-2")}>
                <button
                    className={cx("button")}
                    onClick={() => {
                        navigate("/mobile/equipment");
                    }}
                >
                    <p>설비현황</p>
                </button>

                <button
                    className={cx("button")}
                    onClick={() => {
                        navigate("/mobile/check/elevator");
                    }}
                >
                    <p>승강기점검</p>
                    <p className={cx("count-text")}>접수완료: {summary.REQUEST}</p>
                    <p className={cx("count-text")}>점검중: {summary.ING}</p>
                    <p className={cx("count-text")}>조치완료: {summary.COMPLETED}</p>
                </button>
            </div>
            <div className={cx("row-2")}>
                <button
                    className={cx("button")}
                    onClick={() => {
                        navigate("/mobile/check/list?type=legal");
                    }}
                >
                    <p>법정점검</p>
                    <p className={cx("count-text")}>점검전: {legalCount[0]}</p>
                    <p className={cx("count-text")}>점검중: {legalCount[1]}</p>
                    <p className={cx("count-text")}>점검완료: {legalCount[2]}</p>
                </button>
                <button
                    className={cx("button")}
                    onClick={() => {
                        navigate("/mobile/check/list?type=checklist");
                    }}
                >
                    <p>체크리스트</p>
                    <p className={cx("count-text")}>점검전: {checklistCount[0]}</p>
                    <p className={cx("count-text")}>점검중: {checklistCount[1]}</p>
                    <p className={cx("count-text")}>점검완료: {checklistCount[2]}</p>
                </button>
            </div>
            <div className={cx("row-2")}>
                <button
                    className={cx("button")}
                    onClick={() => {
                        navigate("/mobile/check/list?type=extra");
                    }}
                >
                    <p>일반점검</p>
                    <p className={cx("count-text")}>점검전: {extraCount[0]}</p>
                    <p className={cx("count-text")}>점검중: {extraCount[1]}</p>
                    <p className={cx("count-text")}>점검완료: {extraCount[2]}</p>
                </button>
                <button
                    className={cx("button")}
                    onClick={() => {
                        navigate("/mobile/check/list?type=normal");
                    }}
                >
                    <p>추가점검</p>
                    <p className={cx("count-text")}>점검전: {normalCount[0]}</p>
                    <p className={cx("count-text")}>점검중: {normalCount[1]}</p>
                    <p className={cx("count-text")}>점검완료: {normalCount[2]}</p>
                </button>
            </div>
        </div>
    );
};

export {MobileDashboardPage};
