import {useMemo, useState} from "react";

import {httpRequest} from "~/fetch/common/http-request";
import {WorkListType} from "~/pages/work-plan/work-plan.interface";

const useCommonWorkPlan = () => {
    const [response, setResponse] = useState<WorkListType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<null, WorkListType[]>({
            method: "GET",
            url: "/dailyWork/type/list",
        });
    }, []);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };
    return {submitList: submit, workList: response, isLoading, error};
};

export {useCommonWorkPlan};
