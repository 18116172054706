import {useEffect, useState} from "react";
import classNames from "classnames/bind";

import {WorkPlanWeekListResponse} from "~/data/work-plan/work-plan.interface";

import styles from "./work-plan.list.module.scss";

const cx = classNames.bind(styles);

const WorkPlanList = (data: WorkPlanWeekListResponse) => {
    const [elements, setElements] = useState<JSX.Element[]>([]);

    useEffect(() => {
        setElements(
            data.workInfos.map((data, index) => (
                <div className={cx("list")} key={index}>
                    <div className={cx("item", {title: true, empty: !data.dailyWorkList.length})}>{data.name}</div>

                    {data.dailyWorkList.length ? (
                        data.dailyWorkList.map((data, index) => (
                            <div className={cx("item")} key={index}>
                                {data.hrName} {data.detail !== "" && data.detail !== null && `(${data.detail})`}
                            </div>
                        ))
                    ) : (
                        <div className={cx("item")}>일정없음</div>
                    )}
                </div>
            )),
        );
    }, [data.workInfos]);

    return (
        <div className={cx("container")} onClick={data.openModal}>
            {elements}
        </div>
    );
};

export {WorkPlanList};
