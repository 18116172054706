import {useMemo, useState} from "react";
import {message} from "antd";
import Swal from "sweetalert2";

import {
    BuildingAreaProps,
    BuildingAreaRegisterRequest,
    BuildingAreaType,
    BuildingAreaUpdateRequest,
} from "~/data/area/use-building-area.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useBuildingArea = ({buildingId}: BuildingAreaProps) => {
    const [response, setResponse] = useState<BuildingAreaType[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<null, BuildingAreaType[]>({
            method: "GET",
            url: `/buildings/${buildingId}/regions`,
        });
    }, [buildingId]);

    const submit = async () => {
        try {
            setIsLoading(true);
            const res = await request.submit();
            setResponse(res.data);
        } catch (e) {
            console.log(e);
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const register = async ({buildingId, parentId, regionName}: BuildingAreaRegisterRequest) => {
        setIsLoading(true);
        return await httpRequest<{parentId: number; regionName: string}, any>({
            method: "POST",
            url: `/buildings/${buildingId}/regions`,
            data: {
                parentId,
                regionName,
            },
        })
            .submit()
            .then(() => {
                message.success("등록되었습니다.");
            })
            .catch((e) => {
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    };

    const update = async ({buildingId, parentId, regionId, regionName}: BuildingAreaUpdateRequest) => {
        setIsLoading(true);
        return await httpRequest<{parentId: number; regionName: string}, any>({
            method: "PUT",
            url: `/buildings/${buildingId}/regions/${regionId}`,
            data: {
                parentId,
                regionName,
            },
        })
            .submit()
            .then(() => {
                message.success("수정되었습니다.");
            })
            .catch((e) => {
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    };

    const remove = async ({buildingId, regionId}: {buildingId: number; regionId: number}) => {
        setIsLoading(true);
        return await httpRequest<{buildingId: number; regionId: number}, any>({
            method: "DELETE",
            url: `/buildings/${buildingId}/regions/${regionId}`,
        })
            .submit()
            .then(() => {
                message.success("삭제되었습니다.");
            })
            .catch((e) => {
                setError(e);
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            })
            .finally(() => setIsLoading(false));
    };

    return {submit, areas: response, register, update, remove, isLoading, error};
};

export {useBuildingArea};
