import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {faArrowRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import {useCheckElevatorList} from "~/data/check/elevator/use-check-elevator";
import {CostIntegratedRequest, CostIntegratedResponse} from "~/data/cost/integrated/integrated.interface";
import {httpRequest} from "~/fetch/common/http-request";
import {calculatePercentage} from "~/utills/calculatePercentage";

import styles from "./pc-dashboard.page.module.scss";

const cx = classNames.bind(styles);

const CheckStatusMember = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [data, setData] = useState<any>({});
    const {summary} = useCheckElevatorList({
        perPage: 1000,
        page: 1,
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
    });

    const navigate = useNavigate();

    useEffect(() => {
        httpRequest<any, any>({
            method: "GET",
            url: "/buildings/checks/total/list",
            params: {
                orderDate: dayjs().format("YYYY-MM-DD"),
            },
        })
            .submit()
            .then((res: any) => {
                setData(res.data[0]);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (
        <div className={cx("card", "check-status")}>
            <div className={cx("loading", {active: isLoading})}>
                <div className={cx("loading-text")}>Loading</div>
            </div>
            <div className={cx("body")}>
                <div className={cx("check-analytics")}>
                    <div className={cx("analytics-area")}>
                        <ul className={cx("analytics-list")}>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/legal/report")}
                                >
                                    법정점검
                                </span>
                                <span className={cx("analytics-item-desc")}>
                                    {calculatePercentage(data.legalEndCnt, data.legalAllCnt)}%
                                </span>
                                <span className={cx("analytics-item-tag")}>진행률</span>
                                <span className={cx("analytics-item-desc")}>
                                    {Number(data.legalEndCnt).toLocaleString()}
                                </span>
                                <span className={cx("analytics-item-tag")}>점검완료</span>
                                <span className={cx("analytics-item-desc")}>
                                    {Number(data.legalAllCnt).toLocaleString()}
                                </span>
                                <span className={cx("analytics-item-tag")}>전체</span>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/checklist/report")}
                                >
                                    체크리스트
                                </span>
                                <span className={cx("analytics-item-desc")}>
                                    {calculatePercentage(data.checkEndCnt, data.checkAllCnt)}%
                                </span>
                                <span className={cx("analytics-item-tag")}>진행률</span>
                                <span className={cx("analytics-item-desc")}>
                                    {Number(data.checkEndCnt).toLocaleString()}
                                </span>
                                <span className={cx("analytics-item-tag")}>점검완료</span>
                                <span className={cx("analytics-item-desc")}>
                                    {Number(data.checkAllCnt).toLocaleString()}
                                </span>
                                <span className={cx("analytics-item-tag")}>전체</span>
                            </li>
                        </ul>
                    </div>
                    <div className={cx("analytics-area")}>
                        <ul className={cx("analytics-list")}>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/extra/report")}
                                >
                                    일반점검
                                </span>
                                <span className={cx("analytics-item-desc")}>
                                    {calculatePercentage(data.addEndCnt, data.addAllCnt)}%
                                </span>
                                <span className={cx("analytics-item-tag")}>진행률</span>
                                <span className={cx("analytics-item-desc")}>
                                    {Number(data.addEndCnt).toLocaleString()}
                                </span>
                                <span className={cx("analytics-item-tag")}>점검완료</span>
                                <span className={cx("analytics-item-desc")}>
                                    {Number(data.addAllCnt).toLocaleString()}
                                </span>
                                <span className={cx("analytics-item-tag")}>전체</span>
                            </li>
                            <li className={cx("analytics-item")}>
                                <span
                                    className={cx("analytics-item-title", "clickable")}
                                    onClick={() => navigate("/check/elevator")}
                                >
                                    승강기점검
                                    {/*<FontAwesomeIcon*/}
                                    {/*    icon={faArrowRightToBracket}*/}
                                    {/*    className={cx("analytics-item-title-icon")}*/}
                                    {/*/>*/}
                                </span>
                                <span className={cx("analytics-item-desc")}>{summary.REQUEST}건</span>
                                <span className={cx("analytics-item-tag")}>접수</span>
                                <span className={cx("analytics-item-desc")}>{summary.COMPLETED}건</span>
                                <span className={cx("analytics-item-tag")}>완료</span>
                                <span className={cx("analytics-item-desc")}>{summary.penalty_above}건</span>
                                <span className={cx("analytics-item-tag")}>패널티</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export {CheckStatusMember};
