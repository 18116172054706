import {useCallback, useEffect, useState} from "react";
import classNames from "classnames/bind";

import Button from "~/components/button";
import {CnInput} from "~/components/cn-input";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area";
import {PageTitle} from "~/components/page-title";
import {useBoardList} from "~/data/board/use-board-list";
import {useUser} from "~/data/user";
import {ManualBoardModal} from "~/pages/manual/modal/manual-board.modal";

import styles from "./manual-board.module.scss";

const cx = classNames.bind(styles);

const ManualBoardPage = () => {
    const perPage = 30;
    const [inputs, setInputs] = useState<{title: string; content: string}>({
        title: "",
        content: "",
    });
    const [page, setPage] = useState<number>(1);
    const [isModal, setIsModal] = useState<number | null>(null);

    const {user} = useUser();
    const [submitList, tableList, isListLoading] = useBoardList({
        category: "manual",
        title: inputs.title || null,
        content: inputs.content || null,
        startDate: null,
        endDate: null,
        page,
        perPage,
    });

    const handleReset = useCallback(() => {
        setInputs({
            title: "",
            content: "",
        });
    }, []);
    const handleInquiry = () => {
        setPage(1);
        submitList();
        window.scrollTo(0, 0);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        submitList();
    }, [page]);

    return (
        <div>
            {isModal !== null && (
                <ManualBoardModal close={() => setIsModal(null)} refresh={() => submitList()} noticeId={isModal} />
            )}
            <PageTitle>매뉴얼 게시판</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <CnInput
                        label={"제목"}
                        placeholder={"제목검색"}
                        value={inputs.title}
                        onChange={(e) => setInputs((prev) => ({...prev, title: e.target.value}))}
                    />
                    <CnInput
                        label={"내용"}
                        placeholder={"내용검색"}
                        value={inputs.content}
                        onChange={(e) => setInputs((prev) => ({...prev, content: e.target.value}))}
                    />
                    <div className={cx("button-container")}>
                        <Button label={"조회"} color={"primary"} onClick={handleInquiry} />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <div className={cx("table-label-container")}>
                            <span>
                                {tableList?.allRecordCnt} 건{" "}
                                {`(${page}/${Math.ceil((tableList?.allRecordCnt ? tableList.allRecordCnt : 1) / perPage)} 페이지)`}
                            </span>

                            {user?.division === "MEMBER" && (
                                <Button
                                    label="등록하기"
                                    onClick={() => {
                                        setIsModal(0);
                                    }}
                                />
                            )}
                        </div>
                    }
                    totalCount={0}
                    onPageChange={(e) => setPage(e)}
                    page={page}
                    perPage={perPage}
                    pagination={false}
                    loading={isListLoading}
                    rowKey={"id"}
                    onRow={(record) => ({
                        onClick: () => {
                            setIsModal(record.id);
                        },
                    })}
                    dataSource={tableList?.list}
                    columns={[
                        {title: "No", key: "rowNum", dataIndex: "rowNum", width: "4%"},
                        {title: "작성자", key: "name", dataIndex: "name", width: "10%"},

                        {
                            title: "제목",
                            key: "title",
                            dataIndex: "title",
                            width: "50%",
                            ellipsis: true,
                        },
                        {
                            title: "등록일",
                            key: "createDate",
                            dataIndex: "createDate",
                            width: "12%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {ManualBoardPage};
