import {useEffect, useState} from "react";
import {createPortal} from "react-dom";
import {faDownload, faPaperclip, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as AntButton, Checkbox, CheckboxProps, List, Radio, Spin, Upload, UploadFile, UploadProps} from "antd";
import classNames from "classnames/bind";
import dayjs from "dayjs";
import Swal from "sweetalert2";

import Button from "~/components/button/button";
import {MergeAnswerList} from "~/components/merge/merge-answer-list/merge-answer-list";
import {MergeGridItem} from "~/components/merge/merge-grid-item/merge-grid-item";
import {PageTitle} from "~/components/page-title/page-title";
import {MergeAnswerFormCheckResponse} from "~/data/merge/answer/answer.interface";
import {useMergeAnswer, useMergeAnswerAddFileDownload, useMergeAnswerFormCheck} from "~/data/merge/answer/use-answer";
import {useMergeDetail, useMergeDownloadBuilding, useMergeFormDownload} from "~/data/merge/detail/use-detail";
import {MergeNoticeMergeFileDTOList, MergeNoticeMergeQnADTOList} from "~/data/merge/merge.interface";

import {MergeBoardAnswerModalProps} from "./merge-board-answer.interface";

import styles from "./merge-board-answer.modal.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const MergeBoardAnswerModal = ({noticeMergeId, close, submit: submitTable}: MergeBoardAnswerModalProps) => {
    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileList, setFileList] = useState<MergeNoticeMergeFileDTOList[]>([]);
    const [file, setFile] = useState<File | null>(null);
    const [deleteFiles, setDeleteFiles] = useState<number[] | null>(null);
    const [isNone, setIsNone] = useState<"Y" | "N">("N"); // 해당 없음
    const [questionList, setQuestionList] = useState<JSX.Element[]>([]);
    const [qnaList, setQnaList] = useState<MergeNoticeMergeQnADTOList[]>([]);

    const props: UploadProps = {
        name: "file",
        onRemove(file) {
            setFile(null);
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
        showUploadList: false,
    };

    // 상세 정보
    const [submit, response, isDataLoading] = useMergeDetail({
        noticeMergeId,
    });

    // 첨부양식 다운로드
    const [downloadForm] = useMergeFormDownload(noticeMergeId);
    // 추가첨부 다운로드
    const [downloadAddFiles] = useMergeAnswerAddFileDownload();
    //응답 저장
    const [answer] = useMergeAnswer({
        info: response
            ? {
                  ...response.responseData,
                  deleteFileIdList: deleteFiles,
                  noticeMergeQuestionAndAnswerDTOList: qnaList,
              }
            : null,

        file,
        mergeType: response?.responseData.mergeType ?? "EXCEL",
    });
    // 취합파일 검사
    const [formCheck] = useMergeAnswerFormCheck({uploadFile: file, noticeMergeId: noticeMergeId});
    // 지점이 기존에 넣었던 파일 다운로드
    const [downloadExistFile] = useMergeDownloadBuilding();

    // modal 초기셋팅
    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);
        submit();
    }, []);

    useEffect(() => {
        if (response?.responseData.noticeMergeFileDTOList.length) {
            if (response.responseData.noticeMergeFileDTOList[0].isNone === "Y") {
                setDeleteFiles(
                    response.responseData.noticeMergeFileDTOList[0].noticeMergeFileId
                        ? [response.responseData.noticeMergeFileDTOList[0].noticeMergeFileId]
                        : null,
                );
                setIsNone("Y");
            } else {
                setFileList(response?.responseData.noticeMergeFileDTOList ?? []);
                setDeleteFiles(
                    response.responseData.noticeMergeFileDTOList[0].noticeMergeFileId
                        ? [response.responseData.noticeMergeFileDTOList[0].noticeMergeFileId]
                        : null,
                );
            }
        }
        setQnaList(response?.responseData.noticeMergeQuestionAndAnswerDTOList ?? []);

        setQuestionList(
            response?.responseData.noticeMergeQuestionAndAnswerDTOList.map((data, index) => (
                <MergeAnswerList
                    key={index}
                    index={index + 1}
                    noticeMergeQuestionId={data.noticeMergeQuestionId}
                    question={data.question}
                    answer={data.answer}
                    isNone={data.isNone ?? "N"}
                    setQnaList={setQnaList}
                />
            )) ?? [],
        );
    }, [response]);

    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => close(), 500);
    }, [isLoading]);

    useEffect(() => {
        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    // 닫기
    const handleClose = () => {
        setIsLoading(false);
    };

    // 저장
    const handleSave = () => {
        Swal.fire({
            text: "저장하시겠습니까?",
            showCancelButton: true,
            confirmButtonText: "확인",
            cancelButtonText: "취소",
        }).then((info) => {
            if (info.isConfirmed) {
                if (response?.responseData.mergeType === "EXCEL") {
                    if (isNone === "N") {
                        if (file || fileList.length) {
                            answer(isNone, "EXCEL");
                            setTimeout(submitTable, 1000);
                            setIsLoading(false);
                        } else {
                            Swal.fire({
                                text: "파일을 선택해주세요",
                                confirmButtonText: "확인",
                            });
                        }
                    } else {
                        answer(isNone, "EXCEL");
                        setTimeout(submitTable, 1000);
                        setIsLoading(false);
                    }
                } else if (response?.responseData.mergeType === "FILE") {
                    if (isNone === "N") {
                        if (file || fileList.length) {
                            answer(isNone, "FILE");
                            setTimeout(submitTable, 1000);
                            setIsLoading(false);
                        } else {
                            Swal.fire({
                                text: "파일을 선택해주세요",
                                confirmButtonText: "확인",
                            });
                        }
                    } else {
                        answer(isNone, "FILE");
                        setTimeout(submitTable, 1000);
                        setIsLoading(false);
                    }
                } else {
                    answer("N", "DATA");
                    setTimeout(submitTable, 1000);
                    setIsLoading(false);
                }
            }
        });
    };

    // 해당없음
    const onChangeApplicable: CheckboxProps["onChange"] = (e) => {
        if (e.target.checked) {
            setFile(null);
            setFileList([]);
            setIsNone("Y");
        } else {
            setIsNone("N");
        }
    };

    // 취합파일양식 검사
    const handleBeforeUpload = async (file: File) => {
        if (response?.responseData.mergeType === "FILE") {
            setFile(file);
            return false;
        } else {
            const result: MergeAnswerFormCheckResponse | null = await formCheck(file);

            if (result?.responseResult === "SUCCESS") {
                setFile(file);
            } else {
                Swal.fire({
                    title: "파일 오류",
                    text: result?.responseMessage,
                    confirmButtonText: "확인",
                });
                return Upload.LIST_IGNORE;
            }
            return false;
        }
    };

    return createPortal(
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading})}
                onClick={(e) => {
                    e.stopPropagation();
                }}
            >
                <div className={cx("contents-container")}>
                    <PageTitle>취합게시판 응답</PageTitle>
                    {isDataLoading ? (
                        <div className={cx("loading")}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <div className={cx("form-container")}>
                            <div className={cx("detail-content")}>
                                <MergeGridItem name={"연도"}>{response?.responseData.year}</MergeGridItem>
                                <MergeGridItem name={"월"}>{response?.responseData.month}</MergeGridItem>
                                <MergeGridItem name={"취합기간"}>
                                    {dayjs(new Date(response?.responseData.startDate ?? new Date())).format(
                                        "YYYY.MM.DD",
                                    )}
                                    {" ~ "}
                                    {dayjs(new Date(response?.responseData.endDate ?? new Date())).format("YYYY.MM.DD")}
                                </MergeGridItem>
                                <MergeGridItem name={"담당자"}>{response?.responseData.managerNm}</MergeGridItem>
                                <MergeGridItem name={"제목"} span extraClassName={"text-left"}>
                                    {response?.responseData.title}
                                </MergeGridItem>
                                <MergeGridItem name={"구분"}>
                                    {" "}
                                    {response?.responseData.mergeType === "DATA"
                                        ? "데이터"
                                        : response?.responseData.mergeType === "FILE"
                                          ? "파일"
                                          : "엑셀"}{" "}
                                    취합
                                </MergeGridItem>
                                <MergeGridItem name={"등록일"}>
                                    {dayjs(response?.responseData.createDate).format("YYYY.MM.DD")}
                                </MergeGridItem>
                                <MergeGridItem name={"중요도"} span>
                                    <Radio.Group
                                        className={gridCx("radio-group")}
                                        value={response?.responseData.important}
                                        disabled
                                        onChange={(e) => {
                                            console.log("");
                                        }}
                                    >
                                        <Radio className={gridCx("radio")} value={0}>
                                            -
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={1}>
                                            ★
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={2}>
                                            ★★
                                        </Radio>
                                        <Radio className={gridCx("radio")} value={3}>
                                            ★★★
                                        </Radio>
                                    </Radio.Group>
                                </MergeGridItem>
                                {(response?.responseData.mergeType === "EXCEL" ||
                                    response?.responseData.mergeType === "FILE") && (
                                    <MergeGridItem name={"첨부양식"} extraClassName={"upload-container-detail-answer"}>
                                        <div
                                            className={gridCx("default-file")}
                                            onClick={() => {
                                                downloadForm();
                                            }}
                                        >
                                            {response?.responseData.formFileOriginName
                                                ? response.responseData.formFileOriginName
                                                : "-"}
                                        </div>
                                    </MergeGridItem>
                                )}
                                <MergeGridItem
                                    span={response?.responseData.mergeType === "DATA"}
                                    name={
                                        <div className={gridCx("question-name-container")}>
                                            <span>추가첨부</span>
                                            <Button
                                                label="일괄저장"
                                                color={"primary"}
                                                onClick={() => {
                                                    downloadAddFiles({noticeMergeId});
                                                }}
                                            />
                                        </div>
                                    }
                                    extraClassName="upload-container-detail-answer"
                                >
                                    <div className={gridCx("list-container-answer")}>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={response?.responseData.addFileDTOList}
                                            className={gridCx("file-list")}
                                            locale={{emptyText: " "}}
                                            renderItem={(file, index) => (
                                                <List.Item
                                                    className={gridCx("file-list-item")}
                                                    actions={[
                                                        <AntButton
                                                            type="link"
                                                            key={index}
                                                            icon={<FontAwesomeIcon icon={faDownload} />}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                downloadAddFiles({id: file.id});
                                                            }}
                                                        />,
                                                    ]}
                                                >
                                                    <List.Item.Meta
                                                        avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                        title={file.fileOriginName}
                                                        className={gridCx("file-list-item-meta")}
                                                        style={{textAlign: "left"}}
                                                    />
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </MergeGridItem>
                                <MergeGridItem name={"내용"} span extraClassName={"text-left"}>
                                    <pre className={gridCx("text-area")}>{response?.responseData.content}</pre>
                                </MergeGridItem>
                            </div>

                            {response?.responseData.mergeType === "EXCEL" ||
                            response?.responseData.mergeType === "FILE" ? (
                                <>
                                    <div className={cx("subtitle")}>
                                        <span>취합 내용 입력</span>
                                        <div className={cx("is-none-container")}>
                                            <Checkbox
                                                name={"isNone"}
                                                checked={isNone === "Y"}
                                                onChange={onChangeApplicable}
                                            >
                                                해당없음
                                            </Checkbox>
                                        </div>
                                    </div>
                                    <div className={cx("file-container")}>
                                        {isNone === "Y" ? (
                                            <div className={cx("is-none-status")}>N/A</div>
                                        ) : (
                                            <Upload.Dragger
                                                className={cx("upload-dragger")}
                                                {...props}
                                                maxCount={1}
                                                beforeUpload={handleBeforeUpload}
                                            >
                                                {file ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={[file]}
                                                        renderItem={(file, index) => (
                                                            <List.Item
                                                                className={cx("file-list")}
                                                                actions={[
                                                                    <AntButton
                                                                        type="link"
                                                                        danger
                                                                        key={index}
                                                                        icon={<FontAwesomeIcon icon={faTrash} />}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setFileList([]);
                                                                            setFile(null);
                                                                        }}
                                                                    />,
                                                                ]}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                                    title={file.name}
                                                                    style={{textAlign: "left"}}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : fileList.length ? (
                                                    <List
                                                        itemLayout="horizontal"
                                                        dataSource={fileList}
                                                        renderItem={(file, index) => (
                                                            <List.Item
                                                                className={cx("file-list")}
                                                                actions={[
                                                                    <AntButton
                                                                        type="link"
                                                                        key={index}
                                                                        icon={<FontAwesomeIcon icon={faDownload} />}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            downloadExistFile(
                                                                                file.noticeMergeFileId
                                                                                    ? file.noticeMergeFileId.toString()
                                                                                    : "",
                                                                                response?.responseData.year ?? "",
                                                                                response?.responseData.month ?? "",
                                                                            );
                                                                        }}
                                                                    />,
                                                                    <AntButton
                                                                        type="link"
                                                                        danger
                                                                        key={index}
                                                                        icon={<FontAwesomeIcon icon={faTrash} />}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();

                                                                            setDeleteFiles(
                                                                                file.noticeMergeFileId
                                                                                    ? [file.noticeMergeFileId]
                                                                                    : [],
                                                                            );
                                                                            setFile(null);
                                                                            setFileList([]);
                                                                        }}
                                                                    />,
                                                                ]}
                                                            >
                                                                <List.Item.Meta
                                                                    avatar={<FontAwesomeIcon icon={faPaperclip} />}
                                                                    title={file.fileOriginName}
                                                                    style={{textAlign: "left"}}
                                                                />
                                                            </List.Item>
                                                        )}
                                                    />
                                                ) : (
                                                    <>
                                                        <p>드래그하여 파일을 업로드 하거나 파일을 선택하세요</p>
                                                        <p>취합양식에 맞는 파일만 업로드 가능합니다</p>
                                                        <p>저장 전 선택한 파일은 다운로드 할 수 없습니다</p>
                                                    </>
                                                )}
                                            </Upload.Dragger>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={cx("subtitle")}>
                                        <span>취합 내용 입력</span>
                                    </div>
                                    {questionList}
                                </>
                            )}
                        </div>
                    )}
                </div>
                <div className={cx("button-container")}>
                    <Button onClick={handleSave} label="저장" />
                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                </div>
            </div>
        </div>,
        document.body,
    );
};

export {MergeBoardAnswerModal};
