import React from "react";
import {useDroppable} from "@dnd-kit/core";
import classNames from "classnames/bind";

import styles from "./update-form-list.module.scss";

const cx = classNames.bind(styles);

const UpdateFormList = ({children}: any) => {
    const {setNodeRef} = useDroppable({
        id: "UpdateFormList",
    });

    return (
        <tbody ref={setNodeRef} className={cx("tbody")}>
            {children}
        </tbody>
    );
};

export {UpdateFormList};
