import {useEffect, useState} from "react";
import Swal from "sweetalert2";

import {
    AddFileRequest,
    File,
    FileDetailResponse,
    FileRequest,
    FileResponse,
    UpdateFileRequest,
} from "~/data/drive/use-files.interface";
import {httpRequest} from "~/fetch/common/http-request";

const useFiles = (targetFolder: number | string) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [fileList, setFileList] = useState<Array<File>>([]);

    const submit = (params: FileRequest) => {
        httpRequest<FileRequest, FileResponse>({
            method: "GET",
            url: "/fileBoard/getList",
            params: params,
        })
            .submit()
            .then((res) => {
                setFileList(res.data.responseData);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        setFileList([]);
    }, [targetFolder]);

    const addFile = (params: AddFileRequest) => {
        return httpRequest<AddFileRequest, any>({
            method: "POST",
            url: "/fileBoard/setFileBoard",
            data: params,
        });
    };

    return {loading, fileList, submit, addFile};
};

const usefile = (fileBoardSeq: number) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<FileDetailResponse | null>(null);

    const submit = () => {
        setLoading(true);
        httpRequest<{fileBoardSeq: number}, {responseData: FileDetailResponse}>({
            method: "GET",
            url: "/fileBoard/getFileBoard",
            params: {
                fileBoardSeq: fileBoardSeq,
            },
        })
            .submit()
            .then((res) => {
                setData(res.data.responseData);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    useEffect(() => {
        submit();
    }, [fileBoardSeq]);

    const updateFile = (params: UpdateFileRequest) => {
        return httpRequest<UpdateFileRequest, any>({
            method: "PUT",
            url: "/fileBoard/updateFileBoard",
            data: params,
        });
    };

    const deleteBoardFile = (fileBoardSeq: number) => {
        return httpRequest<UpdateFileRequest, any>({
            method: "DELETE",
            url: "/fileBoard/deleteFileBoard",
            params: {
                fileBoardSeq,
            },
        });
    };

    const deleteFile = (fileBoardDataSeq: number) => {
        return httpRequest<UpdateFileRequest, any>({
            method: "DELETE",
            url: "/fileBoard/deleteFileData",
            params: {
                fileBoardDataSeq,
            },
        });
    };

    const fileDownload = (fileBoardDataSeq: number) => {
        httpRequest<{fileBoardDataSeq: number}, Blob>({
            method: "GET",
            url: "/fileBoard/downloadForm",
            params: {
                fileBoardDataSeq,
            },
            responseType: "blob",
        })
            .submit()
            .then((response) => {
                const blob = new Blob([response.data], {type: response.headers["content-type"]});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;

                const contentType = response.headers["content-type"];
                let fileName = "downloaded_file";

                if (contentType) {
                    const matches = /filename=([^;]*)/.exec(contentType);
                    if (matches != null && matches[1]) {
                        fileName = matches[1].trim().replace(/['"]/g, "");
                    }
                }

                link.setAttribute("download", decodeURIComponent(fileName.replace(/\+/g, " ")));
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            })
            .catch((err) => {
                Swal.fire({
                    text: "오류가 발생하였습니다. 다시 시도해주세요",
                    confirmButtonText: "확인",
                });
            });
    };

    return {loading, reload: submit, data, updateFile, fileDownload, deleteFile, deleteBoardFile};
};

export {usefile, useFiles};
