import {useMemo, useState} from "react";
import Swal from "sweetalert2";

import {httpRequest} from "~/fetch/common/http-request";

// 견적서 다운로드
const useCostFacilityReportDownload = (costFacilityId: number) => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<{costFacilityId: number}, Blob>({
            method: "GET",
            url: "lotte/cost/facility/month/plans/plan/estimatefile",
            params: {
                costFacilityId,
            },
            responseType: "blob",
        });
    }, [costFacilityId]);

    const submit = async () => {
        try {
            const response = await request.submit();
            const blob = new Blob([response.data], {type: response.headers["content-type"]});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;

            // 파일명 추출
            const contentType = response.headers["content-type"];
            let fileName = "downloaded_file";

            if (contentType) {
                const matches = /filename=([^;]*)/.exec(contentType);
                if (matches != null && matches[1]) {
                    fileName = matches[1].trim().replace(/['"]/g, "");
                }
            }

            link.setAttribute("download", decodeURIComponent(fileName.replace(/\+/g, " ")));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (e) {
            setError(e);
            console.log(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        }
    };

    return [submit, error];
};

// 견적서 삭제
const useCostFacilityReportDelete = (costFacilityId: number) => {
    const [error, setError] = useState<any>(null);

    const request = useMemo(() => {
        return httpRequest<{costFacilityId: number}, null>({
            method: "DELETE",
            url: "/lotte/cost/facility/month/plans/plan/estimatefile",
            params: {
                costFacilityId,
            },
        });
    }, [costFacilityId]);

    const submit = async () => {
        try {
            await request.submit();
        } catch (e) {
            setError(e);
            console.log(e);
            Swal.fire({
                text: "오류가 발생하였습니다. 다시 시도해주세요",
                confirmButtonText: "확인",
            });
        }
    };
    return [submit, error];
};

export {useCostFacilityReportDelete, useCostFacilityReportDownload};
