import {useMemo, useState} from "react";

// 취합게시판 지점 필터링
const useSearchFilter = (data: any[], key: string) => {
    const [searchText, setSearchText] = useState<string>("");

    // key 값 기준으로 필터링
    const filteredData = useMemo(() => {
        return data.filter((item) => item[key].toLowerCase().includes(searchText.toLowerCase()));
    }, [searchText, data, key]);

    return {
        searchText,
        setSearchText,
        filteredData,
    };
};

export {useSearchFilter};
