import React, {useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {faL, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button as ButtonAntd, DatePicker, Input, InputNumber, Radio, Select, Spin, Upload} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import classNames from "classnames/bind";
import dayjs, {Dayjs} from "dayjs";
import html2canvas from "html2canvas";

import Button from "~/components/button/button";
import {CnTableNoPadding} from "~/components/cn-table-nopadding/cn-table-nopadding";
import {PageTitle} from "~/components/page-title";
import {useMachineBoardList} from "~/data/machine/board/use-machine";
import {useAddListDelete, useAddListEdit, useAddListRegist} from "~/data/machine/register/add-list";
import {
    useEquipmentCheckDTOList,
    useEquipmentHistoryDelete,
    useEquipmentHistoryEdit,
    useEquipmentHistoryRegistration,
    useEquipmentRegisterTargetList,
} from "~/data/machine/register/use-register";
import {singleFileUpload} from "~/fetch/single-file-upload";
import {fileToBase64} from "~/utills/file-to-base64";

import {useMachineDelete, useMachineDetail, useMachineUpdateDetail} from "./use-detail";

import styles from "./equipment-detail.module.scss";
import stylesGrid from "~/components/merge/merge-grid-item/merge-grid-item.module.scss";

const cx = classNames.bind(styles);
const gridCx = classNames.bind(stylesGrid);

const EquipmentDetailEditModal = ({onClose, machineId, division, refresh}: any) => {
    const {t} = useTranslation();

    const captureRef = useRef<HTMLDivElement | null>(null);

    const [isInitialize, setIsInitialize] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChangeCategory, setIsChangeCategory] = useState<boolean>(false);
    const [isAddListView, setIsAddListView] = useState<boolean>(false);

    const [newMachineId, setNewMachineId] = useState(machineId);

    const [input, setInput] = useState<any>({
        // <MergeDetailInput>
        machineId: "",
        machineName: "",
        machineCategoryCode: "",
        machineCategoryName: "",
        machineManufactureCompany: "",
        machineRegionId: "",
        machineInstallDate: "",
        machineManufactureDate: "",
        machineManufactureNumber: "",
        machineModelName: "",
        machineInstallCompany: "",
        machineLocalCode: "",
        machineUseYn: "",
        machineQuantitiy: 0,
        machineNote: "",
        machineImageUrl: "",
        base64Image: "",
        machineAddDTOList: [],
        machineCheckDTOList: [],
    });

    // 장비이력 값
    const [historyData, setHistoryData] = useState<any>({
        machineId: newMachineId.machineId,
        companyId: newMachineId.companyId,
        buildingId: newMachineId.buildingId,
        machineHistoryDate: 0, // 날짜
        machineHistoryCost: 0, // 비용
        machineHistoryContent: "", // 업무내역
        machineHistoryNote: "", // 비고
        type: "self", // 구분 (기본값 self로 지정)
        isManual: "Y", // 수동유무 (기본값 Y로 지정)
        isDel: "N", // 삭제유무
    });

    // 장비이력 수정값 모음
    const [historyDataEdit, setHistoryDataEdit] = useState<any>({
        machineHistoryId: 0,
        machineId: newMachineId.machineId,
        machineHistoryDate: 0, // 날짜
        machineHistoryCost: 0, // 비용
        machineHistoryContent: "", // 업무내역
        machineHistoryNote: "", // 비고
        type: "self", // 구분 (기본값 self로 지정)
    });

    // 추가정보 값
    const [addListData, setAddListData] = useState<any>({
        machineId: newMachineId.machineId,
        companyId: newMachineId.companyId,
        buildingId: newMachineId.buildingId,
        additionalKey: "", // 제목
        additionalValue: "", // 내용
    });

    // 추가정보 수정값 모음
    const [addListDataEdit, setAddListDataEdit] = useState<any>({
        machineAddId: 0,
        additionalKey: "",
        additionalValue: "",
    });

    // 셀렉트들 (카테고리, 제조사, 구역, 설치업체)
    const [submitTargetList, targetList] = useEquipmentRegisterTargetList(newMachineId);

    // 설비 이력 등록, 수정, 삭제
    const [isHistoryEdit, setIsHistoryEdit] = useState<boolean>(false);
    const [historyId, setHistoryId] = useState<number | any>();
    const [historySubmit, isLoadingHistory] = useEquipmentHistoryRegistration(historyData);
    const [historyDeleteSubmit, isLoadingDeleteHistory] = useEquipmentHistoryDelete(historyId, setHistoryId);
    const [historyEditSubmit, isLoadingHistoryEdit] = useEquipmentHistoryEdit(historyDataEdit, setIsHistoryEdit);

    // 추가정보 등록, 수정, 삭제 here
    const [isAddListEdit, setIsAddListEdit] = useState<boolean>(false);
    const [addListId, setAddListId] = useState<number | any>();
    const [addListSubmit, isLoadingAddList] = useAddListRegist(addListData, setIsAddListView);
    const [addListEditSubmit, isLoadingEditList] = useAddListEdit(addListDataEdit, setAddListDataEdit);
    const [AddListDeleteSubmit, isLoadingDeleteAddList] = useAddListDelete(addListId, setAddListId);

    // 이미지 정보
    const [imageFile, setImageFile] = useState<
        | {
              file: string;
              fileName: string;
              filePath: string;
          }
        | any
    >();

    // 상세 정보
    const [submit, response, isDetailLoading] = useMachineDetail(newMachineId);
    const detailData = response?.machineDTO;

    // 수정
    const [submitUpd] = useMachineUpdateDetail(input, setIsLoading);

    // 삭제
    const [submitDel] = useMachineDelete(newMachineId.machineId, setIsLoading);

    // 초기 셋팅
    useEffect(() => {
        setIsLoading(true);
        setIsInitialize(true);

        submitTargetList();
        submitA();
    }, []);

    useEffect(() => {
        if (!isLoading && isInitialize) setTimeout(() => onClose(), 500);

        const handleCloseESC = (event: KeyboardEvent) => {
            if (event.key === "Escape") {
                setIsLoading(false);
            }
        };

        if (isLoading) {
            document.addEventListener("keydown", handleCloseESC);
        }
        return () => {
            document.removeEventListener("keydown", handleCloseESC);
        };
    }, [isLoading]);

    useEffect(() => {
        submit();
        setHistoryData({
            ...historyData,
            machineHistoryCost: 0,
            machineHistoryContent: "",
            machineHistoryNote: "",
        });
        setAddListData({
            ...addListData,
            additionalKey: "",
            additionalValue: "",
        });
        setIsHistoryEdit(false);
        setImageFile(null);
    }, [
        newMachineId,
        isLoadingHistory,
        isLoadingDeleteHistory,
        isLoadingHistoryEdit,
        isLoadingAddList,
        isLoadingEditList,
        isLoadingDeleteAddList,
    ]);

    // 초기 받아온 데이터 주입
    useEffect(() => {
        setInput({
            // 추가정보들 추가하기
            machineId: newMachineId.machineId,
            machineName: detailData?.machineName,
            machineCategoryCode: detailData?.machineCategoryCode,
            machineCategoryName: detailData?.machineCategoryName,
            machineManufactureCompany: detailData?.machineManufactureCompany,
            machineRegionId: detailData?.machineRegionId,
            machineInstallDate: detailData?.machineInstallDate,
            machineManufactureDate: detailData?.machineManufactureDate,
            machineManufactureNumber: detailData?.machineManufactureNumber,
            machineModelName: detailData?.machineModelName,
            machineInstallCompany: detailData?.machineInstallCompany,
            machineLocalCode: detailData?.machineLocalCode,
            machineUseYn: detailData?.machineUseYn,
            machineQuantitiy: detailData?.machineQuantitiy,
            machineNote: detailData?.machineNote,
            machineImageUrl: detailData?.machineImageUrl,
            base64Image: detailData?.base64Image,
            machineAddDTOList: detailData?.machineAddDTOList,
            machineCheckDTOList: detailData?.machineCheckDTOList,
        });
    }, [response]);

    // 추가정보 01 카테고리 바뀔때 추가정보란 바뀌기
    // useEffect(() => {
    //     submitCheckList();
    // if (detailData?.machineCategoryCode === input?.machineCategoryCode) {
    //     setIsChangeCategory(false);
    // } else {
    //     setIsChangeCategory(true);
    //     // submitCheckList();
    // }
    // }, [input.machineCategoryCode]);

    // // 추가정보 02 (카테고리 선택 시 해당 추가정보란 불러옴)
    // const [submitCheckList, checkList] = useEquipmentCheckDTOList(input);

    // useEffect(() => {
    //     setInput({...input, machineCheckDTOList: checkList}); // 상태 업데이트
    // }, [checkList]);

    const handleClose = () => {
        setIsLoading(false);
        captureRef.current = null; // 모달이 닫힌 후 ref를 다시 사용할 수 있도록 처리
    };

    // title, content, manage name
    // const handleChangeInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const handleChangeInput = (e: any) => {
        setInput({
            ...input,
            [e.target.name]: e.target.value,
        });
    };

    // (수정) 보유수량
    const handleChangeInputNumber = (value: any) => {
        setInput({
            ...input,
            machineQuantitiy: value,
        });
    };

    // 추가정보 04 기입
    const handleInputChange = (index: any, value: any) => {
        const newCDataList: any = [...input.machineCheckDTOList]; // 기존의 상태 복사
        newCDataList[index].machineCheckAnswer = value; // 특정 인덱스의 값 업데이트
        setInput({...input, machineCheckDTOList: newCDataList}); // 상태 업데이트
    };

    // 수정
    const handleUpdateDetail = async () => {
        await submitUpd();
        await submit();
        setTimeout(refresh, 500);
    };

    // 삭제
    const handleDeleteDetail = async () => {
        await submitDel();
        setTimeout(refresh, 500);
    };

    // 장비이력 등록
    const handleHistoryRegistration = async () => {
        await historySubmit();
    };

    // 장비이력 수정
    const handleEquipmentHistoryEdit = async () => {
        await historyEditSubmit();
    };

    // 장비이력 삭제
    useEffect(() => {
        const deleteHistory = async () => {
            if (historyId) {
                await historyDeleteSubmit();
            }
        };

        deleteHistory();
    }, [historyId]);

    const handleHistoryDelete = async (machineHistoryId: any) => {
        setHistoryId(machineHistoryId);
    };

    // 캡쳐
    const handleCapture = () => {
        if (captureRef.current) {
            html2canvas(captureRef.current, {
                windowWidth: captureRef.current.scrollWidth,
                windowHeight: captureRef.current.scrollHeight,
                y: 0,
                scale: 2,
            })
                .then((canvas) => {
                    const imgData = canvas.toDataURL("image/png");

                    // 이미지 다운로드
                    const link = document.createElement("a");
                    link.href = imgData;
                    link.download = "modal-screenshot.png";
                    link.click();
                })
                .catch((error) => {
                    console.error("Error capturing screenshot: ", error);
                });
        }
    };

    // 설비목록
    const perPage = useMemo(() => 15, []);
    const [page, setPage] = useState(1);
    const [submitA, responseA, isLoadingA] = useMachineBoardList({
        pageNo: page, // 페이지 넘버 - 1(ALL)
        trainerSectorId: null, // 트레이너 권역
        searchMachineSectorId: null, // 부문
        searchMachineBuildingId: machineId.buildingId, // 지점
        searchProcessCode: null, // 공정
        searchMachineCategoryCode: machineId.machineCategoryCode, // 카테고리 - ALL
        searchMachineUseYn: "ALL", // 사용여부 - ALL
        isNoImage: "ALL", // 설비 사진 - ALL
    });

    useEffect(() => {
        // window.scrollTo(0, 0);
        submitA();
    }, [page]);

    // 추가정보 등록
    const onClickAddAddListInput = () => {
        if (isAddListView === true) {
            alert("생성된 추가정보 항목을 등록 후 이용해주세요.");
        } else {
            setIsAddListView(true);
        }
    };
    const handleAddListRegist = async () => {
        await addListSubmit();
    };

    // 추가정보 수정
    const handleAddListEdit = async () => {
        await addListEditSubmit();
    };

    // 추가정보 삭제
    useEffect(() => {
        const deleteHistory = async () => {
            if (addListId) {
                await AddListDeleteSubmit();
            }
        };

        deleteHistory();
    }, [addListId]);

    const handleAddListDelete = async (machineAddId: any) => {
        setAddListId(machineAddId);
    };

    return (
        <div className={cx("container", {active: isLoading})}>
            <div
                className={cx("inner", {active: isLoading}, "large")}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <div className={cx("contents-container")} ref={captureRef}>
                    <PageTitle>설비현황수정</PageTitle>
                    {isDetailLoading ? (
                        <div className={cx("loading")}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <>
                            <div className={cx("info")}>
                                <div className={cx("info-top-wrapper")}>
                                    <h3>
                                        기본정보{" "}
                                        <span style={{color: "#d9534f", marginLeft: "8px", fontSize: "14px"}}>
                                            * 필수항목
                                        </span>
                                    </h3>
                                    <div className={cx("button-container")}>
                                        {division === "MANAGER" && (
                                            <>
                                                <Button onClick={handleDeleteDetail} label="삭제" color={"primary"} />
                                                <Button onClick={handleUpdateDetail} label="수정" />
                                            </>
                                        )}
                                        <Button onClick={handleClose} label="닫기" color={"primary"} />
                                    </div>
                                </div>
                                <table className={cx("table")}>
                                    <colgroup>
                                        <col width="40%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                        <col width="15%" />
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Upload
                                                    className={cx("field")}
                                                    maxCount={1}
                                                    showUploadList={false}
                                                    customRequest={singleFileUpload}
                                                    onChange={(res) => {
                                                        if (res.fileList.length === 0) setImageFile(undefined);

                                                        try {
                                                            if (res.file.status === "done") {
                                                                fileToBase64(res.file.originFileObj).then(
                                                                    (rst: string) => {
                                                                        setImageFile({
                                                                            file: rst,
                                                                            fileName: res.file.fileName as string,
                                                                            filePath: res.file.response.filePath,
                                                                        });
                                                                        setInput({
                                                                            ...input,
                                                                            machineImageUrl: res.file.response.filePath,
                                                                        });
                                                                    },
                                                                );
                                                            }
                                                        } catch (e) {
                                                            console.error(e);
                                                        }
                                                    }}
                                                >
                                                    <ButtonAntd icon={<FontAwesomeIcon icon={faUpload} />}>
                                                        이미지 업로드
                                                    </ButtonAntd>
                                                </Upload>
                                            </td>
                                            <th>지점명</th>
                                            <td>{detailData?.buildingName}</td>
                                            <th>* 장비명</th>
                                            <td>
                                                <Input
                                                    className={gridCx("input")}
                                                    name="machineName"
                                                    onChange={handleChangeInput}
                                                    value={input?.machineName}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td rowSpan={10} className={cx("image-cell")}>
                                                <img
                                                    style={{width: "100%"}}
                                                    src={
                                                        imageFile?.file !== undefined
                                                            ? imageFile?.file.toString()
                                                            : `data:image/jpeg;base64,${input?.base64Image}`
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            {/* here */}
                                            <th>* 카테고리</th>
                                            <td colSpan={3}>
                                                <Select
                                                    // showSearchs
                                                    placeholder="선택"
                                                    style={{width: "100%"}}
                                                    onChange={(value) => {
                                                        setInput({
                                                            ...input,
                                                            machineCategoryCode: value,
                                                        });
                                                    }}
                                                    disabled
                                                    options={targetList?.category}
                                                    getPopupContainer={(trigger) => trigger.parentElement}
                                                    value={input?.machineCategoryCode}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제조사</th>
                                            <td>
                                                <Select
                                                    showSearch
                                                    placeholder="선택"
                                                    style={{width: "100%"}}
                                                    onChange={(value) => {
                                                        setInput({
                                                            ...input,
                                                            machineManufactureCompany: value,
                                                        });
                                                    }}
                                                    options={
                                                        targetList?.manufactureCompany as {
                                                            value: string;
                                                            label: string;
                                                        }[]
                                                    }
                                                    filterOption={(input, option) => {
                                                        if (option) {
                                                            return option.label
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase());
                                                        }
                                                        return false;
                                                    }}
                                                    getPopupContainer={(trigger) => trigger.parentElement}
                                                    value={input?.machineManufactureCompany}
                                                />
                                            </td>
                                            <th>구역</th>
                                            <td>
                                                <Select
                                                    showSearch
                                                    placeholder="선택"
                                                    style={{width: "100%"}}
                                                    onChange={(value) => {
                                                        setInput({
                                                            ...input,
                                                            machineRegionId: value,
                                                        });
                                                    }}
                                                    options={targetList?.region as {value: string; label: string}[]}
                                                    filterOption={(input, option) => {
                                                        if (option) {
                                                            return option.label
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase());
                                                        }
                                                        return false;
                                                    }}
                                                    getPopupContainer={(trigger) => trigger.parentElement}
                                                    value={input?.machineRegionId}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>설치연월</th>
                                            <td>
                                                <DatePicker
                                                    locale={locale}
                                                    picker="month"
                                                    onChange={(date, dateString: any) => {
                                                        setInput({
                                                            ...input,
                                                            machineInstallDate: dateString.replace("-", ""),
                                                        });
                                                    }}
                                                    placeholder="년/월 선택"
                                                    style={{width: "100%"}}
                                                    format="YYYY.MM"
                                                    value={
                                                        input?.machineInstallDate
                                                            ? dayjs(input?.machineInstallDate, "YYYYMM")
                                                            : null
                                                    }
                                                />
                                            </td>
                                            <th>제조연월</th>
                                            <td>
                                                <DatePicker
                                                    picker="month"
                                                    onChange={(date, dateString: any) => {
                                                        setInput({
                                                            ...input,
                                                            machineManufactureDate: dateString.replace("-", ""),
                                                        });
                                                    }}
                                                    placeholder="년/월 선택"
                                                    style={{width: "100%"}}
                                                    format="YYYY.MM"
                                                    value={
                                                        input?.machineManufactureDate
                                                            ? dayjs(input?.machineManufactureDate, "YYYYMM")
                                                            : null
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>제조번호</th>
                                            <td colSpan={3}>
                                                <Input
                                                    className={gridCx("input")}
                                                    name="machineManufactureNumber"
                                                    onChange={handleChangeInput}
                                                    value={input?.machineManufactureNumber}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>모델명</th>
                                            <td colSpan={3}>
                                                <Input
                                                    className={gridCx("input")}
                                                    name="machineModelName"
                                                    onChange={handleChangeInput}
                                                    value={input?.machineModelName}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>설치업체</th>
                                            <td colSpan={3}>
                                                <Select
                                                    showSearch
                                                    placeholder="선택"
                                                    style={{width: "100%"}}
                                                    onChange={(value) => {
                                                        setInput({
                                                            ...input,
                                                            machineInstallCompany: value,
                                                        });
                                                    }}
                                                    options={
                                                        targetList?.installCompany as {value: string; label: string}[]
                                                    }
                                                    getPopupContainer={(trigger) => trigger.parentElement}
                                                    value={input?.machineInstallCompany}
                                                    filterOption={(input, option) => {
                                                        if (option) {
                                                            return option.label
                                                                .toLowerCase()
                                                                .includes(input.toLowerCase());
                                                        }
                                                        return false;
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>장비코드</th>
                                            <td colSpan={3}>
                                                <Input
                                                    className={gridCx("input")}
                                                    name="machineLocalCode"
                                                    onChange={handleChangeInput}
                                                    value={input?.machineLocalCode}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>사용여부</th>
                                            <td>
                                                <Radio.Group
                                                    onChange={(e) => {
                                                        setInput({
                                                            ...input,
                                                            machineUseYn: e.target.value,
                                                        });
                                                    }}
                                                    value={input?.machineUseYn}
                                                >
                                                    <Radio value={"Y"}>사용</Radio>
                                                    <Radio value={"N"}>미사용</Radio>
                                                </Radio.Group>
                                            </td>
                                            <th>보유수량</th>
                                            <td>
                                                <InputNumber
                                                    className={gridCx("input")}
                                                    name="machineQuantitiy"
                                                    onChange={handleChangeInputNumber}
                                                    min={0}
                                                    value={input?.machineQuantitiy}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>비고</th>
                                            <td colSpan={3}>
                                                <Input
                                                    className={gridCx("input")}
                                                    name="machineNote"
                                                    onChange={handleChangeInput}
                                                    value={input?.machineNote}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={cx("info")}>
                                <h3>설비목록</h3>
                                <div className={cx("table-container")}>
                                    <CnTableNoPadding
                                        style={{padding: "0"}}
                                        totalCount={responseA?.allRecordCnt}
                                        onPageChange={(page) => setPage(page)}
                                        perPage={perPage}
                                        page={page}
                                        dataSource={responseA?.resultList.map((row: any) => {
                                            row["key"] = row.machineId;
                                            return row;
                                        })}
                                        pagination={false}
                                        loading={isLoadingA}
                                        onRow={(data, index) => ({
                                            onClick: () =>
                                                setNewMachineId({
                                                    machineCategoryCode: data!.machineCategoryCode,
                                                    machineId: data!.machineId,
                                                    companyId: data!.companyId,
                                                    buildingId: data!.buildingId,
                                                }),
                                        })}
                                        columns={[
                                            {
                                                title: "No",
                                                key: "index",
                                                render: (_, __, index) =>
                                                    Number((page - 1) * perPage + index + 1).toLocaleString(),
                                                align: "center",
                                            },
                                            {
                                                title: t("equipment.equipment-name"),
                                                dataIndex: "machineName",
                                                key: "machineName",
                                                align: "center",
                                            },
                                            {
                                                title: t("equipment.manufacturer"),
                                                dataIndex: "machineManufactureCompany",
                                                key: "machineManufactureCompany",
                                                align: "center",
                                            },
                                            {
                                                title: t("equipment.installation-date"),
                                                dataIndex: "machineInstallDate",
                                                key: "machineInstallDate",
                                                align: "center",
                                                render: (date) =>
                                                    dayjs(date).isValid() ? dayjs(date).format("YYYY-MM") : "",
                                            },
                                            {
                                                title: t("equipment.installer"),
                                                dataIndex: "machineInstallCompany",
                                                key: "machineInstallCompany",
                                                align: "center",
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                            {/* here */}
                            <div className={cx("info")}>
                                <div className={cx("info-top-wrapper")}>
                                    <h3>추가정보</h3>
                                    <div className={cx("button-container")}>
                                        <Button onClick={onClickAddAddListInput} label="추가" />
                                    </div>
                                </div>

                                <table className={cx("table")}>
                                    <colgroup>
                                        <col width="30%" />
                                        <col width="70%" />
                                    </colgroup>
                                    <tbody>
                                        {/* {checkList?.map(
                                    (
                                        el: {
                                            machineCategoryCode: string; // 카테고리 코드 (필)
                                            machineCheckCode: string; // 추가정보 코드 (필)
                                            machineCheckName: string; // 추가정보명 string - 왼쪽 (필)
                                        },
                                        index: number,
                                    ) => (
                                        <tr key={index}>
                                            <th>{el.machineCheckName}</th>
                                            <td>
                                                <Input
                                                    className={gridCx("input")}
                                                    name={el.machineCheckName}
                                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                                    value={input.machineCheckDTOList[index]?.machineCheckAnswer || ""}
                                                />
                                            </td>
                                        </tr>
                                    ),
                                )}
                                <div>dddd</div> */}
                                        {/* 기존 추가정보 항목 */}
                                        {input?.machineCheckDTOList?.map(
                                            (
                                                el: {
                                                    machineCategoryCode: string; // 카테고리 코드 (필)
                                                    machineCheckCode: string; // 추가정보 코드 (필)
                                                    machineCheckName: string; // 추가정보명 string - 왼쪽 (필)
                                                },
                                                index: number,
                                            ) => (
                                                <tr key={index}>
                                                    <th>{el.machineCheckName}</th>
                                                    <td>
                                                        <Input
                                                            className={gridCx("input")}
                                                            name={el.machineCheckName}
                                                            onChange={(e) => handleInputChange(index, e.target.value)}
                                                            value={
                                                                input.machineCheckDTOList[index]?.machineCheckAnswer ||
                                                                ""
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                        {/* 추가 추가정보 항목 */}
                                        {input?.machineAddDTOList?.map(
                                            (
                                                el: {
                                                    machineAddId: number; // 추가  (필)
                                                    additionalKey: string; // 추가정보 코드 (필)
                                                    additionalValue: string; // 추가정보명 string - 왼쪽 (필)
                                                },
                                                index: number,
                                            ) => (
                                                <tr key={el.machineAddId}>
                                                    <th>
                                                        {isAddListEdit &&
                                                        el.machineAddId === addListDataEdit.machineAddId ? (
                                                            <Input
                                                                className={gridCx("input")}
                                                                name={el.additionalKey}
                                                                onChange={(e: any) => {
                                                                    setAddListDataEdit({
                                                                        ...addListDataEdit,
                                                                        additionalKey: e.target.value,
                                                                    });
                                                                }}
                                                                defaultValue={el.additionalKey}
                                                            />
                                                        ) : (
                                                            el.additionalKey
                                                        )}
                                                    </th>
                                                    <td
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        {isAddListEdit &&
                                                        el.machineAddId === addListDataEdit.machineAddId ? (
                                                            <Input
                                                                className={gridCx("input")}
                                                                name={el.additionalValue}
                                                                onChange={(e: any) => {
                                                                    setAddListDataEdit({
                                                                        ...addListDataEdit,
                                                                        additionalValue: e.target.value,
                                                                    });
                                                                }}
                                                                defaultValue={el.additionalValue}
                                                            />
                                                        ) : (
                                                            el.additionalValue
                                                        )}
                                                        <>
                                                            {isAddListEdit &&
                                                            el.machineAddId === addListDataEdit.machineAddId ? (
                                                                <>
                                                                    <Button onClick={handleAddListEdit} label="수정" />
                                                                    <Button
                                                                        onClick={() => {
                                                                            setIsAddListEdit(false);
                                                                            setAddListDataEdit(null);
                                                                        }}
                                                                        label="취소"
                                                                        color={"primary"}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setIsAddListEdit(true);
                                                                            setAddListDataEdit({
                                                                                ...addListDataEdit,
                                                                                machineAddId: el.machineAddId,
                                                                                additionalKey: el.additionalKey,
                                                                                additionalValue: el.additionalValue,
                                                                            });
                                                                        }}
                                                                        label="수정"
                                                                        color={"primary"}
                                                                    />
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleAddListDelete(el.machineAddId);
                                                                        }}
                                                                        label="삭제"
                                                                        color={"primary"}
                                                                    />
                                                                </div>
                                                            )}
                                                        </>
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                        {/* 추가정보 추가 버튼 */}
                                        {isAddListView && (
                                            <tr>
                                                <th>
                                                    <Input
                                                        className={gridCx("input")}
                                                        name="additionalKey"
                                                        onChange={(e: any) => {
                                                            setAddListData({
                                                                ...addListData,
                                                                additionalKey: e.target.value,
                                                            });
                                                        }}
                                                        value={addListData.additionalKey}
                                                    />
                                                </th>
                                                <td style={{display: "flex", justifyContent: "space-between"}}>
                                                    <Input
                                                        className={gridCx("input")}
                                                        name="additionalValue"
                                                        onChange={(e: any) => {
                                                            setAddListData({
                                                                ...addListData,
                                                                additionalValue: e.target.value,
                                                            });
                                                        }}
                                                        value={addListData.additionalValue}
                                                    />
                                                    <Button onClick={handleAddListRegist} label="등록" />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>

                            {/* {detailData?.machineHistoryDTOList.filter((m) => m.isDel === "N").length !== 0 && ( */}
                            <div className={cx("info")}>
                                <h3>
                                    장비이력{" "}
                                    <span style={{color: "#d9534f", marginLeft: "8px", fontSize: "14px"}}>
                                        * 필수항목
                                    </span>
                                </h3>

                                <table className={cx("table")}>
                                    <colgroup>
                                        <col width="3%" />
                                        <col width="12%" />
                                        <col width="12%" />
                                        <col width="10%" />
                                        <col width="30%" />
                                        <col width="23%" />
                                        <col width="10%" />
                                    </colgroup>
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>* 날짜</th>
                                            <th>비용</th>
                                            <th>구분</th>
                                            <th>* 업무내역</th>
                                            <th>비고</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td>
                                                <DatePicker
                                                    locale={locale}
                                                    onChange={(date, dateString: any) => {
                                                        setHistoryData({
                                                            ...historyData,
                                                            machineHistoryDate: new Date(dateString).getTime(),
                                                        });
                                                    }}
                                                    placeholder="년/월/일"
                                                    style={{width: "100%"}}
                                                    format="YYYY.MM.DD"
                                                />
                                            </td>
                                            <td>
                                                <InputNumber
                                                    className={gridCx("input")}
                                                    name="machineHistoryCost"
                                                    onChange={(e: any) => {
                                                        setHistoryData({
                                                            ...historyData,
                                                            machineHistoryCost: e,
                                                        });
                                                    }}
                                                    value={historyData.machineHistoryCost}
                                                />
                                            </td>
                                            <td>
                                                <Radio.Group
                                                    onChange={(e) => {
                                                        setHistoryData({
                                                            ...historyData,
                                                            type: e.target.value,
                                                        });
                                                    }}
                                                    value={historyData.type}
                                                >
                                                    <Radio value={"self"}>자체개선</Radio>
                                                    <Radio value={"construction"}>실제공사</Radio>
                                                </Radio.Group>
                                            </td>
                                            <td>
                                                <Input
                                                    className={gridCx("input")}
                                                    name="machineHistoryContent"
                                                    onChange={(e: any) => {
                                                        setHistoryData({
                                                            ...historyData,
                                                            machineHistoryContent: e.target.value,
                                                        });
                                                    }}
                                                    value={historyData.machineHistoryContent}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    className={gridCx("input")}
                                                    name="machineHistoryNote"
                                                    onChange={(e: any) => {
                                                        setHistoryData({
                                                            ...historyData,
                                                            machineHistoryNote: e.target.value,
                                                        });
                                                    }}
                                                    value={historyData.machineHistoryNote}
                                                />
                                            </td>
                                            <td>
                                                <Button onClick={handleHistoryRegistration} label="등록" />
                                            </td>
                                        </tr>
                                        {detailData?.machineHistoryDTOList
                                            .filter((m) => m.isDel === "N")
                                            .map(
                                                (
                                                    el: {
                                                        machineHistoryDate: number;
                                                        machineHistoryCost: number;
                                                        machineHistoryContent: string;
                                                        machineHistoryNote: string;
                                                        machineHistoryId: number;
                                                        type: string;
                                                    },
                                                    index: number,
                                                ) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {isHistoryEdit &&
                                                            el.machineHistoryId === historyDataEdit.machineHistoryId ? (
                                                                <>
                                                                    <DatePicker
                                                                        locale={locale}
                                                                        onChange={(date, dateString: any) => {
                                                                            setHistoryDataEdit({
                                                                                ...historyDataEdit,
                                                                                machineHistoryDate: new Date(
                                                                                    dateString,
                                                                                ).getTime(),
                                                                            });
                                                                        }}
                                                                        placeholder="년/월/일"
                                                                        style={{width: "100%"}}
                                                                        format="YYYY.MM.DD"
                                                                        defaultValue={dayjs(el.machineHistoryDate)}
                                                                    />
                                                                </>
                                                            ) : (
                                                                dayjs(new Date(el.machineHistoryDate)).format(
                                                                    "YYYY.MM.DD",
                                                                )
                                                            )}
                                                        </td>
                                                        <td>
                                                            {isHistoryEdit &&
                                                            el.machineHistoryId === historyDataEdit.machineHistoryId ? (
                                                                <InputNumber
                                                                    className={gridCx("input")}
                                                                    name="machineHistoryCost"
                                                                    onChange={(e: any) => {
                                                                        setHistoryDataEdit({
                                                                            ...historyDataEdit,
                                                                            machineHistoryCost: e,
                                                                        });
                                                                    }}
                                                                    defaultValue={el.machineHistoryCost}
                                                                />
                                                            ) : (
                                                                el.machineHistoryCost.toLocaleString()
                                                            )}
                                                        </td>
                                                        <td>
                                                            {isHistoryEdit &&
                                                            el.machineHistoryId === historyDataEdit.machineHistoryId ? (
                                                                <Radio.Group
                                                                    onChange={(e) => {
                                                                        setHistoryDataEdit({
                                                                            ...historyDataEdit,
                                                                            type: e.target.value,
                                                                        });
                                                                    }}
                                                                    // value={el.type}
                                                                    defaultValue={el.type}
                                                                >
                                                                    <Radio value={"self"}>자체개선</Radio>
                                                                    <Radio value={"construction"}>실제공사</Radio>
                                                                </Radio.Group>
                                                            ) : (
                                                                <>
                                                                    {el.type === "self"
                                                                        ? "자체개선"
                                                                        : el.type === "construction"
                                                                          ? "실제공사"
                                                                          : ""}
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {isHistoryEdit &&
                                                            el.machineHistoryId === historyDataEdit.machineHistoryId ? (
                                                                <Input
                                                                    className={gridCx("input")}
                                                                    name="machineHistoryContent"
                                                                    onChange={(e: any) => {
                                                                        setHistoryDataEdit({
                                                                            ...historyDataEdit,
                                                                            machineHistoryContent: e.target.value,
                                                                        });
                                                                    }}
                                                                    defaultValue={el.machineHistoryContent}
                                                                />
                                                            ) : (
                                                                el.machineHistoryContent
                                                            )}
                                                        </td>

                                                        <td>
                                                            {isHistoryEdit &&
                                                            el.machineHistoryId === historyDataEdit.machineHistoryId ? (
                                                                <Input
                                                                    className={gridCx("input")}
                                                                    name="machineHistoryNote"
                                                                    onChange={(e: any) => {
                                                                        setHistoryDataEdit({
                                                                            ...historyDataEdit,
                                                                            machineHistoryNote: e.target.value,
                                                                        });
                                                                    }}
                                                                    defaultValue={el.machineHistoryNote}
                                                                />
                                                            ) : (
                                                                el.machineHistoryNote
                                                            )}
                                                        </td>
                                                        <td>
                                                            {isHistoryEdit &&
                                                            el.machineHistoryId === historyDataEdit.machineHistoryId ? (
                                                                <>
                                                                    <Button
                                                                        onClick={handleEquipmentHistoryEdit}
                                                                        label="수정"
                                                                    />
                                                                    <Button
                                                                        onClick={() => {
                                                                            setIsHistoryEdit(false);
                                                                            setHistoryDataEdit(null);
                                                                        }}
                                                                        label="취소"
                                                                        color={"primary"}
                                                                    />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        onClick={() => {
                                                                            setIsHistoryEdit(true);
                                                                            setHistoryDataEdit({
                                                                                ...historyDataEdit,
                                                                                machineHistoryId: el.machineHistoryId,

                                                                                machineId: newMachineId.machineId,
                                                                                machineHistoryDate:
                                                                                    el.machineHistoryDate, // 날짜
                                                                                machineHistoryCost:
                                                                                    el.machineHistoryCost, // 비용
                                                                                machineHistoryContent:
                                                                                    el.machineHistoryContent, // 업무내역
                                                                                machineHistoryNote:
                                                                                    el.machineHistoryNote, // 비고
                                                                                type: el.type, // 구분 (기본값 self로 지정)
                                                                            });
                                                                        }}
                                                                        label="수정"
                                                                        color={"primary"}
                                                                    />
                                                                    <Button
                                                                        onClick={() =>
                                                                            handleHistoryDelete(el.machineHistoryId)
                                                                        }
                                                                        label="삭제"
                                                                        color={"primary"}
                                                                    />
                                                                </>
                                                            )}
                                                        </td>
                                                    </tr>
                                                ),
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            <div className={cx("info-right")}>
                                <div className={cx("button-container")}>
                                    {division === "MANAGER" && (
                                        <>
                                            <Button onClick={handleCapture} label="캡쳐하기" />
                                            <Button onClick={handleDeleteDetail} label="삭제" color={"primary"} />
                                            <Button onClick={handleUpdateDetail} label="수정" />
                                        </>
                                    )}
                                    <Button onClick={handleClose} label="닫기" color={"primary"} />
                                </div>
                            </div>
                        </>
                    )}
                    {/* )} */}
                </div>
            </div>
        </div>
    );
};

export {EquipmentDetailEditModal};
