import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {DatePicker, Input} from "antd";
import locale from "antd/es/date-picker/locale/ko_KR";
import classNames from "classnames/bind";
import dayjs from "dayjs";

import Button from "~/components/button/button";
import {CnSelect} from "~/components/cn-select/cn-select";
import {CustomLabelTable} from "~/components/custom-label-table/custom-label-table";
import {FilterArea} from "~/components/filter-area/filter-area";
import {FilterItem} from "~/components/filter-item/filter-item";
import {PageTitle} from "~/components/page-title/page-title";
import {useCostFilterTypeList} from "~/data/cost/common/use-cost-filter";
import {
    useCostFacilityMemberApproval,
    useCostFacilityMemberConfirm,
    useCostFacilityMemberFix,
} from "~/data/cost/facility-maintenance/modal/use-cost-facility-member";
import {useCostFacilityExcelDownload} from "~/data/cost/facility-maintenance/use-facility-maintenance";
import {useCostMonthlyList} from "~/data/cost/monthly/use-cost-monthly";
import {useShopCascader} from "~/hooks/use-shop-cascader/use-shop-cascader";
import {CostMonthlyModal} from "~/pages/cost/monthly/modal/cost-monthly.modal";
import {useLocalStorage} from "~/utills/useLocalStorage";

import {CostMonthlyInputType} from "./cost-monthly.interface";

import styles from "./cost-monthly.module.scss";
import tableStyles from "~/components/custom-label-table/custom-label-table.module.scss";
import filterStyles from "~/components/filter-item/filter-item.module.scss";

const cx = classNames.bind(styles);
const filterCx = classNames.bind(filterStyles);
const tableCx = classNames.bind(tableStyles);

const CostMonthlyPage = () => {
    const perPage = 50;
    const [page, setPage] = useState<number>(1);
    const [division, setDivision] = useState<"MEMBER" | "MANAGER" | "EMPLOYEE" | null>(null);
    const [isShowModal, setIsShowModal] = useState<boolean>(false);
    const [selectedRows, setSelectedRows] = useState<React.Key[]>([]); // 선택된 행
    const [costFacilityId, setCostFacilityId] = useState<number>(0);
    const [inputs, setInputs] = useState<CostMonthlyInputType>({
        year: dayjs(),
        month: dayjs(),
        quarters: null,
        yearProcessId: "ALL",
        state: "ALL",
        startCost: 0,
        endCost: 999999999,
        yearConstructName: "",
    });

    const {ShopCascader, selected, setSelected} = useShopCascader(150);
    const {setItem, getItem} = useLocalStorage();
    const {t} = useTranslation();

    // 공정
    const [submitOption, options] = useCostFilterTypeList();
    // 테이블
    const [submitTableList, tableList, isTableListLoading] = useCostMonthlyList({
        ...inputs,
        selected,
        page,
        perPage,
    });
    // 엑셀다운
    const [downloadExcel] = useCostFacilityExcelDownload({
        ...inputs,
        selected,
        budgetDivision: 1,
        typeId: "ALL",
    });
    // 본사 확인
    const [checkMember] = useCostFacilityMemberConfirm({costFacilityId, selectedCostFacilityId: selectedRows});
    // 본사 승인
    const [approvalMember] = useCostFacilityMemberApproval({costFacilityId, selectedCostFacilityId: selectedRows});
    // 본사 확정
    const [fixMember] = useCostFacilityMemberFix({costFacilityId, fixedCost: 0, selectedCostFacilityId: selectedRows});

    // 계정 권한
    useEffect(() => {
        setDivision(JSON.parse(getItem("LM_USER")!).division);
    }, [getItem]);

    // 초기 셋팅
    useEffect(() => {
        submitOption();
    }, []);
    // 테이블 호출
    useEffect(() => {
        window.scrollTo(0, 0);
        submitTableList();
    }, [page]);

    useEffect(() => {
        submitTableList();
    }, [selected, inputs]);

    const handleReset = useCallback(() => {
        setInputs({
            year: dayjs(),
            month: dayjs(),
            quarters: null,
            yearProcessId: "ALL",
            state: "ALL",
            startCost: 0,
            endCost: 999999999,
            yearConstructName: "",
        });
        setSelected(["ALL"]);
    }, []);

    const handleRowClick = (costFacilityId: number, event: React.MouseEvent<HTMLTableRowElement>) => {
        const clickedElement = event.target as HTMLElement;
        const closestCheckboxCell = clickedElement.closest(".ant-table-selection-column");

        if (closestCheckboxCell) {
            return; // 체크박스 셀 클릭 시 모달 띄우지 않음
        } else {
            setCostFacilityId(costFacilityId);
            setIsShowModal(true);
        }
    };

    return (
        <div>
            {isShowModal && (
                <CostMonthlyModal
                    costFacilityId={costFacilityId}
                    close={() => setIsShowModal(false)}
                    division={division}
                    submitTableList={submitTableList}
                />
            )}
            <PageTitle>투자비 월간계획</PageTitle>
            <FilterArea>
                <div className={cx("filter-inner")}>
                    <FilterItem label={"연도"}>
                        <DatePicker
                            placeholder={"연도 선택"}
                            picker={"year"}
                            style={{width: "80px"}}
                            status={inputs.year === null ? "error" : ""}
                            value={inputs.year}
                            allowClear={false}
                            onChange={(e) => setInputs((prev) => ({...prev, year: e}))}
                        />
                    </FilterItem>
                    <FilterItem label={"분기"}>
                        <DatePicker
                            placeholder={"전체"}
                            locale={locale}
                            popupClassName="no-header"
                            picker={"quarter"}
                            format={"Q분기"}
                            style={{width: "80px"}}
                            value={inputs.quarters}
                            onChange={(e) => setInputs((prev) => ({...prev, quarters: e, month: null}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("Q분기")}</div>
                            )}
                        />
                    </FilterItem>
                    <FilterItem label={"월"}>
                        <DatePicker
                            placeholder={"전체"}
                            className={filterCx("filter-datepicker")}
                            popupClassName="no-header"
                            picker={"month"}
                            format={"MM"}
                            style={{width: "80px"}}
                            value={inputs.month}
                            onChange={(e) => setInputs((prev) => ({...prev, month: e, quarter: null}))}
                            cellRender={(date) => (
                                <div className="ant-picker-cell-inner">{dayjs(date).format("MM")}</div>
                            )}
                        />
                    </FilterItem>
                    <CnSelect
                        label={"공정"}
                        placeholder={"공정 선택"}
                        style={{width: "120px"}}
                        value={inputs.yearProcessId}
                        onChange={(e) => setInputs((prev) => ({...prev, yearProcessId: e}))}
                        options={[{label: "전체", value: "ALL"}, ...options.processList]}
                    />
                    <FilterItem label={t("filter.department-trainer")}>
                        <ShopCascader />
                    </FilterItem>
                    <CnSelect
                        label={"상태"}
                        placeholder={"상태 선택"}
                        style={{width: "160px"}}
                        onChange={(e) => setInputs((prev) => ({...prev, state: e}))}
                        value={inputs.state}
                        options={[
                            {
                                label: "전체",
                                value: "ALL",
                            },
                            {
                                label: "검토중",
                                value: "M_SUBMIT",
                            },
                            {
                                label: "본사 확인",
                                value: "M_VERIFY",
                            },
                            {
                                label: "확정",
                                value: "M_CONFIRM",
                            },
                        ]}
                    />
                    <FilterItem label={"공사건명"}>
                        <Input
                            style={{width: "180px"}}
                            placeholder={"공사건명 검색"}
                            value={inputs.yearConstructName}
                            onChange={(e) => setInputs((prev) => ({...prev, yearConstructName: e.target.value}))}
                        />
                    </FilterItem>
                    <div className={cx("button-container")}>
                        <Button
                            label={"조회"}
                            color={"primary"}
                            onClick={() => {
                                setPage(1);
                                if (page === 1) {
                                    submitTableList();
                                }
                            }}
                        />
                        <Button
                            label="엑셀 다운"
                            color={"primary"}
                            onClick={() => {
                                downloadExcel();
                            }}
                        />
                        <Button label={"검색조건 초기화"} onClick={handleReset} />
                    </div>
                </div>
            </FilterArea>
            <div className={cx("table-container")}>
                <CustomLabelTable
                    label={
                        <>
                            <span>
                                총액 : {tableList?.allRecordCost ? tableList.allRecordCost.toLocaleString() : 0} 원 /
                                본사확인 : {tableList?.approvalCost.toLocaleString() ?? 0} 원 / 확정 :{" "}
                                {tableList?.confirmCost.toLocaleString() ?? 0} 원 / 기타 :{" "}
                                {tableList?.etcCost.toLocaleString() ?? 0} 원 / 총{" "}
                                {tableList?.allRecordCnt.toLocaleString() ?? 0} 건
                            </span>
                            <div className={tableCx("button-container")}>
                                {division === "MEMBER" ? (
                                    <>
                                        <span>{selectedRows.length} 건 선택됨</span>
                                        <Button
                                            label="트레이너 확인"
                                            onClick={() => {
                                                checkMember();
                                                setTimeout(submitTableList, 500);
                                            }}
                                        />
                                        <Button
                                            label="승인"
                                            onClick={() => {
                                                fixMember();
                                                setTimeout(submitTableList, 500);
                                            }}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Button
                                            label="등록"
                                            onClick={() => {
                                                setCostFacilityId(0);
                                                setIsShowModal(true);
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        </>
                    }
                    onPageChange={(page) => setPage(page)}
                    perPage={perPage}
                    page={page}
                    dataSource={tableList?.resultList ?? []}
                    rowKey={"costFacilityId"}
                    onRow={(record) => ({
                        onClick: (e) => handleRowClick(record.costFacilityId, e),
                    })}
                    loading={isTableListLoading}
                    pagination={false}
                    totalCount={tableList?.allRecordCnt ?? 0}
                    rowSelection={
                        division === "MANAGER"
                            ? undefined
                            : {
                                  selectedRowKeys: selectedRows,
                                  onChange: (selectedRowKeys: React.Key[]) => {
                                      setSelectedRows(selectedRowKeys);
                                  },
                              }
                    }
                    columns={[
                        {
                            title: "No",
                            render: (_, __, index) => index + 1 + (page - 1) * perPage,
                            width: "4%",
                        },
                        {
                            title: "연도",
                            dataIndex: "year",
                            key: "year",
                            width: "5%",
                        },
                        {
                            title: "월",
                            dataIndex: "month",
                            key: "month",
                            width: "4%",
                        },
                        {
                            title: "부문",
                            key: "sectorName",
                            dataIndex: "sectorName",
                            width: "7%",
                        },
                        {
                            title: "지점",
                            key: "buildingName",
                            dataIndex: "buildingName",
                            width: "7%",
                        },
                        {
                            title: "공정",
                            key: "yearProcessName",
                            dataIndex: "yearProcessName",
                            width: "6%",
                        },
                        {
                            title: "공사건명",
                            key: "yearConstructName",
                            width: "18%",
                            dataIndex: "yearConstructName",
                            ellipsis: true,
                        },
                        {
                            title: "금액",
                            key: "monthConstructCost",
                            dataIndex: "monthConstructCost",
                            render: (value) => value.toLocaleString() ?? 0,
                            width: "8%",
                        },
                        {
                            title: "반영금액",
                            key: "fixedCost",
                            dataIndex: "fixedCost",
                            render: (value) => value.toLocaleString() ?? 0,
                            width: "8%",
                        },
                        {
                            title: "설비 연동",
                            key: "yearMachineLinkYnDesc",
                            dataIndex: "yearMachineLinkYnDesc",
                            width: "5%",
                        },
                        {
                            title: "설비",
                            key: "yearMachineName",
                            dataIndex: "yearMachineName",
                            width: "8%",
                            ellipsis: true,
                        },
                        {
                            title: "등록일",
                            key: "createDate",
                            dataIndex: "createDate",
                            width: "9%",
                        },
                        {
                            title: "상태",
                            key: "stateDesc",
                            dataIndex: "stateDesc",
                            width: "7%",
                        },
                        {
                            title: "확정 여부",
                            key: "finalConfirm",
                            dataIndex: "finalConfirm",
                            width: "5%",
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export {CostMonthlyPage};
