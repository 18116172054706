import React, {MouseEventHandler, useState} from "react";
import {useDraggable, useDroppable} from "@dnd-kit/core";
import {faEllipsisVertical, faFolder} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown, MenuProps} from "antd";
import classNames from "classnames/bind";

import {FolderProps} from "~/components/drive/folder.interface";
import {useUser} from "~/data/user";

import styles from "./folder.module.scss";

const cx = classNames.bind(styles);

const Folder = (props: FolderProps) => {
    const {children, onDoubleClick, icon, category, onChangeName, onDelete, folderId} = props;

    const {user} = useUser();
    const {isOver, setNodeRef: setNodeRefForDrop} = useDroppable({
        id: `folder-${folderId}`,
    });

    const {
        attributes,
        listeners,
        setNodeRef: setNodeRefForDrag,
    } = useDraggable({
        id: `folder-${folderId}`,
        data: category,
    });

    const items: MenuProps["items"] = [
        {
            label: "이름변경",
            key: "0",
            onClick: () => {
                if (onChangeName) onChangeName();
            },
        },
        {
            type: "divider",
        },
        {
            label: "삭제",
            key: "1",
            onClick: () => {
                if (onDelete) onDelete();
            },
        },
    ];

    const [lastClickTime, setLastClickTime] = useState(0);
    const doubleClickThreshold = 300; // 더블 클릭 인식 시간 (300ms)

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const currentTime = new Date().getTime();
        if (currentTime - lastClickTime < doubleClickThreshold) {
            event.stopPropagation();
            event.preventDefault();
            onDoubleClick && onDoubleClick();
        }
        setLastClickTime(currentTime);
    };

    return (
        <div
            ref={(ref) => {
                user?.division === "MEMBER" && setNodeRefForDrop(ref);
                user?.division === "MEMBER" && !icon && setNodeRefForDrag(ref);
            }}
            onClick={handleClick}
            {...listeners}
            {...attributes}
            className={cx("folder", {"is-over": isOver})}
        >
            <div className={cx("icon-container")}>
                <FontAwesomeIcon icon={icon ? icon : faFolder} className={cx("folder-icon")} />
            </div>
            <div className={cx("text-container")}>
                <p>{children}</p>
            </div>
            {category && user?.division === "MEMBER" && (
                <div className={cx("select-container")}>
                    <Dropdown menu={{items}} trigger={["click"]}>
                        <a onClick={(e) => e.preventDefault()}>
                            <button className={cx("option-button")}>
                                <FontAwesomeIcon icon={faEllipsisVertical} className={cx("folder-icon")} />
                            </button>
                        </a>
                    </Dropdown>
                </div>
            )}
        </div>
    );
};

export {Folder};
